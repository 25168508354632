export interface DataTerminalManager {
    id_: number;
    ssl_name: string;
    contact_email: string;
    ssl_code: string;
    is_nam: string;
    alternate_email: string;
    alias: string;
    ccm_alias: string;
  }
  
  export const HeaderDataTerminalManager: HeadCell[] = [
    {
      id: "ssl_name",
      numeric: false,
      disablePadding: false,
      label: "NAME",
    },
    { id: "ssl_code", numeric: false, disablePadding: false, label: "ABBREVIATION" },
    { id: "contact_email", numeric: false, disablePadding: false, label: "EMAIL" },
    { id: "alias", numeric: false, disablePadding: false, label: "ALIAS" },
    { id: "ccm_alias", numeric: false, disablePadding: false, label: "CCM ALIAS" },
  
  ];
  
  export interface HeadCell {
    disablePadding: boolean;
    id: keyof DataTerminalManager;
    label: string;
    numeric: boolean;
  }
