import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { getIepList, getPool, newPool, updatePool } from "../api";
import WaitPopup from "./WaitPopup";

interface Props {
  id: number;
  handleClose: Function;
  newState: boolean;
}

export default function (props: Props) {
  const [pool, setPool] = React.useState<any | undefined>();
  const [pool_name, setName] = React.useState("");
  const [pool_code, setCode] = React.useState("");
  const [iep_id, setIEPCode] = React.useState("");
  const [ieps, setIEPs] = React.useState<any | undefined>([]);

  let openWait: boolean = true;

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  React.useEffect(() => {
    if (pool) {
      setName(pool.pool_name);
      setIEPCode(pool.iep_id);
      setCode(pool.pool_code);
    }
  }, [pool]);

  function loadPageInfo() {
    if (props.newState) {
      setPool({ pool_name: "", iep_id: "", pool_code: "" });
      getIepList( setIEPs);
    } else {
      getPool( props.id, setPool);
      getIepList( setIEPs);
    }
  }

  function handleSave() {
    if (validated()) {
      if (props.newState) {
        pool.pool_name = pool_name;
        pool.iep_id = iep_id;
        pool.pool_code = pool_code;
        newPool( pool, props.handleClose);
      } else {
        pool.pool_name = pool_name;
        pool.iep_id = iep_id;
        pool.pool_code = pool_code;
        updatePool( pool, props.handleClose);
      }
    } else {
      alert("Information is invalid or in the wrong format.");
    }
  }

  function handleIEPDDChange(event: any) {
    setIEPCode(event.target.value);
  }

  function validated() {
    if (props.newState) {
      if (pool_name && iep_id) {
        return true;
      } else return false;
    } else {
      if (pool_name && iep_id) {
        return true;
      }
    }
    return false;
  }

  if (pool) {
    openWait = false;
  }

  return (
    <div>
      <WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
      <Grid container>
        <Grid item sm={12}>
          <Box>{props.newState ? <h3>Add Pool</h3> : <h3>Edit Pool</h3>}</Box>
        </Grid>
        <Grid item sm={12}>
          <Grid container>
            <Grid item sm={5} style={{ margin: 10 }}>
              <TextField
                label="NAME"
                value={pool_name}
                onChange={(event: any) => {
                  setName(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={5} style={{ margin: 10 }}>
              <TextField
                label="ABBREVIATION"
                value={pool_code}
                onChange={(event: any) => {
                  setCode(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={5} style={{ margin: 10 }}>
              <TextField
                select
                fullWidth
                variant="outlined"
                label="IEP"
                value={iep_id}
                SelectProps={{ displayEmpty: true }}
                InputLabelProps={{ shrink: true }}
                onChange={handleIEPDDChange}
              >
                {ieps &&
                  ieps.map((item, index) => {
                    if (ieps.length <= 0) {
                      return <div />;
                    }
                    return (
                      <MenuItem key={index} value={item.id_}>
                        {item.abbrv}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
            <Grid item sm={12}>
              <Box style={{ float: "right" }}>
                <Button
                  style={{ margin: 5 }}
                  onClick={() => {
                    props.handleClose();
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Close
                </Button>
                <Button
                  style={{ margin: 5 }}
                  onClick={handleSave}
                  variant="outlined"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
