import { apiCall } from "./apiV2";
import { Me } from "../types/Me";


export async function signIn(username: string, password: string) {
  let me = await apiCall("POST", "user/login", {
    "username": username,
    "password": password,
  });
  return me;
}

export async function signOut() {
  return await apiCall("GET", "user/logout");
}

export async function getUsers(callback: Function) {
  let users = await apiCall("GET", "user/getUsers");
  callback(users);
}

export async function getUser(userID: number, callback: Function) {
  let user = await apiCall("GET", "user/getUser/" + userID);
  console.log("returned user:",user);
  callback(user);
}

export async function getMe() {
  try {
    let res = await apiCall("GET", "user/validate");
    return res as Me;
  } catch (err) {
    return null;
  }
}

export async function setUserAPI(user: any, callback: Function ) {
  callback(await apiCall("POST", "user/setUser", user));
}

export async function deleteUser(userID: number, callback: Function) {
  callback(await apiCall("DELETE", "user/deleteUser/" + userID));
}


