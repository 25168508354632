export interface DataInvoiceWorkflow {
    id_: number;
    name: string;
    iep_id: string;
    iep_name: string;
    invoice_num: string;
    documented_dt: string;
    entered_dt: string;
    checked: boolean;
  }
  
  export const HeaderDataInvoiceRecons: HeadCell[] = [
    { id: "checked", numeric: false, disablePadding: false, label: "SELECT" },
    { id: "id_", numeric: false, disablePadding: false, label: "ID" },
    { id: "iep_name", numeric: false, disablePadding: false, label: "IEP" },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "NAME",
    },
    { id: "invoice_num", numeric: false, disablePadding: false, label: "INVOICE #" },
    { id: "documented_dt", numeric: false, disablePadding: false, label: "DOCUMENTED DATE" },
    {
      id: "entered_dt",
      numeric: false,
      disablePadding: false,
      label: "ENTERED DATE",
    },
  ];
  
  export interface HeadCell {
    disablePadding: boolean;
    id: keyof DataInvoiceWorkflow;
    label: string;
    numeric: boolean;
  }
  
