export const frequency = {
  weekly: 1,
  monthly: 2,
  once: 3,
};

export const weekdays = {
  Sunday: 1,
  Monday: 2,
  Tuesday: 3,
  Wednesday: 4,
  Thursday: 5,
  Friday: 6,
  Saturday: 7,
};
