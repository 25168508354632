import * as React from "react";

const useIsMountedRef = () => {
  const isMountedRef = React.useRef(false);
  React.useEffect(() => {
    isMountedRef.current = true;
    return () => void (isMountedRef.current = false);
  }, []);
  return isMountedRef;
};

export default useIsMountedRef;
