import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { getFileMappingsByIep, updateFileMap } from "../api";
import WaitPopup from "./WaitPopup";


export interface DataUploadFileMapManager {
  id_: number;
  db_column_name: string;
  file_column_name: string;
  module_id: number;
}

export interface HeadCell {
  disablePadding: boolean;
  id: keyof DataUploadFileMapManager;
  label: string;
  numeric: boolean;
}

type Order = "asc" | "desc";

function getModalStyle() {
  const top = 25;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function (props: any) {
  const [open, setOpen] = React.useState(false);
  const [uploadFileMaps, setUploadFileMaps] = React.useState<any | undefined>();
  const [fileMaps, setFileMaps] = React.useState<any | undefined>(undefined);
  const [fileMapChanged, setFileMapChanged] = React.useState<boolean | undefined>(false);

  const [iepId, setIepId] = React.useState(props.id);
  let openWait: boolean = true;

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  const loadPageInfo = () => {
    getFileMappingsByIep( iepId, setUploadFileMaps);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    loadPageInfo();
    setOpen(false);
  };

  function handleSave() {
    if (validated()) {
      // remove the iep_id and readd it from the original, so that it cannot be changed by the user
      for (var i = 0; i < fileMaps.length; i++) {
        if (fileMaps[i].db_column_name === "iep_id") {
          fileMaps.pop(i)
        }
      }
      let fileMap = { db_column_name: "iep_id", file_column_name: iepId };
      fileMaps.push(fileMap);
      updateFileMap( fileMaps, props.handleClose);
      alert("File mapping saved.");
    } else {
      alert("Information is incomplete or not in the correct format. total_billed is a required field. Please check and try again.");
    }
  }

  function validated() {
    if (props.newState) {
      if (fileMaps && iepId) {
        for (var i = 0; i < fileMaps.length; i++) {
          if (fileMaps[i].db_column_name === "total_billed" && fileMaps[i].file_column_name !== "") {
            return true;
          }
        }
        return false;
      }
    }
    return true;
  }

  React.useEffect(() => {
    if (uploadFileMaps) {
      const maps = [];
      let keys = Object.keys(uploadFileMaps);
      let values = Object.values(uploadFileMaps);
      for (var i = 0; i <= keys.length; i++) {
        if (keys[i] && keys[i] !== "iep_id"){
          let dbColumn = keys[i];
          let fileColumn = values[i];
          let fileMap = { db_column_name: dbColumn, file_column_name: fileColumn };
          maps.push(fileMap);
        }
      }
      openWait = false;
      setFileMaps(maps);
    }
  }, [uploadFileMaps]);

  if (fileMaps) {
    openWait = false;
  }

  return (
    <div>
      <WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
      <Grid item sm={12} style={{ margin: 0 }}>
        <Box justifyContent="center" display="flex" margin={0}>
          <h3 style={{ margin: 5 }}> MANAGE UPLOAD FILE MAPPINGS </h3>
        </Box>
        <Grid item sm={12} style={{ margin: 0 }}>
          <Box justifyContent="center" display="flex" style={{ margin: 5 }}>
            <Button
              style={{ margin: 10 }}
              onClick={() => {
                props.handleClose();
              }}
              variant="contained"
              color="primary"
              size="medium"
            >
              Close
            </Button>
            <Button
              onClick={handleSave}
              variant="contained"
              style={{ margin: 10 }}
              color="primary"
              size="medium"
              disabled={fileMapChanged == false ? true : false}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container sm={12} style={{ position: "sticky" }}>
        <Grid item sm={6} style={{ fontWeight: "bold" }}>
          <TextField
            style={{ fontWeight: "bold", color: "primary" }}
            variant="standard"
            value="DATABASE COLUMN"
            fullWidth
          ></TextField>
        </Grid>
        <Grid item sm={6}>
          <TextField
            style={{ fontWeight: "bold", color: "primary" }}
            variant="standard"
            value="FILE COLUMN"
            fullWidth
          ></TextField>
        </Grid>
      </Grid>
      <Grid container sm={12} style={{ maxHeight: 750, overflow: "scroll" }}>
        <Grid container sm={12}>
          <Grid container item>
            {fileMaps &&
              fileMaps.map((row, index) => {
                return (
                  <Grid container item direction="row" z-index={1}>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        variant="standard"
                        value={row.db_column_name}
                        SelectProps={{ displayEmpty: true }}
                        InputLabelProps={{ shrink: true, disabled: true }}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        variant="standard"
                        defaultValue={row.file_column_name}
                        SelectProps={{ displayEmpty: true }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event: any) => {
                          fileMaps[index].file_column_name = event.target.value;
                          setFileMapChanged(true);
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
