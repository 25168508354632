export interface DataInvoiceRecons {
  invoice_num: string;
  dueDt: Date;
  iep_name: string;
  name: string;
  user: string;
  documented_dt: string;
}

export const HeaderDataInvoiceRecons: HeadCell[] = [
  {
    id: "invoice_num",
    numeric: false,
    disablePadding: true,
    label: "INVOICE #",
  },
  {
    id: "dueDt",
    numeric: false,
    disablePadding: false,
    label: "DUE DATE",
  },
  { id: "iep_name", numeric: false, disablePadding: false, label: "IEP" },
  { id: "name", numeric: false, disablePadding: false, label: "FILE NAME" },
  { id: "user", numeric: false, disablePadding: false, label: "USER" },
  {
    id: "documented_dt",
    numeric: false,
    disablePadding: false,
    label: "INVOICE DATE",
  },
];

export interface HeadCell {
  disablePadding: boolean;
  id: keyof DataInvoiceRecons;
  label: string;
  numeric: boolean;
}
