/* global window, document, Blob */
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/GetApp";
import React from "react";

interface propTypes {
  table: string;
  fileName: string;
  sheet: string;
  id: string | "button-download-as-xls";
  className: string | "button-download";
  buttonText: string;
}


function base64(s: string) {
  return window.btoa(unescape(encodeURIComponent(s)));
}

function format(s: string, c: any) {
  return s.replace(/{(\w+)}/g, (m, p) => c[p]);
}

export default function ReactHTMLTableToExcel(props: propTypes) {
  const handleDownload = () => {
    if (!document) {
      if (process.env.NODE_ENV !== "production") {
        console.error("Failed to access document object");
      }

      return null;
    }

    if (
      document &&
      document.getElementById(props.table) &&
      (document.getElementById(props.table)?.nodeType !== 1 ||
        document.getElementById(props.table)?.nodeName !== "TABLE")
    ) {
      if (process.env.NODE_ENV !== "production") {
        console.error("Provided table property is not html table element");
      }

      return null;
    }

    const table = document.getElementById(props.table)?.outerHTML;
    const sheet = String(props.sheet);
    const filename = `${String(props.fileName)}.xls`;

    const uri = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
      'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
      'rset="UTF-12"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
      "lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>" +
      "</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></" +
      "xml><![endif]--></head><body>{table}</body></html>";

    let context = {
      worksheet: sheet || "Worksheet",
      table,
    };

    const element = window.document.createElement("a");
    element.href = uri + base64(format(template, context));
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    return true;
  };

  return (
    <IconButton
      id={props.id}
      className={props.className}
      onClick={handleDownload}
      style={{ float: "right", padding: 3 }}
    >
      <DownloadIcon />
    </IconButton>
  );
}
