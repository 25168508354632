import MomentUtils from "@date-io/moment";
import { MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./AppRouter";
import Toaster from "./components/Toaster";
import { theme } from "./styles";

const App: React.FC = () => {
  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Toaster></Toaster>
          <AppRouter></AppRouter>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Router>
  );
};

export default App;
