import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import ArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import * as React from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { authStore } from "../../stores/authStore";
import { layoutStyle } from "./LayoutStyle";

interface Props {
  open: boolean;
  setOpen(isOpen: boolean): void;
}
interface DrawerItem {
  label: string;
  icon: string;
  route: string;
  isMatch: boolean;
  onSidePanel: boolean;
}

export default function SideMenu(props: Props) {
  const classes = layoutStyle();
  const location = useLocation();
  const history = useHistory();
  const [drawerItems, setDrawerItems] = React.useState<DrawerItem[]>([]);
  const [textSearch, setTextSearch] = React.useState<string>();
  const auth = authStore.use();
  const me = auth.me;

  React.useEffect(() => {
    if (me && me.group === "TermTeam") {
      setDrawerItems([
        {
          label: "DASHBOARD",
          route: "/terminal",
          onSidePanel: true,
          icon: "dashboard",
          isMatch: !!matchPath(location.pathname, {
            path: ["/terminal"],
            exact: false,
            strict: false,
          }),
        },
      ]);
    } else {
      setDrawerItems([
        {
          label: "DASHBOARD",
          route: "/dashboard",
          onSidePanel: true,
          icon: "dashboard",
          isMatch: !!matchPath(location.pathname, {
            path: ["/dashboard"],
            exact: false,
            strict: false,
          }),
        },
        {
          label: "UPLOAD",
          route: "/upload",
          onSidePanel: true,
          icon: "publish",
          isMatch: !!matchPath(location.pathname, {
            path: ["/upload"],
            exact: false,
            strict: false,
          }),
        },
        {
          label: "OPEN DISPUTES",
          route: "/opendisputesworkflow",
          onSidePanel: true,
          icon: "folder_open",
          isMatch: !!matchPath(location.pathname, {
            path: ["/opendisputesworkflow"],
            exact: false,
            strict: false,
          }),
        },
        {
          label: "AP",
          route: "/aps",
          onSidePanel: true,
          icon: "attach_money",
          isMatch: !!matchPath(location.pathname, {
            path: ["/aps"],
            exact: false,
            strict: false,
          }),
        },
        {
          label: "REPORTS",
          route: "/reports",
          onSidePanel: true,
          icon: "file_download",
          isMatch: !!matchPath(location.pathname, {
            path: ["/reports"],
            exact: false,
            strict: false,
          }),
        },
        {
          label: "CREDIT UPLOAD",
          route: "/creditupload",
          onSidePanel: true,
          icon: "monetization_on",
          isMatch: !!matchPath(location.pathname, {
            path: ["/creditupload"],
            exact: false,
            strict: false,
          }),
        },
        {
          label: "AUTO WRITE OFFS",
          route: "/autowriteoffs",
          onSidePanel: true,
          icon: "history_edu",
          isMatch: !!matchPath(location.pathname, {
            path: ["/autowriteoffs"],
            exact: false,
            strict: false,
          }),
        },
      ]);
    }
  }, []);

  let handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      let redirectState = { searchText: textSearch };
      history.push("/quicksearch", redirectState);
    }
  };

  let handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextSearch(e.target.value);
  };

  const { open, setOpen } = props;
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, classes.drawerBackground, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, classes.drawerBackground, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
    >
      <List className={classes.drawerLogoBackground} onClick={(e) => setOpen(!open)}>
        <ListItem>
          <div
            className={clsx(classes.logo, {
              [classes.bigLogo]: open,
              [classes.tinyLogo]: !open,
            })}
          ></div>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          <TextField
            id="input-with-icon-textfield"
            onKeyUp={handleEnterKey}
            onChange={handleTextChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </ListItem>
        {drawerItems.map((item) => {
          return (
            <ListItem
              key={item.label}
              className={clsx({
                [classes.selected]: item.isMatch,
                [classes.unSelected]: !item.isMatch,
              })}
              button
              onClick={(e) => {
                history.push(item.route);
              }}
            >
              <ListItemIcon>
                <span
                  className={clsx("material-icons", classes.icon, {
                    [classes.iconSelected]: item.isMatch,
                    [classes.iconUnselected]: !item.isMatch,
                  })}
                >
                  {item.icon}
                </span>
              </ListItemIcon>
              <Typography variant="h4" className={item.isMatch ? classes.selectedDrawerText : ""}>
                {item.label}
              </Typography>
            </ListItem>
          );
        })}
      </List>
      <List className={classes.drawerSpacer}></List>
      <List dense>
        <Divider />
        <ListItem button>
          <IconButton
            className={clsx(classes.center, classes.selected)}
            onClick={(e) => setOpen(!open)}
          >
            <Box>{open ? <ArrowLeft /> : <ArrowRight />}</Box>
          </IconButton>
        </ListItem>
      </List>
      V2.002
    </Drawer>
  );
}
