export interface DataInvoiceRecons {
    customer_name: string;
    invoice_ssl: string;
    chassis: string;
    dt_in: Date;
    dt_out: Date;
    id: number;
    invoice: string;
    invoiceDate: Date;
    manifest_num: string;
    terminal: string;
    total_billed: string;
    container1: string;
    selection: string;
    profitLost: string;
    saved: boolean;
  }
  
  export const HeaderDataInvoiceRecons: HeadCell[] = [
    {
      id: "dt_in",
      numeric: false,
      disablePadding: false,
      label: "DT IN",
    },
    { id: "dt_out", numeric: false, disablePadding: false, label: "DT OUT" },
    { id: "container1", numeric: false, disablePadding: false, label: "CONTAINER #" },
    { id: "chassis", numeric: false, disablePadding: false, label: "CHASSIS #" },
    { id: "manifest_num", numeric: false, disablePadding: false, label: "MANFIEST #" },
  
    { id: "customer_name", numeric: false, disablePadding: false, label: "BILL TO" },
    { id: "invoice_ssl", numeric: false, disablePadding: false, label: "SSL" },
  
    { id: "profitLost", numeric: false, disablePadding: false, label: "PROFIT LOST" },
    { id: "total_billed", numeric: false, disablePadding: false, label: "TOTAL BILLED" },
  ];
  
  export interface HeadCell {
    disablePadding: boolean;
    id: keyof DataInvoiceRecons;
    label: string;
    numeric: boolean;
  }
