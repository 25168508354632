import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Moment } from "moment";
import React from "react";
import { getInvoicesWithOpenDisputes, writeOffDisputes } from "../api";
import InvoiceWriteOffTable from "../components/CustomTable/invoiceWriteOffTable/InvoiceWriteOfftable";
import Layout from "../components/layout/Layout";
import WaitPopup from "../components/WaitPopup";
import { authStore } from "../stores/authStore";

const moment = require('moment');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 900,
      maxHeight: 700,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

function rand() {
  return Math.round(Math.random() * 10) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box paddingTop={0}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function (props: any) {
  const classes = useStyles();
  const [openWait, setOpenWait] = React.useState<boolean>(true);
  const [invoices, setInvoices] = React.useState<any | undefined>();
  const [filteredInvoices, setFilteredInvoices] = React.useState<any | undefined>();
  const [percentAmount, setPercentAmount] = React.useState<any | undefined>(0);
  const [waitMsg, setWaitMsg] = React.useState<string | undefined>("...Loading Info");
  const auth = authStore.use();
  const me = auth.me;
  const [toSelectedDate, setToSelectedDate] = React.useState<Moment | null>(moment().startOf('day'));
  const [fromSelectedDate, setFromSelectedDate] = React.useState<Moment | null>(moment().startOf('day'));

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  React.useEffect(() => {
    if (invoices) {
      setFilteredInvoices(invoices);
    }
  }, [invoices]);

  const loadPageInfo = () => {
    getInvoicesWithOpenDisputes( (data) => {
      data.forEach((item) => {
        item.checked = false;
        if (item.documented_dt) {
          item.documented_dt = moment.utc(item.documented_dt);
        }
        if (item.entered_dt) {
          item.entered_dt = moment.utc(item.entered_dt);
        }
      });
      setInvoices(data);
      setOpenWait(false);
    });
  };

  const handleApplyAndClose = () => {

    if (filteredInvoices && percentAmount > 0) {
      let closedDisputes = { percent: percentAmount, invoices: [], username: me.username };
      filteredInvoices.forEach((element) => {
        if (element.checked) {
          closedDisputes.invoices.push(element);
        }
      });
      writeOffDisputes( closedDisputes, (data) => {
        loadPageInfo();
      });
    }
  };

  const handlePercentChange = (event: any) => {
    event.target.value = event.target.value.match(/[0-9]+\.?[0-9]?[0-9]?/);
    setPercentAmount(event.target.value);
  };

  const handleApplyFilter = () => {
    let filteredInvoices = invoices.filter((data) => {
      return fromSelectedDate <= data.documented_dt && data.documented_dt <= toSelectedDate;
    });
    setFilteredInvoices(filteredInvoices);
  };

  return (
    <Layout>
      <Paper style={{ margin: 5 }}>
        <WaitPopup open={openWait}>{waitMsg} </WaitPopup>
        <Box style={{ padding: 30 }}>
          <Grid container style={{ overflow: "hidden", width: "100%" }}>
            <Grid item sm={12}>
              <Box>
                <h3>INVOICE DISPUTE WRITE-OFFS</h3>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="FROM DATE"
                    value={fromSelectedDate}
                    style={{ width: "100%" }}
                    onChange={setFromSelectedDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="TO DATE"
                    value={toSelectedDate}
                    style={{ width: "100%" }}
                    onChange={setToSelectedDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box style={{ paddingTop: 10 }}>
                <TextField
                  style={{ width: "30%" }}
                  id="input-with-icon-textfield"
                  label="PERCENT AMOUNT TO WRITE OFF"
                  onChange={handlePercentChange}
                  value={percentAmount}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div>{"%"}</div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box justifyContent="center" display="flex" style={{ marginTop: 30 }}>
                <Button
                  style={{ marginRight: 10 }}
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={handleApplyFilter}
                >
                  Apply Filter
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={handleApplyAndClose}
                >
                  WRITE OFF AND CLOSE
                </Button>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Box>
                <InvoiceWriteOffTable
                  invoiceData={filteredInvoices}
                  setInvoiceData={setFilteredInvoices}
                  viewPopOver={false}
                  disableControls={false}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
}
