import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import CCMReconciliationTable from "./CustomTable/ccmReconciliationTable/CCMReconciliations";

const useStyles = makeStyles((theme: Theme) => createStyles({}));
interface Props {
  ccmData: any[];
  handleDeleteClick: Function;
}

export default function (props: Props) {
  const classes = useStyles();

  return (
    <Box style={{ margin: 10, height: "100%" }}>
      <Grid container style={{ height: "100%", padding: 15 }}>
        <Grid item sm={12} style={{ height: 20 }}>
          <Grid container>
            <Grid item sm={10}>
              <Box>
                <h4>CCM RECONCILIATIONS ({props.ccmData.length})</h4>
              </Box>
            </Grid>

          </Grid>
        </Grid>
        <Grid item sm={12} style={{ height: "100%" }}>
          <Box style={{ height: "100%" }}>
            {props.ccmData.length > 0 ? (
              <CCMReconciliationTable ccmData={props.ccmData} handleDeleteClick={props.handleDeleteClick} />
            ) : (
                <Box style={{ marginTop: 20 }}>
                  <div>No Records available</div>
                </Box>
              )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
