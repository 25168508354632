import DateFnsUtils from "@date-io/date-fns";
import { Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Moment } from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  getIepList,
  getSettings,
  getSimpleInvoiceRecords,
  post,
  uploadFile,
  validateCCMRecords,
} from "../api";
import Layout from "../components/layout/Layout";
import UploadComp from "../components/upload/UploadComponent";
import { authStore } from "../stores/authStore";
import { toastStore } from "../stores/toastStore";

const moment = require("moment");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(3),
    },
    wrapper: {
      width: "100%",
      height: "100%",
    },
    inputWrapper: {
      position: "absolute",
      overflow: "hidden",
      display: "inline",
      width: "100%",
      height: "50%",
      zIndex: 1,
      opacity: 0,
      left: 0,
      top: 0,
    },
    paper: {
      position: "absolute",
      width: 500,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

async function validateBulkRecords(
  invRecIds,
  daysOffset,
  useBolSsl,
  callBack,
  doneCallback,
  errorCallback
) {
  if (invRecIds) {
    let res = await post(
      "invoice/records/validate/" + daysOffset + "/" + useBolSsl + "/0",
      { recIDs: invRecIds },
      false
    )
      .then(doneCallback)
      .catch(() => {
        errorCallback();
        return;
      });
    while (res && res.recIDs && res.recIDs.length > 0) {
      callBack("...Validating Invoice Records: " + res.recIDs.length);
      res = await post(
        "invoice/records/validate/" + daysOffset + "/" + useBolSsl + "/0",
        { recIDs: res.recIDs },
        false
      );
    }
  }
}

function rand() {
  return Math.round(Math.random() * 15) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface Props {
  style: any;
  match: any;
}

export default function (props: Props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [value, setValue] = React.useState<string | undefined>();
  const [isManager, setIsManager] = React.useState<boolean>(true);
  const [fileValue, setFileValue] = React.useState<any>();
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [iepSelected, setIepSelected] = React.useState(-1);
  const [invoiceNum, setInvoiceNum] = React.useState<string>();
  const [iepList, setIepList] = React.useState<any | undefined>();
  const [daysOffset, setDaysOffset] = React.useState<number | undefined>(0);
  const [useBolSsl, setUseBolSsl] = React.useState<boolean | undefined>(false);
  const [open, setOpen] = React.useState(false);
  const [textWhileWait, setTextWhileWait] = React.useState("");
  const [settings, setSettings] = React.useState<any | undefined>();
  const [labelWidth, setLabelWidth] = React.useState(0);

  const auth = authStore.use();
  const me = auth.me;

  const [selectedDate, setSelectedDate] = React.useState<Moment | null>(moment());
  const history = useHistory();
  React.useEffect(() => {
    if (!props.match.path.includes("manager")) {
      setValue("ccm");
      setIsManager(false);
      loadPageInfo();
    } else {
      setValue("cr");
      setIsManager(true);
    }
    if (inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current!.offsetWidth);
    }
  }, []);

  React.useEffect(() => {
    if (settings) {
      settings.forEach((setting) => {
        if (setting) {
          if (setting.name == "DaysOffsetForValidate") {
            setDaysOffset(parseInt(setting.value));
          }
          if (setting.name == "UseBolSslForValidate" && setting.value == "1") {
            setUseBolSsl(true);
          } else {
            setUseBolSsl(false);
          }
        }
      });
    }
  }, [settings]);

  const handleDateChange = (date: Moment | null) => {
    setSelectedDate(date);
  };

  const loadPageInfo = () => {
    if (!iepList) {
      getIepList(setIepList);
    }
    if (!settings) {
      getSettings(setSettings);
    }
  };

  const handleInvoiceNumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceNum((event.target as HTMLInputElement).value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleIEPDDChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIepSelected(event.target.value as number);
  };

  const handleDaysChange = function (event: any) {
    event.target.value = event.target.value.match(/[0-9]+/);
    setDaysOffset(event.target.value);
  };

  const handleBolSslChange = function (event: any) {
    setUseBolSsl(event.target.checked);
  };

  const handleProcessClick = () => {
    if (
      fileValue &&
      ((value === "ccm" && selectedDate) ||
        (value === "iep" && iepSelected && invoiceNum) ||
        value === "cr" ||
        value === "rate" ||
        value === "pool")
    ) {
      const extraParams = { daysOffset: daysOffset, useBolSsl: useBolSsl };
      const formData = new FormData();
      const choosenDt = selectedDate ? moment.utc(selectedDate) : moment();
      var docDate = choosenDt.format("L");
      const additdata =
        value === "ccm"
          ? '{"username":"' + me.username + '", "docDT":"' + docDate + '"}'
          : '{"username":"' +
            me.username +
            '", "docDT":"' +
            docDate +
            '", "iepID":' +
            iepSelected +
            ', "invoiceNum":"' +
            invoiceNum +
            '"}';
      formData.append("data", additdata);
      formData.append("file", fileValue);
      setOpen(true);
      setTextWhileWait("...Uploading File");
      uploadFile(
        formData,
        true,
        value === "ccm",
        value === "iep",
        value === "cr",
        value === "rate",
        value === "pool"
      )
        .then((data) => {
          if (value === "ccm") {
            setTextWhileWait("...Validating CCM Records");
            validateCCMRecords(
              data.ccm_id,
              (callbackInfo) => {
                history.push("/ccmworkflow/" + data.ccm_id);
              },
              extraParams
            );
          } else if (value == "iep") {
            setTextWhileWait("...Validating Invoice Records");
            getSimpleInvoiceRecords(data.invoice_id, (data2) => {
              if (data2) {
                setTextWhileWait("...Validating Invoice Records: " + data2.length);
                validateBulkRecords(
                  data2,
                  daysOffset,
                  useBolSsl,
                  setTextWhileWait,
                  () => {
                    history.push("/invoiceworkflow/" + data.invoice_id);
                  },
                  () => {
                    setOpen(false);
                  }
                );
              }
            });
          } else if (
            value == "cr" ||
            value == "rate" ||
            value == "pool" ||
            value == "terminal" ||
            value == "ssl"
          ) {
            setTextWhileWait("DONE! ");
            history.push("/dashboard");
          }
        })
        .catch((err) => {
          setOpen(false);
          toastStore.showToast("error", err.message);
        });
    }
  };

  if (props.match.path.includes("manager") && !isManager) {
    setValue("cr");
    setIsManager(true);
  }

  if (!props.match.path.includes("manager") && isManager) {
    setValue("ccm");
    setIsManager(false);
  }

  return (
    <Layout>
      <Paper style={{ margin: 5 }}>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
        >
          <div style={modalStyle} className={classes.paper}>
            {textWhileWait}
          </div>
        </Modal>
        <Box style={{ padding: 30 }}>
          <Grid
            container
            style={{
              width: "auto",
              margin: "0% 20%",
            }}
          >
            <Grid item sm={12} xs={12} style={{ padding: 30 }}>
              <Box justifyContent="center" display="flex">
                UPLOAD FILES
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Grid container style={{ padding: 30 }}>
                <Grid item sm={4}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Select file type</FormLabel>
                    <RadioGroup
                      aria-label="file"
                      name="file2"
                      value={value}
                      onChange={handleChange}
                    >
                      {isManager ? (
                        <React.Fragment>
                          <FormControlLabel
                            value="cr"
                            control={<Radio style={{ color: "#707070" }} />}
                            label="Customer Rules"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="rate"
                            control={<Radio style={{ color: "#707070" }} />}
                            label="Rates"
                            labelPlacement="end"
                          />

                          <FormControlLabel
                            value="pool"
                            control={<Radio style={{ color: "#707070" }} />}
                            label="Pools"
                            labelPlacement="end"
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <FormControlLabel
                            value="ccm"
                            control={<Radio style={{ color: "#707070" }} />}
                            label="CCM"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="iep"
                            control={<Radio style={{ color: "#707070" }} />}
                            label="Invoice"
                            labelPlacement="end"
                          />
                        </React.Fragment>
                      )}
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item sm={8}>
                  <UploadComp setFile={setFileValue} />
                  {!isManager && (
                    <React.Fragment>
                      <TextField
                        style={{ marginTop: 10, width: "100%" }}
                        label="Days for Offset"
                        variant="outlined"
                        value={daysOffset}
                        onChange={handleDaysChange}
                      />

                      <FormControlLabel
                        control={<Checkbox checked={useBolSsl} onChange={handleBolSslChange} />}
                        label="Use BOL/SSL"
                      />
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {!isManager && value === "ccm" && (
              <Grid item sm={12}>
                <Grid container>
                  <Grid item sm={6}></Grid>
                  <Grid
                    item
                    sm={6}
                    style={{
                      paddingLeft: 15,
                      paddingTop: 15,
                      paddingRight: 15,
                    }}
                  >
                    <Box style={{ width: "100%" }}>CCM Details</Box>
                  </Grid>
                  <Grid item sm={6}></Grid>
                  <Grid
                    item
                    sm={6}
                    style={{
                      paddingLeft: 15,
                      paddingBottom: 15,
                      paddingRight: 15,
                    }}
                  >
                    <Box>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Date received"
                          value={selectedDate}
                          style={{ width: "100%" }}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!isManager && value === "iep" && (
              <Grid item sm={12}>
                <Grid container>
                  <Grid item sm={6} style={{ padding: 15 }}>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
                        Select IEP
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={handleIEPDDChange}
                        labelWidth={labelWidth}
                        value={iepSelected}
                      >
                        {iepList &&
                          iepList.map((i: any) => {
                            return <MenuItem value={i.id_}>{i.abbrv}</MenuItem>;
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} style={{ padding: 15 }}>
                    <TextField
                      id="outlined-basic"
                      label="Invoice #"
                      variant="outlined"
                      style={{ width: "100%" }}
                      onChange={handleInvoiceNumChange}
                    />
                  </Grid>
                  <Grid item sm={6}></Grid>
                  <Grid item sm={6} style={{ padding: 15 }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date invoice received"
                        value={selectedDate}
                        style={{ width: "100%" }}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <Box>Today's Date: {moment().format("L")}</Box>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {(value === "" || value === undefined) && <Grid item sm={12}></Grid>}

            <Grid item sm={12}>
              <Box justifyContent="center" display="flex" style={{ margin: 30 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={fileValue === undefined || value === undefined ? true : false}
                  onClick={handleProcessClick}
                >
                  Process
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
}
