import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";
import { validateInvoiceRecord } from "../api";
import InvoiceRecDisputeDetails from "./InvoiceRecordDisputeDetails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 900,
      maxHeight: 700,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    check: {
      color: "rgba(65, 150, 68, 1)",
    },
    clear: {
      color: "rgba(245, 84, 44, 1)",
    },
    panel: {
      backgroundColor: "rgba(235, 235, 235, 1)",
      width: "100%",
    },
  })
);

export default function (props: any) {
  const [approvals, setApprovals] = React.useState<any | undefined>();
  const classes = useStyles();
  const invoiceRecordID = props && props.invoiceRecordID ? props.invoiceRecordID : undefined;

  React.useEffect(() => {
    if (invoiceRecordID) {
      loadPageInfo();
    }
  }, []);

  React.useEffect(() => {
  }, [approvals]);

  const loadPageInfo = () => {
    validateInvoiceRecord(invoiceRecordID, setApprovals);
  };

  const checkOrEx = (flag: boolean) => {
    if (flag) {
      return <CheckIcon className={classes.check} />;
    } else {
      return <ClearIcon className={classes.clear} />;
    }
  };

  return (
    <Box className={classes.panel}>
      <Grid
        container
        style={{
          overflow: "hidden",
          width: "100%",
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 10,
        }}
      >
        <Grid item sm={12}>
          <Box>
            <h5>Approval Criteria</h5>
          </Box>
        </Grid>
        <Grid item sm={12}>
          <table style={{ width: "100%", textAlign: "left" }}>
            <tr>
              <th>
                <b>Chassis</b>
              </th>
              <th>
                <b>Container</b>
              </th>
              <th>
                <b>Manifest</b>
              </th>
              <th>
                <b>SSL</b>
              </th>
              <th>
                <b>CCM</b>
              </th>
              <th>
                <b>Cutomer Rules</b>
              </th>
              <th>
                <b>Contract Rates</b>
              </th>
              <th>
                <b>Duplicate</b>
              </th>
            </tr>
            <tbody>
              <tr>
                <td>{checkOrEx(approvals && approvals.chassis)}</td>
                <td>{checkOrEx(approvals && approvals.container)}</td>
                <td>{checkOrEx(approvals && approvals.manifest)}</td>
                <td>{checkOrEx(approvals && approvals.ssl)}</td>
                <td>{checkOrEx(approvals && approvals.ccm)}</td>
                <td>{checkOrEx(approvals && approvals.customerRules)}</td>
                <td>{checkOrEx(approvals && approvals.rates)}</td>
                <td>{checkOrEx(approvals && approvals.duplicate)}</td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item sm={12}>
          <InvoiceRecDisputeDetails invoiceRecordID={invoiceRecordID} disableControls={props.disableControls} />
        </Grid>
      </Grid>
    </Box>
  );
}
