import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { getModuleAlias, getSsl, newSsl, updatedAddAlias, updateSsl } from "../api";
import WaitPopup from "./WaitPopup";

interface Props {
  id: number;
  handleClose: Function;
  newState: boolean;
}

export default function (props: Props) {
  const [ssl, setSsl] = React.useState<any | undefined>();
  const [name, setName] = React.useState("");
  const [abbrv, setAbbrv] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [glCode, setGlCode] = React.useState("");
  const [ccmGlCode, setCCMGlCode] = React.useState("");
  const [ccmGlCodeList, setCCMGlCodeList] = React.useState([]);
  const [duplicateCode, setduplicateCode] = React.useState(false);

  let openWait: boolean = true;

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  React.useEffect(() => {
    if (ssl) {
      setName(ssl.name);
      setEmail(ssl.contact_email);
      setAbbrv(ssl.abbrv);
    }
  }, [ssl]);

  React.useEffect(() => {
    if (duplicateCode) {
      setCCMGlCode(glCode);
    }
  }, [duplicateCode]);

  React.useEffect(() => {}, [ccmGlCodeList]);

  function loadPageInfo() {
    if (props.newState) {
      setSsl({ name: "", email: "", abbrv: "" });
    } else {
      getSsl(props.id, setSsl);
      getModuleAlias(props.id, (data) => {
        if (data) {
          setGlCode(data.alias);
          setCCMGlCodeList(data.ccm_alias ? data.ccm_alias.split(",") : []);
        }
      });
    }
  }

  function handleSave() {
    if (validated()) {
      let alias = { moduleID: -1, glCode: "", ccmGlCode: "" };
      ssl.name = name;
      ssl.email = email;
      ssl.abbrv = abbrv;
      ssl.module_type = "ssl";
      alias.glCode = glCode;
      alias.moduleID = props.id;
      alias.ccmGlCode = ccmGlCodeList.length ? ccmGlCodeList.join(",") : ccmGlCode;

      if (props.newState) {
        newSsl(ssl, (data) => {
          alias.moduleID = data.module_id;
          updatedAddAlias(alias, props.handleClose);
        });
      } else {
        updateSsl(ssl, (data) => {
          updatedAddAlias(alias, props.handleClose);
        });
      }
    } else {
      alert("Information is invalid or in the wrong format.");
    }
  }

  function validated() {
    if (props.newState) {
      if (name) {
        if (email) {
          return name && validateEmail(email);
        }
        return true;
      } else return false;
    } else {
      if (name) {
        if (email) {
          return name && validateEmail(email);
        }
        return true;
      }
    }
    return false;
  }

  function validateEmail(email: string) {
    if (email && email !== "") {
      if (email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        return true;
      }
      return false;
    }
    return true;
  }

  function handleAliasAddClick() {
    if (!ccmGlCodeList.find((item) => item === ccmGlCode)) {
      let aliasList = ccmGlCodeList;
      aliasList.push(ccmGlCode);
      setCCMGlCodeList(aliasList.filter((item) => item.length > 0));
    }
  }
  function handleAliasDeleteClick(item) {
    setCCMGlCodeList(ccmGlCodeList.filter((alias) => alias !== item));
  }

  let emailError: boolean = false;
  if (validateEmail(email)) {
    emailError = false;
  } else {
    emailError = true;
  }

  if (ssl) {
    openWait = false;
  }

  return (
    <div>
      <WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
      <Grid container>
        <Grid item sm={12}>
          <Box>{props.newState ? <h3>Add SSL</h3> : <h3>Edit SSL</h3>}</Box>
        </Grid>
        <Grid item sm={12}>
          <Grid container>
            <Grid item sm={5} style={{ margin: 10 }}>
              <TextField
                label="NAME"
                value={name}
                onChange={(event: any) => {
                  setName(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={5} style={{ margin: 10 }}>
              <TextField
                label="ABBREVIATION"
                value={abbrv}
                onChange={(event: any) => {
                  setAbbrv(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={5} style={{ margin: 10 }}>
              <TextField
                error={emailError ? true : false}
                label="EMAIL"
                type="email"
                value={email}
                onChange={(event: any) => {
                  setEmail(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={5} style={{ margin: 10 }}>
              <TextField
                label="ALIAS"
                value={glCode}
                onChange={(event: any) => {
                  setGlCode(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={12} style={{ margin: 10 }}>
              <IconButton onClick={handleAliasAddClick}>
                <AddCircleOutlineIcon />
              </IconButton>
              <TextField
                style={{ width: "30%" }}
                label="CCM ALIAS"
                value={ccmGlCode}
                onChange={(event: any) => {
                  setCCMGlCode(event.target.value);
                }}
                variant="outlined"
              />
              <FormControlLabel
                style={{ margin: 10 }}
                control={
                  <Checkbox
                    checked={duplicateCode}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setCCMGlCode(glCode);
                      }
                      setduplicateCode(event.target.checked);
                    }}
                  />
                }
                label="Duplicate Alias for CCM Alias"
              />
            </Grid>
            <Grid item sm={12} style={{ margin: 5 }}>
              <List dense={true}>
                {ccmGlCodeList &&
                  ccmGlCodeList.map((alias, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <React.Fragment>
                          <IconButton
                            onClick={() => {
                              handleAliasDeleteClick(alias);
                            }}
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                          {alias}
                        </React.Fragment>
                      </ListItemIcon>
                    </ListItem>
                  ))}
              </List>
            </Grid>
            <Grid item sm={12}>
              <Box style={{ float: "right" }}>
                <Button
                  style={{ margin: 5 }}
                  onClick={() => {
                    props.handleClose();
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Close
                </Button>
                <Button
                  style={{ margin: 5 }}
                  onClick={handleSave}
                  variant="outlined"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
