import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const drawerWidth = 240;
export const drawerHeight = 65;
export const drawerWidthClosed = 73;
export const drawerWidthClosedNarrow = 57;
export const appBarHeight = 63;

export const layoutStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%"
    },
    main: {
      flexGrow: 1,
      overflow: "hidden" // this is important for responsive pages
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap"
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      width: drawerWidthClosedNarrow,
      [theme.breakpoints.up("sm")]: {
        width: drawerWidthClosed
      }
    },
    drawerPaper: {
      overflow: "hidden"
    },
    selectedDrawerText: {
      borderBottom: "2px solid #fff",
      marginLeft: "2px",
      paddingBottom: "2px"
    },
    drawerSpacer: {
      flex: "auto"
    },
    center: {
      margin: "0 auto"
    },
    selected: {
      color: "#FFFFFF",
      "&:hover": {
        background: "#FFFFFF22"
      }
    },
    unSelected: {
      color: theme.palette.primary.light,
      opacity: 0.5,
      "&:hover": {
        background: "#FFFFFF22",
        opacity: 0.9
      }
    },
    drawerBackground: {
      background: theme.palette.primary.main
    },
    drawerLogoBackground: {
      background: theme.palette.primary.main,
      cursor: "pointer"
    },
    bigLogo: {
      background:
        "url(" +
        require("../../img/CPG_WheelzType_Final.png") +
        ") no-repeat center",
      backgroundSize: "cover",
      height: "50px"
    },
    tinyLogo: {
      background:
        "url(" +
        require("../../img/CPG_WheelzType_Final.png") +
        ") no-repeat center",
      backgroundSize: "contain",
      height: "50px",
      transform: "scale(2)"
    },
    logo: {
      display: "block",
      width: drawerWidth,
      height: drawerHeight
    },
    icon: {
      width: "40px",
      textAlign: "center"
    },
    iconSelected: {
      color: "#fff"
    },
    iconUnselected: {
      color: theme.palette.primary.light
    }
  })
);
