import InlineEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { getEmailTemplateSetting, updateEmailTemplateSetting } from "../api";
import Layout from "../components/layout/Layout";

interface Props {
  ccmTemplate: any;
  invoiceTemplate: any;
  handleClose: Function;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 900,
      maxHeight: 700,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

export default function (props: any) {
  const classes = useStyles();
  const [ccmTemplate, setCcmTemplate] = React.useState<any | undefined>();
  const [invoiceTemplate, setInvoiceTemplate] = React.useState<any | undefined>();
  const [iepTemplate, setIepTemplate] = React.useState<any | undefined>();
  const [ccmBody, setCcmBody] = React.useState<any | undefined>("default text");
  const [ccmSubject, setCcmSubject] = React.useState<any | undefined>("default text");
  const [invoiceBody, setInvoiceBody] = React.useState<any | undefined>("default text");
  const [invoiceSubject, setInvoiceSubject] = React.useState<any | undefined>("default text");
  const [iepBody, setIepBody] = React.useState<any | undefined>("default text");
  const [iepSubject, setIepSubject] = React.useState<any | undefined>("default text");

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  React.useEffect(() => {
    if (ccmTemplate) {
      setCcmBody(ccmTemplate.body.value);
      setCcmSubject(ccmTemplate.subject.value);
    }
  }, [ccmTemplate]);

  React.useEffect(() => {
    if (invoiceTemplate) {
      setInvoiceBody(invoiceTemplate.body.value);
      setInvoiceSubject(invoiceTemplate.subject.value);
    }
  }, [invoiceTemplate]);

  React.useEffect(() => {
    if (iepTemplate) {
      setIepBody(iepTemplate.body.value);
      setIepSubject(iepTemplate.subject.value);
    }
  }, [iepTemplate]);

  function loadPageInfo() {
    getEmailTemplateSetting( "CcmToIep", setCcmTemplate);
    getEmailTemplateSetting( "InvoiceToTerminal", setInvoiceTemplate);
    getEmailTemplateSetting( "InvoiceToIEP", setIepTemplate);
  }

  const handleClose = () => {
    loadPageInfo();
  };

  function handleSaveCcmTemplate() {
    ccmTemplate.body = ccmBody;
    ccmTemplate.subject = ccmSubject;
    updateEmailTemplateSetting( "CcmToIep", ccmTemplate, handleClose);
  }

  function handleSaveInvoiceTemplate() {
    invoiceTemplate.body = invoiceBody;
    invoiceTemplate.subject = invoiceSubject;
    updateEmailTemplateSetting( "InvoiceToTerminal", invoiceTemplate, handleClose);
  }

  function handleSaveIepTemplate() {
    iepTemplate.body = iepBody;
    iepTemplate.subject = iepSubject;
    updateEmailTemplateSetting( "InvoiceToIEP", iepTemplate, handleClose);
  }

  const subjectEditor = {
    toolbar: [],
    disablePlugins: ["toolbar"],
  };

  const bodyEditor = {};

  return (
    <Layout>
      <Paper style={{ margin: 5 }}>
        <Box style={{ padding: 10 }}>
          <Grid container style={{ overflow: "hidden", width: "100%" }}>
            <Grid item sm={12}>
              <Grid container>
                <Grid item sm={12}>
                  <Box>
                    <h3>EDIT EMAIL TEMPLATES</h3>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12}>
              <hr></hr>
              <h4>CCM Email to IEPs</h4>
              <h4>
                <i>Subject:</i>
              </h4>
              <CKEditor
                editor={InlineEditor}
                data={ccmSubject}
                config={subjectEditor}
                onInit={(editor) => {
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                }}
                onBlur={(event, editor) => {
                  setCcmSubject(editor.getData());
                }}
                onFocus={(event, editor) => {
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <h4>
                <i>Email Body:</i>
              </h4>
              <CKEditor
                editor={InlineEditor}
                data={ccmBody}
                config={bodyEditor}
                onInit={(editor) => {
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                }}
                onBlur={(event, editor) => {
                  setCcmBody(editor.getData());
                }}
                onFocus={(event, editor) => {
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <Box style={{ float: "left" }}>
                <Button
                  style={{ margin: 5 }}
                  onClick={handleSaveCcmTemplate}
                  variant="outlined"
                  color="primary"
                >
                  Save CCM Email
                </Button>
                <br></br>
                <br></br>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <hr></hr>
              <h4>Invoice Email to Terminals</h4>
              <h4>
                <i>Subject:</i>
              </h4>
              <CKEditor
                editor={InlineEditor}
                data={invoiceSubject}
                config={subjectEditor}
                onInit={(editor) => {
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                }}
                onBlur={(event, editor) => {
                  setInvoiceSubject(editor.getData());
                }}
                onFocus={(event, editor) => {
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <h4>
                <i>Email Body:</i>
              </h4>
              <CKEditor
                editor={InlineEditor}
                data={invoiceBody}
                config={bodyEditor}
                onInit={(editor) => {
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                }}
                onBlur={(event, editor) => {
                  setInvoiceBody(editor.getData());
                }}
                onFocus={(event, editor) => {
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <Box style={{ float: "left" }}>
                <Button
                  style={{ margin: 5 }}
                  onClick={handleSaveInvoiceTemplate}
                  variant="outlined"
                  color="primary"
                >
                  Save Invoice Email
                </Button>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <hr></hr>
              <h4>Invoice Email to IEPs</h4>
              <h4>
                <i>Subject:</i>
              </h4>
              <CKEditor
                editor={InlineEditor}
                data={iepSubject}
                config={subjectEditor}
                onInit={(editor) => {
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                }}
                onBlur={(event, editor) => {
                  setIepSubject(editor.getData());
                }}
                onFocus={(event, editor) => {
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <h4>
                <i>Email Body:</i>
              </h4>
              <CKEditor
                editor={InlineEditor}
                data={iepBody}
                config={bodyEditor}
                onInit={(editor) => {
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                }}
                onBlur={(event, editor) => {
                  setIepBody(editor.getData());
                }}
                onFocus={(event, editor) => {
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <Box style={{ float: "left" }}>
                <Button
                  style={{ margin: 5 }}
                  onClick={handleSaveIepTemplate}
                  variant="outlined"
                  color="primary"
                >
                  Save IEP Email
                </Button>
                <br></br>
                <br></br>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
}
