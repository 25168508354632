import { API_URL } from "./env";
import { authStore } from "./stores/authStore";
import { toastStore } from "./stores/toastStore";
import { fromAPI_Date } from "./types/Date";

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
export class APIError extends Error {
  originalError: any;
  constructor(message: string, error: any) {
    super(message);
    this.name = "APIError";
    this.originalError = error;
  }
}

function toastAndRedirectToSignin(msg: string) {
  toastStore.showToast("error", msg);
  console.log("Should be Toasting Error");
  authStore.clearMe(); // this will cause the top-level router to redirect
}

async function apiCallBase(
  method: string,
  route: string,
  data: any = null,
  hasAttachment: boolean = false
): Promise<Response> {
  const req: RequestInit = {
    method,
    headers: new Headers({}),
  };
  let cookie = getCookie("uwl-auth-token");
  if (cookie) {
    (req.headers as Headers).append("Authorization", cookie);
  }

  if (data !== null && data !== undefined && !hasAttachment) {
    req.body = JSON.stringify(data);
    (req.headers as Headers).append("Content-Type", "application/json");
  }
  if (data !== null && data !== undefined && hasAttachment) {
    req.body = data;
  }

  let res: Response;
  try {
    res = await fetch(API_URL + route, req);
  } catch (err) {
    throw new APIError("Network error", err);
  }
  return res;
}

async function apiCall<T = any>(
  method: string,
  route: string,
  data: any = null,
  hasAttachment: boolean = false,
  isDownloadFile: boolean = false
): Promise<T> {
  let res = await apiCallBase(method, route, data, hasAttachment);

  if (isDownloadFile) {
    return res as any;
  }

  let resBody: any;
  try {
    resBody = await res.json();
    if (resBody.success) {
      return resBody.data as T;
    } else {
      toastStore.showToast("error", "Server error in API call. See log for details.");

      console.log(
        "Error : ",
        resBody,
        "method :'",
        method,
        "' route :'",
        route,
        "' hasAttachment :'",
        hasAttachment,
        "' isDownloadFile :'",
        isDownloadFile
      );
    }
    //todo: add an else that checks for the error object and does...stuff.
  } catch (err) {
    console.log(
      "method :'",
      method,
      "' route :'",
      route,
      "' data :'",
      data,
      "' hasAttachment :'",
      hasAttachment,
      "' isDownloadFile :'",
      isDownloadFile
    );

    throw new APIError("Bad error", err);
  }

  let message = "Unexpected Error";
  const error = (resBody && resBody.error) || {};
  if (typeof error.message === "string" && error.message.trim().length > 0) {
    message = error.message;
  } else if (typeof error.msg === "string" && error.msg.trim().length > 0) {
    message = error.msg;
  }
  switch (resBody && resBody.error && resBody.type) {
    case "NotAuthenticatedError":
      toastAndRedirectToSignin(message);
      break;
    case "Unauthorized":
      toastAndRedirectToSignin(message);
      break;
  }

  throw new APIError(message, resBody.error);
}

export function post<T = any>(
  urlExtension: string,
  data: any,
  hasAttachment: boolean = false
): Promise<T> {
  return apiCall<T>("POST", urlExtension, data, hasAttachment);
}

export function get<T = any>(
  urlExtension: string,
  hasAttachment: boolean = false,
  isDownloadFile: boolean = false
): Promise<T> {
  return apiCall<T>("GET", urlExtension, null, hasAttachment, isDownloadFile);
}

export function forgotPassword(email: string) {
  return post("auth/forgotPassword", { email }, false);
}

export function verifyResetPasswordToken(token: string) {
  return post("auth/verifyResetPasswordToken", { token }, false);
}

export function resetPassword(newPassword: string, token: string) {
  return post("auth/resetPassword", { password: newPassword, token: token }, false);
}

/**************************************************
                  CCM CALLS
*/
export async function getCCMs(callback: Function) {
  callback(await get("ccms", false));
}

export async function getCCM(ccm_id: number, callback: Function) {
  callback(await get("ccm/" + ccm_id, false));
}

export async function getCCMRecords(ccm_id: number, callback: Function, status?: string) {
  if (status) {
    callback(await get("ccm/records/" + ccm_id + "/" + status.toUpperCase(), false));
  } else {
    callback(await get("ccm/" + ccm_id, false));
  }
}

export async function getCCMRecord(ccmRecordID: number, callback: Function) {
  callback(await get("ccm/record/" + ccmRecordID, false));
}

export async function getCCMRecordPossibleMatches(ccmRecordID: number, callback: Function) {
  callback(await get("ccm/records/matches/" + ccmRecordID, false));
}

export async function removeCCMRecordPossibleMatches(ccmRecordID: number, callback: Function) {
  callback(await get("ccm/records/matches/remove/" + ccmRecordID, false));
}

export async function updateCCMRecord(ccmRecordObj: any, callback: Function) {
  callback(await post("ccm/record/update", ccmRecordObj, false));
}

export async function emailCCM(ccmSendObj: any, callback: Function) {
  callback(await post("ccm/records/ccmsend", ccmSendObj, false));
}

export async function deleteCCM(ccmID: number, callback: Function) {
  callback(await post("ccm/delete/" + ccmID, null, false));
}

export async function markCCMAsCompleted(ccmID: string, callback: Function) {
  callback(await get("ccm/completed/" + ccmID, false));
}

export async function validateCCMRecords(
  ccmID: any,
  callback: Function,
  validateParams: any = undefined
) {
  if (validateParams) {
    let daysOffset: number = validateParams.daysOffset;
    let useBolSsl: boolean = validateParams.useBolSsl;
    callback(
      await get("ccm/records/validate/" + ccmID + "/" + daysOffset + "/" + useBolSsl, false)
    );
  } else {
    callback(await get("ccm/records/validate/" + ccmID, false));
  }
}

export async function getCCMAdditionalFields(CCMRecID: number, callback: Function) {
  callback(await get("ccm/record/additionals/" + CCMRecID, false));
}

/**************************************************
                  INVOICE CALLS
*/

export async function deleteInvoice(invoiceID: number, callback: Function) {
  callback(await get("invoice/delete/" + invoiceID, false));
}

export async function removePosibleDisputes(
  invoiceRecID: number,
  recordStatusID: number,
  callback: Function
) {
  callback(
    await get("invoice/records/remove/disputes/" + invoiceRecID + "/" + recordStatusID, false)
  );
}

export async function getInvoices(callback: Function) {
  callback(await get("invoices", false));
}

export async function getInvoice(invoice_id: number, callback: Function) {
  callback(await get("invoice/" + invoice_id, false));
}

export async function getInvoiceAdditionalFields(invRecID: number, callback: Function) {
  callback(await get("invoice/record/additionals/" + invRecID, false));
}

export async function getFullInvoice(invoice_id: number, callback: Function) {
  callback(await get("invoice/full/" + invoice_id, false));
}

export async function getInvoicesAP(): Promise<APInvoiceListItem[]> {
  const rows: any[] = await get("invoices/ap");
  return rows.map((row) => {
    const documented_dt = fromAPI_Date(row.documented_dt);

    let n_days_old = -1;
    if (documented_dt) {
      n_days_old = Math.round((Date.now() - documented_dt.getTime()) / (1000 * 3600 * 24));
    }

    return {
      ...row,
      documented_dt,
      n_days_old,
    };
  });
}

export async function getFullInvoiceAP(invoice_id: number): Promise<any> {
  return await get("invoice/recs/ap/" + invoice_id, false);
}

export async function getInvoiceRecordPossibleMatches(invoiceRecordID: number, callback: Function) {
  callback(await get("invoice/records/matches/" + invoiceRecordID, false));
}

export async function updateInvoiceRecManifestRef(
  invoice_rec_mani_ref_id: number,
  days_out: number,
  callback: Function
) {
  callback(
    await get("/invoice/records/manifestref/update/" + invoice_rec_mani_ref_id + "/" + days_out)
  );
}

export async function removeInvoiceRecordPossibleMatches(
  invoiceRecordID: number,
  callback: Function
) {
  callback(await get("invoice/records/matches/remove/" + invoiceRecordID, false));
}

export async function getInvoiceRecords(
  invoiceID?: number | string,
  callback?: Function,
  status?: string,
  terminalStatus?: string,
  terminalID?: number
) {
  if (status && invoiceID) {
    callback(await get("invoice/records/status/" + invoiceID + "/" + status.toUpperCase(), false));
  } else if (invoiceID && terminalID && terminalStatus) {
    callback(
      await get(
        "invoice/records/terminal/terminalstatus/" +
          invoiceID +
          "/" +
          terminalID +
          "/" +
          terminalStatus,
        false
      )
    );
  } else if (terminalID) {
    callback(await get("invoices/terminal/" + terminalID, false));
  } else if (terminalStatus && invoiceID) {
    callback(
      await get("invoice/records/terminal/" + invoiceID + "/" + terminalStatus.toUpperCase(), false)
    );
  } else if (invoiceID) {
    callback(await get("invoice/records/" + invoiceID + "/" + 0, false));
  }
}

export async function getSimpleInvoiceRecords(invoiceID?: string, callback?: Function) {
  if (invoiceID) {
    callback(await get("invoice/records/" + invoiceID + "/" + 1, false));
  }
}

export async function validateInvoiceRecords(
  invoicesID: any,
  callback: Function,
  validateParams: any = undefined
) {
  if (validateParams) {
    let daysOffset: number = validateParams.daysOffset;
    let useBolSsl: boolean = validateParams.useBolSsl;
    callback(
      await get(
        "invoice/records/validate/" + invoicesID + "/" + daysOffset + "/" + useBolSsl,
        false
      )
    );
  } else {
    callback(await get("invoice/records/validate/" + invoicesID, false));
  }
}

export async function validateInvoiceRecord(invoiceRecordID: any, callback: Function) {
  callback(await get("invoice/record/validate/" + invoiceRecordID, false));
}

export async function updateInvoiceRecord(invoiceRecordObj: any, callback: Function) {
  callback(await post("invoice/record/update", invoiceRecordObj, false));
}

export async function validatedInvoiceRecordWithNewManifest(
  invoiceRecObj: any,
  callback: Function
) {
  callback(await post("invoice/record/validate", invoiceRecObj, false));
}

export async function InvoiceRecordRemoveManifest(invoiceRecObj: any, callback: Function) {
  callback(await post("invoice/record/manifest/unassigned", invoiceRecObj, false));
}

export async function sendToTerminal(terminalSendObj: any, callback: Function) {
  callback(await post("invoice/records/terminalsend", terminalSendObj, false));
}

export async function markTerminalResponseAccepted(
  invoiceID: number,
  terminalID: number,
  callback: Function
) {
  callback(
    await post("invoice/records/termresponse/accepted/" + invoiceID + "/" + terminalID, null, false)
  );
}

export async function updatedTerminalStatus(terminalStatus: any, callback: Function) {
  callback(await post("invoice/records/termreply", terminalStatus, false));
}

export async function unlockInvoice(
  invoiceID: number | string,
  username: string,
  callback: Function
) {
  callback(await post("invoice/unlock/" + username + "/" + invoiceID, null, false));
}

export async function updateTermComments(
  invoiceRecordID: number,
  terminalCommentObj: any,
  callback: Function
) {
  callback(
    await post("invoice/records/termresponse/update/" + invoiceRecordID, terminalCommentObj, false)
  );
}

export async function updateTermResponse(
  recordId: number,
  userID: number,
  isAccepted: boolean,
  comment?: string
): Promise<boolean> {
  let data = {};
  if (isAccepted) {
    data = {
      isResponseAccepted: isAccepted,
      userID: userID,
    };
  } else {
    data = {
      isResponseAccepted: isAccepted,
      userID: userID,
      comment: comment,
    };
  }

  const result = await post("invoice/records/termresponse/update/" + recordId, data).then(
    (data) => {
      if (data) {
        return true;
      } else {
        return false;
      }
    }
  );
  return result;
}

export async function getOpenDisputes(): Promise<any[]> {
  const opendisputes = await get("invoices/opendisputes");
  return opendisputes;
}

export async function getInvoicesWithOpenDisputes(callback: Function) {
  callback(await get("invoices/withopendisputes", false));
}

export async function getDisputesByInvoiceId(invoiceID: number): Promise<any> {
  return await get("invoice/disputes/" + invoiceID, false);
}

export async function getLatestDisputes(invoiceRecID: number): Promise<any> {
  return await get("invoice/dispute/latest/" + invoiceRecID, false);
}

export async function markInvoicesAsCompleted(
  invoiceID: string,
  username: string,
  callback: Function
) {
  callback(await get("invoice/completed/" + invoiceID + "/" + username, false));
}

export async function createDisputeHistory(
  invoiceID: string,
  username: string,
  callback: Function
) {
  callback(await get("invoice/create/disputetransactions/" + invoiceID + "/" + username, false));
}

export async function updateOrCreateDispute(disputeObj: string, callback: Function) {
  callback(await post("invoice/dispute/updatelatest", disputeObj, false));
}

export async function createDispute(disputeObj: any, callback: Function) {
  callback(await post("invoice/dispute/new", disputeObj));
}

export async function emailIEPDisputeForm(iepSendObj: any, callback: Function) {
  callback(await post("invoice/records/iepsend", iepSendObj, false));
}

export async function downloadDisputeForm(invoiceID: number) {
  return get("export/dispute_form/invoice_id/" + invoiceID, false, true);
}

export async function getInvoiceRecord(invoiceRecordID: number, callback: Function) {
  callback(await get("invoice/record/" + invoiceRecordID, false));
}

export async function getInvoiceRecordWithDisputes(invoiceRecordID: number, callback: Function) {
  callback(await get("invoice/dispute/" + invoiceRecordID, false));
}

export async function getTerminalResponse(callback: Function) {
  callback(await get("invoice/terminal/responses", false));
}

export async function markTerminalResponseRead(invoiceID, callback: Function) {
  callback(await get("invoice/records/terminalresponse/markread/" + invoiceID, false));
}

export async function addCommentsToInvoiceRecord(invoiceRecCommentObj: any, callback: Function) {
  callback(await post("invoice/comments/add", invoiceRecCommentObj, false));
}

export async function addAPSummary(invoiceID: number, apSummaryRecList: any[], callback: Function) {
  callback(await post("invoice/apsummary/new/" + invoiceID, apSummaryRecList, false));
}

export async function getAPSummary(invoiceID: number, callback: Function) {
  callback(await get("invoice/ap/" + invoiceID, false));
}

export async function getAPHistoryDetail(apId: number, callback: Function) {
  callback(await get("invoice/aphistorydetails/" + apId, false));
}

export async function deleteAPSummary(apSummaryID: number, callback: Function) {
  callback(await post("invoice/ap/delete/" + apSummaryID, false));
}

export async function getAPSummaryByInvoiceID(invoiceID: number, callback: Function) {
  callback(await get("invoice/aps/" + invoiceID, false));
}

export async function uploadTermChassisFiles(
  data: any,
  invoiceRecID: number,
  hasAttachment: boolean
) {
  return await post("invoice/terminal/file/save/" + invoiceRecID, data, true);
}

export async function getInvRecSupportFileList(invoiceRecID: number, callback: Function) {
  callback(await get("invoice/record/supportfiles/" + invoiceRecID, false));
}

export async function writeOffDisputes(writeOffsObj: any, callback: Function) {
  callback(await post("/invoice/dispute/writeoffs", writeOffsObj, false));
}

export async function getInvoiceRecordDuplicates(invoiceID: number, callback: Function) {
  callback(await get("invoice/duplicates/" + invoiceID, false));
}

/**************************************************
                  USER CALLS
*/
export async function getUsers(callback: Function) {
  callback(await get("admin/users", false));
}

export async function getUser(userID: number, callback: Function) {
  callback(await get("admin/user/" + userID, false));
}

export async function updateUser(user: any, callback: Function) {
  callback(await post("admin/user/update", user, false));
}

export async function newUser(user: any, callback: Function) {
  callback(await post("admin/user/add", user, false));
}

export async function deleteUser(user_id: number, callback: Function) {
  callback(await post("admin/user/delete/" + user_id, null, false));
}
/**************************************************
                  TRINIUM CALLS
*/
export async function SearchTrinium(triniumObj: any, callback: Function) {
  callback(await post("trinium/", triniumObj, false));
}

/**************************************************
                  CREDIT CALLS
*/
export async function creditUpload(iepID: number, creditObj: any, callback: Function) {
  callback(await post("import/credits/" + iepID, creditObj, true));
}

/**************************************************
                  UTIL CALLS
*/

export async function quickSearch(searchItem: string, callback: Function) {
  callback(await get("admin/freeforall/search/" + searchItem, false));
}

export async function getRecQuickSearch(searchObj: string, callback: Function) {
  callback(await post("admin/quicksearch", searchObj, false));
}

export async function getTerminalNotifications(callback: Function) {
  callback(await get("notifications/category/chassis_terminal", false));
}

export async function markNotificationRead(notification_id, callback: Function) {
  callback(await get("notification/read/" + notification_id, false));
}

export async function getSettings(callback: Function) {
  callback(await get("admin/settings", false));
}

export async function getSetting(settingName: string, callback: Function) {
  callback(await get("admin/setting/" + settingName, false));
}

export async function getEmailTemplateSetting(settingGroupName: string, callback: Function) {
  callback(await get("admin/emailsetting/" + settingGroupName, false));
}

export async function updateEmailTemplateSetting(
  settingGroupName: string,
  template: any,
  callback: Function
) {
  callback(await post("admin/emailsetting/update/" + settingGroupName, template, false));
}

export async function getRates(callback: Function) {
  callback(await get("admin/rates", false));
}

export async function getRate(rateID: number, callback: Function) {
  callback(await get("admin/rate/" + rateID, false));
}

export async function newRate(rate: any, callback: Function) {
  callback(await post("admin/rate/add", rate, false));
}

export async function updateRate(rate: any, callback: Function) {
  callback(await post("admin/rate/update", rate, false));
}

export async function deleteRate(rate_id: number, callback: Function) {
  callback(await post("admin/rate/delete/" + rate_id, null, false));
}

export async function deleteCustomerRule(rule_id: number, callback: Function) {
  callback(await post("admin/customerrule/delete/" + rule_id, null, false));
}

export async function updateSettings(settings: any, callback: Function) {
  callback(await post("admin/settings/update", settings, false));
}

export async function getPoolList(callback: Function) {
  callback(await get("admin/pool", false));
}

export async function getPool(PoolId: number, callback: Function) {
  callback(await get("admin/get_pool/" + PoolId, false));
}

export async function updatePool(pool: any, callback: Function) {
  callback(await post("admin/update_pool", pool, false));
}

export async function newPool(pool: any, callback: Function) {
  callback(await post("admin/new_pool", pool, false));
}

export async function getIepList(callback: Function) {
  callback(await get("admin/iep", false));
}

export async function getIEP(IEPId: number, callback: Function) {
  callback(await get("admin/get_module/" + IEPId, false));
}
export async function updateIEP(IEP: any, callback: Function) {
  callback(await post("admin/update_module", IEP, false));
}
export async function newIEP(IEP: any, callback: Function) {
  callback(await post("admin/new_module", IEP, false));
}

export async function getStatusListByCategory(category: string, callback: Function) {
  callback(await get("admin/recordstatus/category/" + category, false));
}

export async function getGroups(callback: Function) {
  callback(await get("admin/groups", false));
}

export async function getRoles(callback: Function) {
  callback(await get("admin/roles", false));
}

export async function updatedAddAlias(aliasObj, callback: Function) {
  callback(await post("admin/alias/updatenew", aliasObj, false));
}

export async function getModuleAlias(moduleID, callback: Function) {
  callback(await get("admin/module/alias/" + moduleID, false));
}

export async function getTerminals(callback: Function) {
  callback(await get("admin/terminals", false));
}

export async function getTerminal(terminalId: number, callback: Function) {
  callback(await get("admin/get_module/" + terminalId, false));
}
export async function updateTerminal(terminal: any, callback: Function) {
  callback(await post("admin/update_module", terminal, false));
}
export async function newTerminal(terminal: any, callback: Function) {
  callback(await post("admin/new_module", terminal, false));
}

export async function getTerminalsForInvoice(invoiceID: number, callback: Function) {
  callback(await get("invoice/terminals/" + invoiceID, false));
}

export async function getSsl(SslId: number, callback: Function) {
  callback(await get("admin/get_module/" + SslId, false));
}
export async function updateSsl(ssl: any, callback: Function) {
  callback(await post("admin/update_module", ssl, false));
}
export async function newSsl(ssl: any, callback: Function) {
  callback(await post("admin/new_module", ssl, false));
}

export async function newDisputeReason(reason: any, callback: Function) {
  callback(await post("admin/new_reason", reason, false));
}

export async function getDisputeReasons(callback: Function) {
  callback(await get("admin/disputereasons", false));
}

export async function getDisputeReasonswithDepenencies(callback: Function) {
  callback(await get("admin/disputereasonsdependencies", false));
}

export async function getDisputeReason(reasonID: number, callback: Function) {
  callback(await get("admin/disputereason/" + reasonID, false));
}

export async function updateDisputeReason(disputeReason, callback: Function) {
  callback(await post("admin/update/disputereason", disputeReason, false));
}

export async function deleteDisputeReason(disputeReasonID, callback: Function) {
  callback(await post("admin/delete/disputereason/" + disputeReasonID, null, false));
}

export async function uploadFile(
  data: any,
  hasAttachment: boolean,
  isCCM?: boolean,
  isIEP?: boolean,
  isCR?: boolean,
  isRate?: boolean,
  isPool?: boolean
) {
  if (isCCM) {
    return await post("import/ccm", data, true);
  } else if (isIEP) {
    return await post("import/iep", data, true);
  } else if (isCR) {
    return await post("import/customerrules", data, true);
  } else if (isRate) {
    return await post("import/rates", data, true);
  } else if (isPool) {
    return await post("import/pools", data, true);
  }
}

export async function getSslList(callback: Function) {
  callback(await get("admin/get_ssl_list", false));
}

export async function getCustomerList(callback: Function) {
  callback(await get("admin/get_customer_list", false));
}
export async function getCustomer(customerId: number, callback: Function) {
  callback(await get("admin/get_module/" + customerId, false));
}
export async function updateCustomer(customer: any, callback: Function) {
  callback(await post("admin/update_module", customer, false));
}
export async function newCustomer(customer: any, callback: Function) {
  callback(await post("admin/new_module", customer, false));
}

export async function getCannedResponseList(callback: Function) {
  callback(await get("admin/get_customer_rule_response_list", false));
}

export async function getFileMappingsByIep(iep_id: any, callback: Function) {
  callback(await get("admin/get_file_mappings_by_iep/" + iep_id, false));
}

export async function updateFileMap(file_map, callback: Function) {
  callback(await post("admin/update_file_mapping", file_map, false));
}

export async function getFileMapDbColumns(callback: Function) {
  callback(await get("admin/get_file_map_db_columns", false));
}

export function getAPIURL() {
  return API_URL;
}

/**************************************************
                  RULES CALLS
*/
export async function getSslRules(callback: Function) {
  callback(await get("admin/get_ssl_rules", false));
}

export async function getSslRule(ruleID: number, callback: Function) {
  callback(await get("admin/get_ssl_rule/" + ruleID, false));
  console.log("got ssl rule from api");
}

export async function updateSslRule(rule: any, callback: Function) {
  callback(await post("admin/update_ssl_rule", rule, false));
}

export async function addSslRule(rule: any, callback: Function) {
  callback(await post("admin/add_ssl_rule", rule, false));
}

export async function getCustomerRules(callback: Function) {
  callback(await get("admin/get_customer_rules", false));
}

export async function getCustomerRule(ruleID: number, callback: Function) {
  callback(await get("admin/get_customer_rule/" + ruleID, false));
}

export async function updateCustomerRule(rule: any, callback: Function) {
  callback(await post("admin/update_customer_rule", rule, false));
}

export async function addCustomerRule(rule: any, callback: Function) {
  callback(await post("admin/add_customer_rule", rule, false));
}

/**************************************************
                  REPORTING CALLS
*/
export async function updateParams(reportID: number, params: any, callback: Function) {
  callback(await post("report/params/" + reportID, params, false));
}

export async function getReportParams(reportID: number, callback: Function) {
  callback(await get("report/params/" + reportID, false));
}

export async function deleteReport(reportID: number, callback: Function) {
  callback(await post("reports/delete/" + reportID, null, false));
}

export async function getFullReport(reportID: number, callback: Function) {
  callback(await get("reports/details/full/" + reportID, false));
}

export async function getReportColumns(callback: Function) {
  callback(await get("report/columns", false));
}

export async function getReports(callback: Function) {
  callback(await get("reports", false));
}

export async function test(callback: Function) {
  callback(await get("test/", false));
}

function getOperators() {
  const operators = [
    "Equals",
    "Less Than",
    "Greater Than",
    "Less Than or Equal To",
    "Greater Than or Equal To",
    "Not Equal To",
    "Contains",
    "Does Not Contain",
    "Previous Week",
    "Previous Month",
  ];

  return operators;
}

export function getReportOperators() {
  return getOperators();
}

export async function saveReport(reportProps: any, callback: Function) {
  callback(await post("reports/save_report", reportProps, false));
}

export async function runAdHocReport(reportProps: any, callback: Function) {
  callback(await post("reports/run_ad_hoc", reportProps, false));
}

export async function emailAdHocReport(reportProps: any, callback: Function) {
  callback(await post("export/email_ad_hoc_report", reportProps, false));
}
