import { Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { getIepList } from "../api";
import Layout from "../components/layout/Layout";
import UpdateUploadFileMap from "../components/UpdateUploadFileMap";
import { authStore } from "../stores/authStore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(3),
    },
    wrapper: {
      width: "100%",
      height: "100%",
    },
    inputWrapper: {
      position: "absolute",
      overflow: "hidden",
      display: "inline",
      width: "100%",
      height: "50%",
      zIndex: 1,
      opacity: 0,
      left: 0,
      top: 0,
    },
    paper: {
      position: "absolute",
      width: 500,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);


function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface Props {
  style: any;
}

export default function (props: Props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [value, setValue] = React.useState<string>("ccm");
  const [selectedItem, setSelected] = React.useState<number>();
  const [iepCode, setIepCode] = React.useState<any | undefined>();
  const [iepList, setIepList] = React.useState<any | undefined>();
  const [open, setOpen] = React.useState(false);
  const [newState, setNewState] = React.useState(false);
  const auth = authStore.use();
  const me = auth.me;

  if (!iepList) {
    getIepList( setIepList);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleIepDDChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIepCode(event.target.value);
  };

  const handleProceedClick = (event: any, iepCode: any) => {
    if (iepCode) {
      setNewState(false);
      setIepCode(iepCode);
      setOpen(true);
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
    let newSelected: number = -1;
    newSelected = name;
    setSelected(newSelected);
  };

  const loadPageInfo = () => {
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    loadPageInfo();
    setOpen(false);
  };

  const [labelWidth, setLabelWidth] = React.useState(0);

  return (
    <Layout>
      <Paper style={{ margin: 5 }}>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
        >
          <div style={modalStyle} className={classes.paper}>
            <UpdateUploadFileMap id={iepCode} handleClose={handleClose} newState={newState} />
          </div>
        </Modal>
        <Box style={{ padding: 30 }}>
          <Grid
            container
            style={{
              width: "auto",
              margin: "0% 20%",
            }}
          >
            <Grid item sm={12} xs={12} style={{ padding: 20 }}>
              <Box justifyContent="center" display="flex">
                <h3>Manage Upload File Mappings</h3>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Grid container style={{ padding: 30 }}>
                <Grid item sm={12} style={{ margin: 10 }}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Select IEP to map upload file columns."
                    value={iepCode}
                    SelectProps={{ displayEmpty: true }}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleIepDDChange}
                  >
                    {iepList &&
                      iepList.map((item, index) => {
                        if (iepList.length <= 0) {
                          return <div />;
                        }
                        return (
                          <MenuItem key={index} value={item.id_}>
                            {item.abbrv}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12}>
              <Box justifyContent="center" display="flex" style={{ margin: 30 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={iepCode === undefined ? true : false}
                  onClick={(event) => handleProceedClick(event, iepCode)}
                >
                  Proceed
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
}
