import React from "react";
import { getInvoiceRecord, getInvoiceRecordDuplicates } from "../api";
import InvoiceNeedsReview from "./CustomTable/invoiceWorkflowTable/InvoiceNeedsReview";


interface Props {
  invoiceRecID: number;
  statusList: any[];
  disputeReasons: any[];
  PopoverControl: Function;
}

export default function (props: Props) {
  const [invoiceRecData, setInvoiceRecData] = React.useState<any|undefined>([]);
  const [invoice, setInvoice] = React.useState<any|undefined>([]);
  React.useEffect(() => {loadPageInfo(); }, []);

  const loadPageInfo = ()=>{
    getInvoiceRecord(props.invoiceRecID,(data)=>{
      if(data){
        setInvoice(data);
      }
    });
    getInvoiceRecordDuplicates(props.invoiceRecID,(data)=>{
        if (data){
          setInvoiceRecData(data);
        }
    });
  };

  return (
    <div>
      <div><h2>INVOICE: {invoice.invoice_num}</h2></div>
     <InvoiceNeedsReview
        viewTrinium={false}
        viewReason={true}
        viewReview={true}
        viewPopOver={false}
        invoiceData={invoiceRecData ? invoiceRecData : []}
        statusData={props.statusList ? props.statusList : []}
        disputeReasonData={props.disputeReasons ? props.disputeReasons : []}
        PopoverControl={props.PopoverControl}
        maxHeight={600}
        disableControls={true}
        disableDropdown={true}
        isComplete={true}
        quickSearchDisplays={true}
        />
    </div>
  );
}
