import { Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { useHistory } from "react-router-dom";
import { creditUpload, getIepList } from "../api";
import Layout from "../components/layout/Layout";
import UploadComp from "../components/upload/UploadComponent";
import { authStore } from "../stores/authStore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(3),
    },
    wrapper: {
      width: "100%",
      height: "100%",
    },
    inputWrapper: {
      position: "absolute",
      overflow: "hidden",
      display: "inline",
      width: "100%",
      height: "50%",
      zIndex: 1,
      opacity: 0,
      left: 0,
      top: 0,
    },
    paper: {
      position: "absolute",
      width: 500,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

function rand() {
  return Math.round(Math.random() * 15) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface Props {
  style: any;
}

export default function (props: Props) {
  const classes = useStyles();
  const history = useHistory();

  const [modalStyle] = React.useState(getModalStyle);
  const [fileValue, setFileValue] = React.useState<any>();
  const [iepSelected, setIepSelected] = React.useState(-1);
  const [iepList, setIepList] = React.useState<any | undefined>();
  const [open, setOpen] = React.useState(false);
  const [textWhileWait, setTextWhileWait] = React.useState("");
  const [creditUploaded, setCreditUploaded] = React.useState<any | undefined>();
  const auth = authStore.use();
  const me = auth.me;
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  var strtoday = mm + "/" + dd + "/" + yyyy;

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  React.useEffect(() => {
    if (creditUploaded) {
      setOpen(false);
    }
  }, [creditUploaded]);

  const loadPageInfo = () => {
    getIepList( setIepList);
  };

  const handleIEPDDChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIepSelected(event.target.value as number);
  };

  const handleProcessClick = () => {
    if (fileValue) {
      const formData = new FormData();
      const additdata = '{"username":"' + me.username + '"}';

      formData.append("data", additdata);
      formData.append("file", fileValue);

      creditUpload( iepSelected, formData, (data) => {
        if (data) {
          history.push("/dashboard");
          setTextWhileWait("...Upload Done!");
        }
      });
      setOpen(true);
      setTextWhileWait("...Uploading File");
    }
  };

  return (
    <Layout>
      <Paper style={{ margin: 5 }}>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
        >
          <div style={modalStyle} className={classes.paper}>
            {textWhileWait}
          </div>
        </Modal>
        <Box style={{ padding: 30, margin: "auto" }}>
          <Grid
            container
            style={{
              width: "auto",
              margin: "0% 20%",
            }}
          >
            <Grid item sm={12} xs={12} style={{ padding: 30 }}>
              <Box justifyContent="center" display="flex">
                UPLOAD CREDIT FILES
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Grid container>
                <Grid item sm={8} style={{ margin: "auto", padding: 15 }}>
                  <UploadComp setFile={setFileValue} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Grid container>
                <Grid item sm={8} style={{ margin: "auto", padding: 15 }}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                    >
                      SELECT IEP TO APPLY CREDIT FILE
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      onChange={handleIEPDDChange}
                      value={iepSelected}
                    >
                      {iepList &&
                        iepList.map((i: any) => {
                          return <MenuItem value={i.id_}>{i.abbrv}</MenuItem>;
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={6}></Grid>
                <Grid item sm={8} style={{ margin: "auto", padding: 15 }}>
                  <Box>Today's Date: {strtoday}</Box>
                </Grid>
                <Grid item sm={8} style={{ margin: "auto" }}>
                  <Box>Note: Must use Dispute File Form. Must include columns (ID, IEP COMMENT 1, CREDIT)</Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Box justifyContent="center" display="flex" style={{ margin: 30 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={fileValue === undefined ? true : false}
                  onClick={handleProcessClick}
                >
                  Process
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
}
