import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Moment } from "moment";
import React from "react";
import {
  addCommentsToInvoiceRecord,
  createDispute,
  getDisputeReasons,
  getAPSummary, getInvoiceRecordWithDisputes
} from "../api";
import { formatCurrency } from "../formatters";
import { authStore } from "../stores/authStore";
import InvRecSupportFiles from "./InvRecSupportFiles";
import WaitPopup from "./WaitPopup";

const moment = require('moment');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      minWidth: 700,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    check: {
      color: "rgba(65, 150, 68, 1)",
    },
    clear: {
      color: "rgba(245, 84, 44, 1)",
    },
    panel: {
      backgroundColor: "rgba(235, 235, 235, 1)",
      width: "100%",
    },
  })
);

export default function (props: any) {
  const classes = useStyles();
  const [state, setState] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [secondaryAmount, setSecondaryAmount] = React.useState(0);
  const [initialAmtApproved, setInitialAmtApproved] = React.useState(0);
  const [initialAmtDeclined, setInitialAmtDeclined] = React.useState(0);
  const [invDispute, setInvDispute] = React.useState<any | undefined>();
  const [disputes, setDisputes] = React.useState<any | undefined>();
  const [comments, setComments] = React.useState<any | undefined>();
  const [internalComments, setInternalComments] = React.useState<string>("");
  const [externalComments, setExternalComments] = React.useState<string>("");
  const [addInternalComments, setAddInternalComments] = React.useState<string | undefined>();
  const [addExternalComments, setAddExternalComments] = React.useState<string | undefined>();
  const [waitMsg, setWaitMsg] = React.useState<string | undefined>("...Loading Info");
  const [openWait, setOpenWait] = React.useState<boolean>(true);
  const [disputeReasons, setDisputeReasons] = React.useState<any | undefined>();
  const [totalPaid, setTotalPaid] = React.useState<number | undefined>(0);
  const [totalCredits, setTotalCredits] = React.useState<number | undefined>(0);
  const [addedComment, setAddedComment] = React.useState<any | undefined>();
  const [createdDispute, setCreatedDispute] = React.useState<any | undefined>();
  const [apSummary, setApSummary] = React.useState<any | undefined>();
  const [primaryAPDate, setPrimaryAPDate] = React.useState<any | Date>();
  const [secondaryAPDate, setSecondaryAPDate] = React.useState<any | Date>();

  const [responseDate, setResponseDate] = React.useState<Moment | undefined>(moment());
  const [rebuttalDate, setRebuttalDate] = React.useState<Moment | undefined>(moment());
  const auth = authStore.use();
  const me = auth.me;

  const invoiceRecordID = props.invoiceRecordID;

  React.useEffect(() => {
    if (invoiceRecordID) loadPageInfo();
  }, []);

  React.useEffect(() => {
    if (comments && comments.length > 0) {
      let internal = [];
      let external = [];

      comments.forEach((element) => {
        element.created_dt = moment.utc(element.created_dt);
      });

      comments.sort((a: any, b: any) => {
        return b.created_dt - a.created_dt;
      });
      comments.forEach((element) => {
        if (element.is_internal_comment) {
          internal.push(element);
        } else {
          external.push(element);
        }
      });
      formatComments(internal, setInternalComments);
      formatComments(external, setExternalComments);
    }
  }, [comments]);

  React.useEffect(() => {
    if (invDispute) {
      let temInvDisputes = invDispute;
      setComments(invDispute.comments);
      if (temInvDisputes.disputes_transactions && temInvDisputes.disputes_transactions.length > 0) {
        temInvDisputes.disputes_transactions.forEach((disp) => {
          if (disp.disp_dt) {
            disp.disp_dt = moment.utc(disp.disp_dt);
          }
          if (disp.disp_updated_dt) {
            disp.disp_updated_dt = moment.utc(disp.disp_updated_dt);
          }
          if (disp.response_dt) {
            disp.response_dt = moment.utc(disp.response_dt);
          }
          if (disp.rebuttal_dt) {
            disp.rebuttal_dt = moment.utc(disp.rebuttal_dt);
          }
        });
        temInvDisputes.disputes_transactions.sort((a: any, b: any) => {
          return b.id_ - a.id_;
        });
        setState(
          temInvDisputes.disputes_transactions[0]
            .paid_resolved == true ? true : false
        );
        
        setDisputes(temInvDisputes.disputes_transactions);
      }
      setOpenWait(false);
    }
  }, [invDispute]);

  React.useEffect(() => {

    if (disputes && disputes.length > 0) {
      let initAmtApproved = 0;
      if (disputes[0].approved_amt) {
        initAmtApproved = disputes[0].approved_amt;
        setInitialAmtApproved(initAmtApproved);
      }
      if (disputes[0].disp_amt) {
        setInitialAmtDeclined(disputes[0].disp_amt);
      }

      setTotalPaid(disputes[0].second_apprv_amt ? disputes[0].second_apprv_amt : 0 + initAmtApproved);
      setTotalCredits(disputes[0].credit_amt ? disputes[0].credit_amt : 0);

    }
  }, [disputes]);

  React.useEffect(() => {
    if (addedComment) {
      let disputeObj: any = {};
      disputeObj.username = me.username;
      disputeObj.invoiceRecordID = invoiceRecordID;
      disputeObj.credit = amount;
      disputeObj.secondaryPayment = secondaryAmount;
      disputeObj.paidResolved = state;
      disputeObj.responseDt = responseDate ? moment.utc(responseDate.toLocaleString()).format("L"): null;
      disputeObj.rebuttalDt = rebuttalDate ? moment.utc(rebuttalDate.toLocaleString()).format("L"): null;
      setWaitMsg("...Saving Dispute");
      setOpenWait(true);
      createDispute( disputeObj, setCreatedDispute);
    }
  }, [addedComment]);

  React.useEffect(() => {
    if (createdDispute) {
      setOpenWait(false);
      props.OnClose();
    }
  }, [createdDispute]);

  React.useEffect(() => {
    if (apSummary && apSummary.length == 1 && apSummary[0].pay_to_month) {
      setPrimaryAPDate(moment.utc(apSummary[0].pay_to_month));
    }
    if (apSummary && apSummary.length > 1 && apSummary[apSummary.length - 1].pay_to_month) {
      setSecondaryAPDate(moment.utc(apSummary[apSummary.length - 1].pay_to_month));
    }
  }, [apSummary]);

  const formatComments = (listOfComments: any[], callback: Function) => {
    let commentsStr: string = "";
    listOfComments.forEach((comm) => {
      commentsStr += "[" + comm.created_dt.format("L") + "]:\n";
      commentsStr += comm.comment + "\n";
    });
    callback(commentsStr);
  };

  const loadPageInfo = function () {
    getInvoiceRecordWithDisputes( invoiceRecordID, setInvDispute);
    getDisputeReasons( setDisputeReasons);
    getAPSummary( invoiceRecordID, setApSummary);
  };

  const handleTextChange = function (event: any, isCredit: boolean) {
    if (event.target.value && event.target.value.length == 1 && event.target.value === '-') {
      event.target.value = "-0";
    }
    else {
      event.target.value = event.target.value.match(/\-?\d+\.?[0-9]?[0-9]?/);
    }
    if (isCredit) {
      setAmount(event.target.value);
    } else {
      setSecondaryAmount(event.target.value);
    }
  };

  const handleChange = function (event: any) {
    setState(event.target.checked);
  };

  const hangleAddExternalComment = function (event: any) {
    setAddExternalComments(event.target.value);
  };
  const hangleAddInternalComment = function (event: any) {
    setAddInternalComments(event.target.value);
  };

  const handleResponseDateChange = (date: Moment | null) => {
    setResponseDate(date);
  };

  const handleRebuttalDateChange = (date: Moment | null) => {
    setResponseDate(date);
  };

  const handleSave = function () {
    if (invoiceRecordID) {
      let invoiceRecObj: any = {};

      invoiceRecObj.invoiceRecordID = invoiceRecordID;
      invoiceRecObj.username = me.username;
      invoiceRecObj.comments = [];

      if (addInternalComments) {
        invoiceRecObj.comments.push({ comment: addInternalComments, isInternalComment: true });
      }
      if (addExternalComments) {
        invoiceRecObj.comments.push({ comment: addExternalComments, isInternalComment: false });
      }
      setWaitMsg("...Saving Comments");
      setOpenWait(true);
      addCommentsToInvoiceRecord( invoiceRecObj, setAddedComment);
    }
  };

  const handleDDSwitchChange = function (event, isExternal) {
    let pretext = "";
    if (isExternal) {
      if (addExternalComments) {
        pretext = addExternalComments + "\n";
      }
      pretext = pretext + disputeReasons.find((ele) => ele.id_ == event.target.value).reason;
      setAddExternalComments(pretext);
    } else {
      if (addInternalComments) {
        pretext = addInternalComments + "\n";
      }
      pretext = pretext + disputeReasons.find((ele) => ele.id_ == event.target.value).reason;
      setAddInternalComments(pretext);
    }
  };

  return (
    <Box className={classes.panel}>
      <WaitPopup open={openWait}>{waitMsg}</WaitPopup>
      <Grid
        container
        style={{
          overflow: "hidden",
          width: "100%",
          padding: 10,
        }}
      >
        <Grid item sm={12}>
          <h5>DISPUTE DETAILS</h5>
        </Grid>
        <Grid item sm={12}>
          <Grid container>
            <Grid item sm={3}>
              <Grid container>
                <Grid item sm={12}>
                  Internal Comments
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    id="internal_com_read"
                    disabled={true}
                    multiline
                    rows="4"
                    defaultValue="Default Value"
                    variant="outlined"
                    value={internalComments ? internalComments.toUpperCase() : ""}
                    style={{ backgroundColor: "white", width: "90%" }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    size="small"
                    select
                    variant="outlined"
                    value={-1}
                    onChange={(event) => {
                      handleDDSwitchChange(event, false);
                    }}
                  >
                    {disputeReasons
                      ? disputeReasons.map((item) => {
                        return (
                          <MenuItem key={item.id_} value={item.id_}>
                            {item.reason}
                          </MenuItem>
                        );
                      })
                      : undefined}
                  </TextField>
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    id="internal_com"
                    multiline
                    rows="4"
                    variant="outlined"
                    value={addInternalComments}
                    onChange={hangleAddInternalComment}
                    style={{ backgroundColor: "white", width: "90%" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={3}>
              <Grid container>
                <Grid item sm={12}>
                  External Comments
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    id="external_com_read"
                    disabled={true}
                    multiline
                    rows="4"
                    value={externalComments ? externalComments.toUpperCase() : ""}
                    variant="outlined"
                    style={{ backgroundColor: "white", width: "90%" }}
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    size="small"
                    select
                    variant="outlined"
                    value={-1}
                    onChange={(event) => {
                      handleDDSwitchChange(event, true);
                    }}
                  >
                    {disputeReasons
                      ? disputeReasons.map((item) => {
                        return (
                          <MenuItem key={item.id_} value={item.id_}>
                            {item.reason}
                          </MenuItem>
                        );
                      })
                      : undefined}
                  </TextField>
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    id="external_com"
                    multiline
                    rows="4"
                    value={addExternalComments}
                    variant="outlined"
                    onChange={hangleAddExternalComment}
                    style={{ backgroundColor: "white", width: "90%" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={3}>
              <Grid container>
                <Grid item sm={12}>
                  <Box>
                    <b>Pre-Reconciliation:</b>
                    ${invDispute && invDispute.total_billed ? formatCurrency(invDispute.total_billed - (totalPaid + totalCredits)) : 0}
                  </Box>
                </Grid>
                <Grid item sm={12}></Grid>
                <Grid item sm={12}>
                  <Box>
                    <b>Total Billed: </b>
                    ${invDispute && invDispute.total_billed ? formatCurrency(invDispute.total_billed) : 0}
                  </Box>
                </Grid>
                <Grid item sm={12}>

                </Grid>
                <Grid item sm={12}>
                  <Box>
                    <b>Initial amount approved: </b>
                    ${formatCurrency(initialAmtApproved)}
                  </Box>
                </Grid>
                <Grid item sm={12}>

                </Grid>
                <Grid item sm={12}>
                  <Box>
                    <b>Initial amount declined: </b>
                    ${formatCurrency(initialAmtDeclined)}
                  </Box>
                </Grid>
                <Grid item sm={12}>

                </Grid>
                <Grid item sm={12}>
                  <Box>
                    <b>Reason for declined dispute: </b>
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  {invDispute && invDispute.dispute_reason ? invDispute.dispute_reason : ""}
                </Grid>
                <Grid item sm={12}>
                  <Box>
                    <b>Pending AP: </b>
                    ${formatCurrency(totalPaid)}
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  <b>Total Credit: </b>
                  ${disputes && disputes.length > 0
                    ? disputes[0].credit_amt
                      ? formatCurrency(disputes[0].credit_amt)
                      : 0
                    : 0}
                </Grid>
                <Grid item sm={12} style={{ marginRight: 15 }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Response Date"
                      value={responseDate}
                      style={{ width: "100%", backgroundColor: "white" }}
                      onChange={handleResponseDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm={12} style={{ marginRight: 15 }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Rebuttal Date"
                      value={rebuttalDate}
                      style={{ width: "100%", backgroundColor: "white" }}
                      onChange={handleRebuttalDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm={12} style={{ marginRight: 5 }}>
                  <InvRecSupportFiles invoiceRecID={invoiceRecordID} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={3}>
              <Grid container>
                <Grid item sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state}
                        onChange={handleChange}
                        name="finalize"
                        color="primary"
                      />
                    }
                    label="Finalize & Close"
                  />
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    id="credit"
                    label="Credit"
                    variant="outlined"
                    onChange={(event) => {
                      handleTextChange(event, true);
                    }}
                    style={{
                      backgroundColor: "white",
                      width: "90%",
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  Prev. amt: ${" "}
                  {disputes && disputes.length > 0
                    ? disputes.length > 1
                      ? disputes[0].credit_amt && disputes[1].credit_amt
                        ? formatCurrency(disputes[0].credit_amt - disputes[1].credit_amt)
                        : formatCurrency(disputes[0].credit_amt)
                      : 0
                    : 0}
                </Grid>
                <Grid item sm={12}>
                  {""}
                  {disputes && disputes.length > 0
                    ? disputes.length > 1
                      ? disputes[0].disp_updated_by_name
                        ? disputes[0].disp_updated_by_name
                        : ""
                      : disputes[0].disp_updated_by_name
                        ? disputes[0].disp_updated_by_name
                        : ""
                    : ""}
                </Grid>
                <Grid item sm={12}>
                  {""}
                  {disputes && disputes.length > 0
                    ? disputes.length > 1
                      ? disputes[0].disp_updated_dt
                        ? disputes[0].disp_updated_dt.format("L")
                        : ""
                      : disputes[0].disp_updated_dt
                        ? disputes[0].disp_updated_dt.format("L")
                        : ""
                    : ""}
                </Grid>
                <Grid item sm={12}>
                  <TextField
                    id="sec_credit"
                    label="Secondary payment"
                    variant="outlined"
                    onChange={(event) => {
                      handleTextChange(event, false);
                    }}
                    style={{
                      backgroundColor: "white",
                      width: "90%",
                    }}
                  />
                </Grid>
                <Grid item sm={12}>
                  Prev. amt: $ {""}
                  {disputes && disputes.length > 0
                    ? disputes.length > 1
                      ? disputes[0].second_apprv_amt
                        ? formatCurrency(disputes[0].second_apprv_amt - disputes[1].second_apprv_amt)
                        : 0
                      : disputes[0].second_apprv_amt
                        ? formatCurrency(disputes[0].second_apprv_amt - disputes[1].second_apprv_amt)
                        : 0
                    : 0}
                </Grid>
                <Grid item sm={12}>
                  {""}
                  {disputes && disputes.length > 0
                    ? disputes.length > 1
                      ? disputes[0].disp_updated_by_name
                        ? disputes[0].disp_updated_by_name
                        : ""
                      : disputes[0].disp_updated_by_name
                        ? disputes[0].disp_updated_by_name
                        : ""
                    : ""}
                </Grid>
                <Grid item sm={12}>
                  {""}
                  {disputes && disputes.length > 0
                    ? disputes.length > 1
                      ? disputes[0].disp_updated_dt
                        ? disputes[0].disp_updated_dt.format("L")
                        : ""
                      : disputes[0].disp_updated_dt
                        ? disputes[0].disp_updated_dt.format("L")
                        : ""
                    : ""}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} style={{ padding: 2 }}>
          <table
            style={{
              width: "100%",
              textAlign: "left",
              border: "1px solid black",
              tableLayout: "fixed",
            }}
          >
            <tr>
              <th>ID</th>
              <th>IEP</th>
              <th>Invoice</th>
              <th>Status</th>
              <th>Chassis</th>
              <th>Container</th>
            </tr>
            <tbody>
              <tr>
                <td>{invDispute ? invDispute.id_ : null}</td>
                <td>{invDispute && invDispute.iep_name ? invDispute.iep_name.toUpperCase() : null}</td>
                <td>{invDispute && invDispute.invoice_num ? invDispute.invoice_num.toUpperCase() : null}</td>
                <td></td>
                <td>{invDispute && invDispute.chassis ? invDispute.chassis.toUpperCase() : null}</td>
                <td>{invDispute && invDispute.container1 ? invDispute.container1.toUpperCase() : null}</td>
              </tr>
            </tbody>
          </table>
        </Grid>

        <Grid item sm={12} style={{ padding: 2 }}>
          <table
            style={{
              width: "100%",
              textAlign: "left",
              border: "1px solid black",
              tableLayout: "fixed",
            }}
          >
            <tr>
              <th>Original to A/P date</th>
              <th>Month paid to</th>
              <th>Dispute sent</th>
              <th>Response date</th>
              <th>Rebuttal sent date</th>
              <th>Secondary to A/P date</th>
            </tr>
            <tbody>
              <tr>
                <td>{primaryAPDate ? primaryAPDate.format("L") : null}</td>
                <td></td>
                <td>
                  {disputes && disputes.length > 0 && disputes[0].disp_dt
                    ? disputes[0].disp_dt.format("L")
                    : null}
                </td>
                <td>
                  {disputes && disputes.length > 0 && disputes[0].response_dt
                    ? disputes[0].response_dt.format("L")
                    : null}
                </td>
                <td>
                  {disputes && disputes.length > 0 && disputes[0].rebuttal_dt
                    ? disputes[0].rebuttal_dt.format("L")
                    : null}
                </td>
                <td>{secondaryAPDate ? secondaryAPDate.format("L") : null}</td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item sm={12} style={{ padding: 2 }}>
          <table
            style={{
              width: "100%",
              textAlign: "left",
              border: "1px solid black",
              tableLayout: "fixed",
            }}
          >
            <tr>
              <th>Reason for Dispute</th>
              <th>SSL</th>
              <th>BOL/Booking #</th>
              <th>Billed Invoice</th>
              <th>Billed date out</th>
              <th>Billed date in</th>
              <th>Billed invoice date</th>
            </tr>
            <tbody>
              <tr>
                <td>{invDispute && invDispute.dispute_reason ? invDispute.dispute_reason.toUpperCase() : null}</td>
                <td>{invDispute && invDispute.invoice_ssl ? invDispute.invoice_ssl.toUpperCase() : null}</td>
                <td></td>
                <td>{invDispute && invDispute.invoice_num ? invDispute.invoice_num.toUpperCase() : null}</td>
                <td>
                  {invDispute && invDispute.dt_out
                    ? moment.utc(invDispute.dt_out.replace(" GMT", "")).format("L")
                    : null}
                </td>
                <td>
                  {invDispute && invDispute.dt_in
                    ? moment.utc(invDispute.dt_in).format("L")
                    : null}
                </td>
                <td>
                  {invDispute && invDispute.invoice_dt
                    ? moment.utc(invDispute.invoice_dt).format("L")
                    : null}
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>

        <Grid item sm={12}>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 10, float: "right" }}
            onClick={handleSave}
          >
            Save & Close
          </Button>

          <Button
            onClick={() => {
              props.OnClose();
            }}
            variant="outlined"
            color="primary"
            style={{ margin: 10, float: "right" }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
