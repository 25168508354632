import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment, { Moment } from "moment";
import React from "react";
import { addAPSummary, getTerminalsForInvoice } from "../../../api";
import { formatCurrency } from "../../../formatters";
import { Order, getComparator, stableSort } from "../../../lib/sort";
import TableToExcel from "../../TableToExcel/TableToExcel";
import WaitPopup from "../../WaitPopup";
import { useStyles } from "./styles";
import { DataApSums, HeadCell, HeaderDataApSums } from "./types";

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DataApSums) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: HeadCell[];
}

function SortableTableHeaders(props: EnhancedTableProps) {
  const { classes, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property: keyof DataApSums) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
              padding={"checkbox"}
              style={{
                backgroundColor: "rgba(4, 101, 170, 1)",
                color: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                hideSortIcon={true}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

function checkManifestContainsTerm(manifestList, terminalCode) {
  let foundOne = false;
  manifestList.forEach((manifest) => {
    if (manifest.site && manifest.site.toUpperCase() == terminalCode.toUpperCase()) {
      foundOne = true;
    }
  });
  return foundOne;
}

function formatData(terminalList: any[], data: any[]) {
  let formatedData: DataApSums[] = [];

  if (terminalList && terminalList.length > 0) {
    let invRecsCounted = [];
    terminalList.forEach((term) => {
      let sumApproved: number = 0;
      let sumRebill: number = 0;
      let termCode = "";
      if (data && data.length > 0) {
        data.forEach((rec) => {
          if (
            rec.matches &&
            rec.matches.length > 0 &&
            checkManifestContainsTerm(rec.matches, term.terminal_code) &&
            !invRecsCounted.includes(rec.id_)
          ) {
            if (rec.record_status == 3) {
              //Record status: 3 = DISPUTED
              if (rec.disputes_transactions && rec.disputes_transactions.length > 0) {
                rec.disputes_transactions.sort((a, b) => {
                  return b.id_ - a.id_;
                });
                if (rec.disputes_transactions[0].approved_amt) {
                  sumApproved += parseFloat(rec.disputes_transactions[0].approved_amt);
                }
                if (rec.disputes_transactions[0].second_apprv_amt) {
                  sumApproved += parseFloat(rec.disputes_transactions[0].second_apprv_amt);
                }
                invRecsCounted.push(rec.id_);
              }
            } else {
              if (rec.total_billed) {
                sumApproved += parseFloat(rec.total_billed);
              } else if (rec.actual_billed) {
                sumApproved += parseFloat(rec.actual_billed);
              } else {
                sumApproved += 0;
              }
              invRecsCounted.push(rec.id_);
            }
            rec.matches.forEach((manifest) => {
              if (manifest.ar_chuse) {
                sumRebill += parseFloat(manifest.ar_chuse);
              }
              if (manifest.ar_trx) {
                sumRebill += parseFloat(manifest.ar_trx);
              }
            });
          }
        });
      }
      if (term.terminal_alias) {
        termCode = term.terminal_alias;
      }

      formatedData.push({
        id_: term.id_,
        terminal_abbrv: term.terminal_code,
        sum_of_approval_amt: sumApproved + "",
        sum_of_rebill_amt: sumRebill + "",
        terminal_alias_code: termCode,
      });
    });
  }
  return formatedData;
}

const months = [
  { name: " January", value: "January" },
  { name: " February", value: "February" },
  { name: " March", value: "March" },
  { name: " April", value: "April" },
  { name: " May", value: "May" },
  { name: " June", value: "June" },
  { name: " July", value: "July" },
  { name: " August", value: "August" },
  { name: " September", value: "September" },
  { name: " October", value: "October" },
  { name: " November", value: "November" },
  { name: " December", value: "December" },
];

export default function ({ invoiceID, invoiceData }: { invoiceID: number; invoiceData: any }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("name");
  const [selectedItem, setSelected] = React.useState<number>();
  const [invoiceTerminals, setInvoiceTerminals] = React.useState<any | undefined>();
  const [formatedData, setFormatedData] = React.useState<any | undefined>();
  const [openWait, setOpenWait] = React.useState<any | undefined>(true);
  const [payMonth, setPayMonth] = React.useState<Moment | null>(moment());
  const [apSummarySaved, setApSummarySaved] = React.useState<any | undefined>();
  const [disableLockButton, setDisableLockButton] = React.useState<any | undefined>();

  React.useEffect(() => {
    if (invoiceData && invoiceData.length > 0) {
      loadPageInfo();
    }
  }, [invoiceData]);


  React.useEffect(() => {
    if (apSummarySaved) {
      setDisableLockButton(true);
    }
  }, [apSummarySaved]);

  React.useEffect(() => {
    if (invoiceTerminals && invoiceTerminals.length > 0) {
      setFormatedData(formatData(invoiceTerminals, invoiceData));
    }
    setOpenWait(false);
  }, [invoiceTerminals]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof DataApSums) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleLockAP = () => {
    if (formatedData) {
      let dataToSave = formatedData;
      dataToSave.forEach((rec) => {
        if (payMonth) {
          rec.pay_to_month = payMonth;
        }
        rec.iep_invoice_id = invoiceID;
      });
      let data: any = {};
      data.sums = dataToSave;
      data.totalSumApproved = approved_amt;
      data.totalSumRebill = rebill_amt;
      data.pay_to_month = payMonth;
      data.invoice_id = invoiceID;

      setDisableLockButton(true);
      addAPSummary(invoiceID, data, setApSummarySaved);
    }
  };

  const handlePayMonth = (dt: Moment | null) => {
    setPayMonth(dt);
  };

  const loadPageInfo = () => {
    getTerminalsForInvoice(invoiceData[0].iep_invoice_id, setInvoiceTerminals);
  };

  let approved_amt: number = 0;
  let rebill_amt: number = 0;

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>

      <Box style={{ height: "auto", width: "100%", padding: 0 }}>
        <TableToExcel
          id="tte_apsummary"
          sheet="Worksheet"
          table="apsummary"
          fileName="apsummary"
          buttonText=""
          className=""
        />
      </Box>
      <TableContainer style={{ width: "100%" }}>
        <Table id="apsummary" stickyHeader={true} size={"small"}>
          <SortableTableHeaders
            classes={classes}
            numSelected={1}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={0}
            headCells={HeaderDataApSums}
          />
          <TableBody>
            {formatedData &&
              stableSort(formatedData, getComparator(order, orderBy)).map((row, index) => {
                if (row.sum_of_rebill_amt) {
                  rebill_amt += parseFloat(row.sum_of_rebill_amt);
                }
                if (row.sum_of_approval_amt) {
                  approved_amt += parseFloat(row.sum_of_approval_amt);
                }
                return (
                  <TableRow key={index} hover tabIndex={-1} selected={selectedItem === index}>
                    <TableCell>
                      {row.terminal_abbrv ? row.terminal_abbrv.toUpperCase() : ""}
                    </TableCell>
                    <TableCell>{formatCurrency(parseFloat(row.sum_of_approval_amt))}</TableCell>
                    <TableCell>{formatCurrency(parseFloat(row.sum_of_rebill_amt))}</TableCell>
                    <TableCell>
                      {row.terminal_alias_code ? row.terminal_alias_code.toUpperCase() : ""}
                    </TableCell>
                  </TableRow>
                );
              })}
            <TableRow>
              <TableCell>Totals</TableCell>
              <TableCell>{formatCurrency(approved_amt)}</TableCell>
              <TableCell>{formatCurrency(rebill_amt)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Pay to month:</TableCell>
              <TableCell>
                {payMonth && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="schedule-start"
                      inputVariant="standard"
                      format="MMM yyyy"
                      value={payMonth}
                      onChange={handlePayMonth}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}
              </TableCell>
              <TableCell>
                <Button
                  style={{ margin: 5 }}
                  onClick={handleLockAP}
                  disabled={disableLockButton}
                  variant="outlined"
                  color="primary"
                >
                  {disableLockButton ? "SAVED" : "LOCK AP"}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
