import {
  List,
  ListItem, ListItemIcon,
  ListItemSecondaryAction, ListItemText
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import RootRef from "@material-ui/core/RootRef";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import DragHandle from "@material-ui/icons/DragHandle";
import clonedeep from "lodash/cloneDeep";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";
import { getReportColumns } from "../api";
import ReportColumnsPreview from "../components/CustomTable/reportColumnsPreviewTable/ReportColumnsPreview";
import Layout from "../components/layout/Layout";
import { ReportData } from "../types/ReportData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(16),
      },
    },
  })
);

interface Props {
  style: any;
  selectedColumns: any;
}

export default function (props: any) {
  const history = useHistory();
  const [reportData, setReportData] = React.useState<ReportData | undefined>(props.location.state);
  const [reportID, setReportID] = React.useState<number | undefined>();
  const [isNext, setNext] = React.useState<boolean | undefined>(false);
  const [isBack, setBack] = React.useState<boolean | undefined>(false);
  const [ready, setReady] = React.useState<boolean>(false);
  const [columns, setColumns] = React.useState<any>();
  const [activeColumns, setActiveColumns] = React.useState<any>([]);

  React.useEffect(() => {
    setReportData(props.location.state);
    prepareColumns();
  }, []);

  React.useEffect(() => {
    if (reportData.reportID) {
      setReportID(reportData.reportID);
    }
  }, [reportData]);

  React.useEffect(() => {
    if (isNext) {
      if (reportData) {
        history.push("/reportfilters", reportData);
      }
    }
  }, [isNext]);

  React.useEffect(() => {
    if (isBack) {
      if (reportID) {
        history.push("/reportdetails", { reportID: reportID });
      } else if (reportData) {
        history.push("/reportdetails", reportData);
      }
    }
  }, [isBack]);

  React.useEffect(() => {
    let tempActiveColumns = [];
    if (columns) {
      columns.forEach((element) => {
        if (element.active) {
          tempActiveColumns.push(element);
        }
      });
    }
    tempActiveColumns.sort((a: any, b: any) => {
      return a.sequence - b.sequence;
    });

    setActiveColumns(tempActiveColumns);
  }, [columns]);

  React.useEffect(() => {
    if (activeColumns.length > 0) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [activeColumns]);

  const reorder = (list, startIndex, endIndex) => {
    const result = clonedeep(list);

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    for (var i in result) {
      result[i].sequence = Number(i);
    }

    setColumns(result);

    return result;
  };

  const prepareColumns = () => {
    getReportColumns( (data: any) => {
      let columnObjects = [];
      let tempActiveColumns = [];
      let tempColumns = [];
      if (data.length > 0) {
        data.forEach((item, index) => {
          let greatestActiveSeq = 0;

          if (reportData.activeColumns) {
            tempActiveColumns = clonedeep(reportData.activeColumns);
            let col = tempActiveColumns.find((ac) => ac.report_column_id === item.id_);
            if (col) {
              let currentSeq = Number(col.sequence);
              let columnToAdd = {
                sequence: Number(col.sequence),
                name: item.column_name,
                label: item.label,
                active: true,
                report_column_id: col.report_column_id,
              };
              if (currentSeq > greatestActiveSeq) {
                greatestActiveSeq = currentSeq;
              }
              columnObjects.push(columnToAdd);
            } else {
              let columnToAdd = {
                sequence: index,
                name: item.column_name,
                label: item.label,
                active: false,
                report_column_id: item.id_,
              };
              columnObjects.push(columnToAdd);
            }
          } else {
            columnObjects.push({
              sequence: index,
              name: item.column_name,
              label: item.label,
              active: false,
              report_column_id: item.id_,
            });
          }
        });
        tempColumns = clonedeep(columnObjects);
        tempColumns.sort((a, b) => {
          return a.sequence - b.sequence;
        });
      }
      setColumns(tempColumns);
    });
  };

  const toggleActive = (index: number) => {
    let tempColumns = clonedeep(columns);
    tempColumns[index].active = !tempColumns[index].active;
    setColumns(tempColumns);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    reorder(columns, result.source.index, result.destination.index);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,

    ...(isDragging && {
      background: "rgb(235,235,235)",
    }),
  });

  const getDivStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "transparent" : "white",
    marginBottom: 10,
    marginTop: 10,
  });

  const GreenSwitch = withStyles({
    switchBase: {
      color: "white",
      "&$checked": {
        color: "white",
      },
      "&$checked + $track": {
        backgroundColor: "#C5EAC4",
        opacity: 1,
      },
    },
    checked: {},
    track: {},
    thumb: {
      border: "1px solid #C7C7C7",
      width: 16,
      height: 17,
      boxShadow: "none",
    },
  })(Switch);

  function handleNext() {
    setReportData({
      allColumns: columns,
      existingReport: reportData.existingReport,
      reportName: reportData.reportName,
      isScheduled: reportData.isScheduled,
      isWeekly: reportData.isWeekly,
      category: reportData.category,
      scheduledTime: reportData.scheduledTime,
      scheduledStartDate: reportData.scheduledStartDate,
      weekdays: reportData.weekdays,
      frequency: reportData.frequency,
      activeColumns: activeColumns,
      filters: reportData.filters,
      recipients: reportData.recipients,
      username: "",
      reportID: reportData.reportID,
    });
    setNext(true);
  }

  function handleBack() {
    setReportData({
      allColumns: columns,
      existingReport: reportData.existingReport,
      reportName: reportData.reportName,
      isScheduled: reportData.isScheduled,
      isWeekly: reportData.isWeekly,
      category: reportData.category,
      scheduledTime: reportData.scheduledTime,
      scheduledStartDate: reportData.scheduledStartDate,
      weekdays: reportData.weekdays,
      frequency: reportData.frequency,
      activeColumns: activeColumns,
      filters: reportData.filters,
      recipients: reportData.recipients,
      username: "",
      reportID: reportData.reportID,
    });
    setBack(true);
  }

  return (
    <Layout>
      <Box>
        <Paper style={{ backgroundColor: "transparent" }}>
          <Grid container style={{ backgroundColor: "transparent", padding: 5 }}>
            <Grid item sm={6}>
              <Grid style={{ maxHeight: 700, overflow: "scroll" }} item sm={12}>
                <Typography>COLUMNS</Typography>
                <hr></hr>
                <DragDropContext style={{ paddingTop: 5 }} onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <RootRef rootRef={provided.innerRef}>
                        <List style={{ background: "transparent" }}>
                          {columns &&
                            columns.map((item, index) => (
                              <Draggable
                                key={item.name + item.report_column_id.toString()}
                                draggableId={item.name + item.report_column_id.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div style={getDivStyle(snapshot.isDraggingOver)}>
                                    <ListItem
                                      ContainerComponent="li"
                                      ContainerProps={{ ref: provided.innerRef }}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                      divider={true}
                                    >
                                      <ListItemText primary={item.label} />
                                      <GreenSwitch
                                        checked={item.active}
                                        onClick={() => toggleActive(index)}
                                        name={item.name}
                                        color="default"
                                      />
                                      <ListItemIcon>
                                        <DragHandle />
                                      </ListItemIcon>
                                      <ListItemSecondaryAction></ListItemSecondaryAction>
                                    </ListItem>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </List>
                      </RootRef>
                    )}
                  </Droppable>
                </DragDropContext>
              </Grid>
              <Grid container direction="row" alignItems="center">
                <Grid item sm={3}>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ margin: 10, float: "left", width: "80%" }}
                    onClick={(e) => {
                      handleBack();
                    }}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <hr color="#909090" />
                </Grid>
                <Grid item sm={3}>
                  <Button
                    disabled={!ready}
                    variant="outlined"
                    color="primary"
                    style={{ margin: 10, float: "right", width: "80%" }}
                    onClick={(e) => {
                      handleNext();
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} style={{ maxHeight: 750, overflow: "hidden" }}>
              {reportData && (
                <ReportColumnsPreview
                  columns={activeColumns}
                  reportName={reportData.reportName}
                  category={reportData.category}
                  isScheduled={reportData.isScheduled}
                  isWeekly={reportData.isWeekly}
                  scheduledTime={reportData.scheduledTime}
                  scheduledStartDate={reportData.scheduledStartDate}
                  weekdays={reportData.weekdays}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Layout>
  );
}
