
import DateFnsUtils from "@date-io/date-fns";
import {
  Box, Button, Dialog, DialogActions, Grid, TextField, Typography
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Moment } from "moment";
import * as React from "react";

const moment = require('moment');
interface FilterProps {
  open: boolean;
  onClose: Function;
  setFilteredRecords: Function;
  unfilteredRecords: any[];
}

export default function FilterDialog(props: FilterProps) {
  const [dateIn, setDateIn] = React.useState<Moment | null>(null);
  const [dateOut, setDateOut] = React.useState<Moment | null>(null);
  const [containerNum, setContainerNum] = React.useState("");
  const [chassisNum, setChassisNum] = React.useState("");
  const [manifestNum, setManifestNum] = React.useState("");
  const [billTo, setBillTo] = React.useState("");

  function handleClose() {
    props.onClose(false);
  }

  function applyFilters() {
    let filtered: any[] = Array.from(props.unfilteredRecords);
    if (containerNum) {
      filtered = filtered.filter((item) => { return item.containerNum.toUpperCase().includes(containerNum.toUpperCase()) });
    }

    if (chassisNum) {
      filtered = filtered.filter((item) => { return item.chassisNum.toUpperCase().includes(chassisNum.toUpperCase()) });
    }

    if (manifestNum) {
      filtered = filtered.filter((item) => { return item.manifestNum.toUpperCase().includes(manifestNum.toUpperCase()) });
    }

    if (dateIn) {
      filtered = filtered.filter((item) => { return item.dt_in.diff(dateIn,'days') === 0 });
    }

    if (dateOut) {
      filtered = filtered.filter((item) => { return item.dt_out.diff(dateOut,'days') === 0 });
    }

    if (billTo) {
      filtered = filtered.filter((item) => { return item.customer_name.toUpperCase().includes(billTo.toUpperCase()) });
    }

    props.onClose(false);
    props.setFilteredRecords(filtered);
  }

  function handleDateIn(date: Moment | null) {
    date = moment.utc(date.toLocaleString())
    date.hour(0)
    date.second(0)
    date.millisecond(0)
    console.log(date);
    setDateIn(date);
  }

  function handleDateOut(date: Moment | null) {
    date = moment.utc(date.toLocaleString())
    date.hours(0)
    date.seconds(0)
    date.millisecond(0)
    setDateOut(date);
  }

  return (
    <Dialog open={props.open} onClose={handleClose} fullWidth>
      <Box margin={2} padding={1}>
        <Typography variant="h2" color="primary">
          Filters
        </Typography>
        <hr />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Date In"
                value={dateIn}
                onChange={handleDateIn}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Date Out"
                value={dateOut}
                onChange={handleDateOut}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </Grid>
          <Grid container justify="space-around"></Grid>
        </MuiPickersUtilsProvider>
        <Box margin={1} padding={1}>
          <TextField
            onChange={(e) => {
              setContainerNum(e.target.value);
            }}
            label="Container Number"
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box margin={1} padding={1}>
          <TextField
            onChange={(e) => {
              setChassisNum(e.target.value);
            }}
            label="Chassis Number"
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box margin={1} padding={1}>
          <TextField
            onChange={(e) => {
              setManifestNum(e.target.value);
            }}
            label="Manifest Number"
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box margin={1} padding={1}>
          <TextField
            onChange={(e) => {
              setBillTo(e.target.value);
            }}
            label="Bill To"
            variant="outlined"
            fullWidth
          />
        </Box>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            cancel
          </Button>
          <Button onClick={applyFilters} variant="contained" color="primary" autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
