import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { useHistory } from "react-router-dom";
import { getSettings, updateSettings } from "../api";
import Layout from "../components/layout/Layout";
import { formatPascalToString } from "../formatters";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 900,
      maxHeight: 700,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

export default function (props: any) {
  const classes = useStyles();
  const [settings, setSettings] = React.useState<any | undefined>();
  const [updatedSettings, setUpdatedSettings] = React.useState<any | undefined>();
  const history = useHistory();

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  React.useEffect(() => {
    if (updatedSettings) {
      history.push("/dashboard");
    }
  }, [updatedSettings]);

  function loadPageInfo() {
    getSettings( setSettings);
  }

  function handleValueChange(event: any, id: any) {
    if (settings) {
      let newValues: any = [];
      settings.forEach((setting) => {
        let newSetting: any = setting;
        if (id == setting.id_) {
          newSetting.value = event.target.value;
        }
        newValues.push(newSetting);
      });
      setSettings(newValues);
    }
  }

  function handleSaveSettings() {
    let settingsObj: any = {};
    settingsObj.settings = settings;
    updateSettings(settingsObj, setUpdatedSettings);
  }


  return (
    <Layout>
      <Paper style={{ margin: 5 }}>
        <Box style={{ padding: 30 }}>
          <Grid container style={{ overflow: "hidden", width: "100%" }}>
            <Grid item sm={12}>
              <Grid container>
                <Grid item sm={12}>
                  <Box>
                    <h3>SETTINGS</h3>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Grid container>
                {settings &&
                  settings.map((setting) => {
                    return (
                      <Grid item sm={12}>
                        <Box style={{ margin: "2%" }}>
                          <TextField
                            id={setting.id_}
                            value={setting.value}
                            onChange={(event) => {
                              handleValueChange(event, setting.id_);
                            }}
                            label={formatPascalToString(setting.name)}
                            variant="outlined"
                          />
                        </Box>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Box style={{ float: "right" }}>
                <Button
                  style={{ margin: 5 }}
                  onClick={handleSaveSettings}
                  variant="outlined"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
}
