import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import { deleteCustomerRule, getCustomerRules } from "../../../api";
import { formatDateMMDDYYYY } from "../../../formatters";
import { getComparator, Order, stableSort } from "../../../lib/sort";
import TableToExcel from "../../TableToExcel/TableToExcel";
import UpdateCustomerRule from "../../UpdateCustomerRule";
import WaitPopup from "../../WaitPopup";
import { useStyles } from "./styles";
import { DataCustomerRulesManager, HeadCell, HeaderDataCustomerRulesManager } from "./types";

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DataCustomerRulesManager
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: HeadCell[];
}

function SortableTableHeaders(props: EnhancedTableProps) {
  const { classes, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property: keyof DataCustomerRulesManager) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{
            padding: 0,
            backgroundColor: "rgba(4, 101, 170, 1)",
            color: "white",
          }}
        />
        <TableCell
          style={{
            padding: 0,
            backgroundColor: "rgba(4, 101, 170, 1)",
            color: "white",
          }}
        />
        {headCells.map((headCell) => {
          let col_width = headCell.id == "ecm_comments" ? 200 : "auto";

          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
              padding={"checkbox"}
              style={{
                backgroundColor: "rgba(4, 101, 170, 1)",
                color: "white",
              }}
            >
              <TableSortLabel
                style={{ width: col_width }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                hideSortIcon={true}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
        <TableCell
          style={{
            width: 10,
            padding: 0,
            margin: 0,
            backgroundColor: "rgba(4, 101, 170, 1)",
            color: "white",
          }}
        />
      </TableRow>
    </TableHead>
  );
}

function rand() {
  return Math.round(Math.random() * 15) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function (props: any) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("name");
  const [selectedItem, setSelected] = React.useState<number>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(200);
  const [editID, setEditID] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [customerRules, setCustomerRules] = React.useState<any | undefined>();
  const [modalStyle] = React.useState(getModalStyle);
  const [newState, setNewState] = React.useState(false);
  const [openWait, setOpenWait] = React.useState(true);

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  React.useEffect(() => {
    if (customerRules) {
      setOpenWait(false);
    }
  }, [customerRules]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DataCustomerRulesManager
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
    let newSelected: number = -1;
    newSelected = name;
    setSelected(newSelected);
  };

  const loadPageInfo = () => {
    setOpenWait(true);
    getCustomerRules(setCustomerRules);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    loadPageInfo();
    setOpen(false);
  };

  const handleEditClick = (event: any, id: any) => {
    setNewState(false);
    setEditID(id);
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNewClick = (event: any) => {
    setNewState(true);
    setOpen(true);
  };

  const handleDeleteClick = (event: any, id: number) => {
    setOpenWait(true);
    deleteCustomerRule(id, loadPageInfo);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <UpdateCustomerRule id={editID} handleClose={handleClose} newState={newState} />
        </div>
      </Modal>
      <Box style={{ height: "auto", width: "100%", padding: 0 }}>
        <TableToExcel
          id="tte_customerRules"
          sheet="Worksheet"
          table="customerRulesManager"
          fileName="customerRules"
          buttonText=""
          className=""
        />
        <IconButton
          onClick={(event) => handleNewClick(event)}
          style={{ float: "right", padding: 3 }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <TableContainer style={{ width: "100%" }}>
        <Table id="customerRulesManager" stickyHeader={true} size={"small"}>
          <SortableTableHeaders
            classes={classes}
            numSelected={1}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={0}
            headCells={HeaderDataCustomerRulesManager}
          />
          <TableBody>
            {customerRules &&
              stableSort(customerRules, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      hover
                      onClick={(event) => handleClick(event, index)}
                      tabIndex={-1}
                      selected={selectedItem === index}
                    >
                      <TableCell style={{ padding: 0 }}>
                        <IconButton onClick={(event) => handleEditClick(event, row.id_)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell style={{ padding: 0 }}>
                        <IconButton onClick={(event) => handleDeleteClick(event, row.id_)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>{row.terminal_code}</TableCell>
                      <TableCell>{row.customer_bill_to_name}</TableCell>
                      <TableCell>{row.ssl_code}</TableCell>
                      <TableCell>{row.pool_code}</TableCell>
                      <TableCell>{row.billing_party}</TableCell>
                      <TableCell>{row.consignee}</TableCell>
                      <TableCell>{row.import_export}</TableCell>
                      <TableCell>{row.delivery_location}</TableCell>
                      <TableCell>
                        {row.end_date
                          ? formatDateMMDDYYYY(new Date(row.end_date.replace(" GMT", "")))
                          : ""}
                      </TableCell>
                      <TableCell>{row.for_terminal_nam}</TableCell>
                      <TableCell>{row.contract_num}</TableCell>
                      <TableCell>{row.ssl_contact_info}</TableCell>
                      <TableCell style={{ width: 200 }}>{row.ecm_comments}</TableCell>
                      <TableCell>{row.canned_response_reason}</TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15]}
        component="div"
        count={customerRules ? customerRules.length : []}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
