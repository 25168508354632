import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PublishIcon from "@material-ui/icons/Publish";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(3)
    },
    wrapper: {
      width: "100%",
      height: "100%"
    },
    inputWrapper: {
      position: "absolute",
      overflow: "hidden",
      display: "inline",
      width: "100%",
      height: "100%",
      zIndex: 1,
      opacity: 0,
      left: 0,
      top: 0
    }
  })
);

interface Props {
  style?: any;
  setFile: Function;
}

export default function (props: Props) {
  const classes = useStyles();

  const [fileValue, setFileValue] = React.useState<any>();

  const handleFileValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files != null) {
      setFileValue(event.target.files[0]);
      props.setFile(event.target.files[0]);
    }
  };

  return (
    <Box style={props.style}>
      <Grid container>
        <Grid item sm={12}>
          <FormControl style={{ width: "100%" }}>
            <Grid container>
              <Grid
                item
                sm={9}
                style={{
                  border: "solid",
                  borderWidth: 1,
                  borderColor: "rgba(178, 178, 178, 1)",
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5
                }}
              >
                <input
                  disabled
                  type="text"
                  value={fileValue ? fileValue.name : ""}
                  style={{
                    height: "95%",
                    width: "100%",
                    border: "none",
                    fontSize: 16
                  }}
                />
              </Grid>
              <Grid
                item
                sm={3}
                style={{
                  border: "solid",
                  borderWidth: 1,
                  borderColor: "rgba(178, 178, 178, 1)",
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5
                }}
              >
                <div className={classes.wrapper}>
                  <IconButton style={{ height: "100%", width: "100%" }}>
                    <PublishIcon />
                  </IconButton>
                  <input
                    type="file"
                    className={classes.inputWrapper}
                    onChange={handleFileValueChange}
                  />
                </div>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
