import { Store } from "./Store";

interface State {
  type: string;
  message: string;
  autoHideDuration: number;
  show: boolean;
}

class ToastStore extends Store<State> {
  public showToast(type: string, message: string, autoHideDuration?: number) {
    this.setState({
      type,
      message,
      autoHideDuration: autoHideDuration ? autoHideDuration : 5000,
      show: true
    });
  }
}

export const toastStore = new ToastStore({
  type: "basic",
  message: "Test Message",
  autoHideDuration: 5000,
  show: false
});
