import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
const logo = require("../img/CPG_WheelzType_Final.png");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      overflowX: "hidden",
      overflowY: "auto",
      backgroundSize: "cover",
      background:
        "url(" +
        require("../img/image001.png") +
        ") no-repeat center",
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10) // so there is scroll space under
    },
    layover: {
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      overflowX: "hidden",
      overflowY: "auto",
      backgroundSize: "cover",
      background: "rgba(54,139,204,0.50)",
      height: "100%",
      width: "100%",
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10)
    },
    paper: {
      background: "rgba(255,255,255,0.93)"
    },
    logo: {
      background: "rgba(100,255,100,0.93)"
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
      width: "auto",
      height: "auto"
    }
  })
);

interface Props {
  title: string;
}

export default function AuthLayout(props: React.PropsWithChildren<Props>) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div id="layover" className={classes.layover}>
        <Container maxWidth="sm">
          <Paper classes={{ root: classes.paper }}>
            <Box>
              <Box
                style={{
                  display: "flex",
                  height: 130,
                  background: "rgba(0,55,110)"
                }}
              >
                <img
                  className={classes.center}
                  src={logo}
                  alt="logo"
                  style={{
                    maxWidth: "40%"
                  }}
                />
              </Box>
              <Box
                paddingTop={5}
                color="primary.main"
                textAlign="center"
                fontFamily="lato Regular"
                fontSize="h4.fontSize"
              >
                {props.title}
              </Box>
              <Box paddingX={3} paddingY={3}>
                {props.children}
              </Box>
            </Box>
          </Paper>
        </Container>
      </div>
    </div>
  );
}
