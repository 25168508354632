import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React from "react";
import { formatCurrency } from "../../../formatters";
import { getComparator, Order, stableSort } from "../../../lib/sort";
import TableToExcel from "../../TableToExcel/TableToExcel";
import { useStyles } from "./styles";
import { DataApHistoryDetails, HeadCell, HeaderDataApSums } from "./types";

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DataApHistoryDetails) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: HeadCell[];
}

function SortableTableHeaders(props: EnhancedTableProps) {
  const { classes, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
              padding={"checkbox"}
              style={{
                backgroundColor: "rgba(4, 101, 170, 1)",
                color: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                hideSortIcon={true}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

function rand() {
  return Math.round(Math.random() * 10) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50;

  return {
    top: `${10}%`,
    left: `${3}%`,
    overflow: "auto",
  };
}

export default function (props: any) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("name");
  const [selectedItem, setSelected] = React.useState<number>();

  React.useEffect(() => {
    if (props.invoiceData && props.invoiceData.length > 0) {
      loadPageInfo();
    }
  }, [props.invoiceData]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DataApHistoryDetails
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const loadPageInfo = () => {};
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Box style={{ height: "auto", width: "100%", padding: 0 }}>
        <TableToExcel
          id="tte_apsummary"
          sheet="Worksheet"
          table="aphistorydetails"
          fileName="apHistoryDetails"
          buttonText=""
          className=""
        />
      </Box>
      {props.invoiceData && props.invoiceData.length > 0 ? (
        <TableContainer style={{ width: "100%" }}>
          <Table id="aphistorydetails" stickyHeader={true} size={"small"}>
            <SortableTableHeaders
              classes={classes}
              numSelected={1}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={0}
              headCells={HeaderDataApSums}
            />
            <TableBody>
              {props.invoiceData &&
                stableSort(props.invoiceData, getComparator(order, orderBy)).map((row, index) => {
                  return (
                    <TableRow key={index} hover tabIndex={-1} selected={selectedItem === index}>
                      <TableCell>{row.id_}</TableCell>
                      <TableCell>{row.chassis}</TableCell>
                      <TableCell>{row.container1}</TableCell>
                      <TableCell>{formatCurrency(parseFloat(row.total_billed))}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box style={{ marginTop: 20 }}>
          <div>No Records available</div>
        </Box>
      )}
    </div>
  );
}
