import DateFnsUtils from "@date-io/date-fns";
import { IconButton, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import clonedeep from "lodash/cloneDeep";
import React from "react";
import { useHistory } from "react-router-dom";
import { getReportColumns, getReportOperators } from "../api";
import ReportColumnsPreview from "../components/CustomTable/reportColumnsPreviewTable/ReportColumnsPreview";
import Layout from "../components/layout/Layout";
import { formatDateMMDDYYYY } from "../formatters";
import Filter from "../types/filter";
import { ReportData } from "../types/ReportData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(16),
      },
    },
  })
);

interface Props {
  style: any;
  selectedColumns: any;
}

export default function (props: any) {
  const [ready, setReady] = React.useState<boolean>(false);
  const [reportData, setReportData] = React.useState<ReportData | undefined>(props.location.state);
  const [isNext, setNext] = React.useState<boolean | undefined>(false);
  const [isBack, setBack] = React.useState<boolean | undefined>(false);
  const history = useHistory();
  const [columns, setColumns] = React.useState<any>([
    { sequence: 0, name: "column1", active: false },
  ]);
  const [activeColumns, setActiveColumns] = React.useState<any>([]);
  const [reportOperators, setReportOperators] = React.useState<any>([]);
  const [selectedValue, setSelectedValue] = React.useState<null | any>("");

  const [selectedOperator, setSelectedOperator] = React.useState<null | any>("");
  const [selectedColumn, setSelectedColumn] = React.useState<null | string>("");
  const [filters, setFilters] = React.useState<null | any>([]);

  React.useEffect(() => {
    prepareColumns();
    prepareOperators();
    loadPageInfo();
  }, []);

  React.useEffect(() => {
    if (isNext) {
      if (reportData) {
        history.push("/reportusers", reportData);
      }
    }
  }, [isNext]);

  React.useEffect(() => {
    if (isBack) {
      if (reportData) {
        history.push("/reportcolumns", reportData);
      }
    }
  }, [isBack]);

  React.useEffect(() => {
    let tempColumns = [];
    for (var i in columns) {
      if (columns[i].active) {
        tempColumns.push(columns[i]);
      }
    }
    setActiveColumns(tempColumns);
  }, [columns]);

  React.useEffect(() => {
    if (activeColumns.length > 0 || (filters && filters.length > 0)) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [filters]);

  React.useEffect(() => {
    if (
      selectedColumn &&
      (selectedColumn.includes("Date", 0) || selectedColumn.includes("dt", 0))
    ) {
      setSelectedValue(formatDateMMDDYYYY(new Date()).toString());
    } else {
      setSelectedValue(" ");
    }
  }, [selectedColumn]);

  const loadPageInfo = () => {
    let tempFilters = [];
    if (props.location.state.filters && props.location.state.filters.length > 0) {
      props.location.state.filters.forEach((item) => {
        if (item.columnName) {
          item.column_name = item.columnName;
        }
        tempFilters.push({
          id: item.id_,
          column_id: item.column_id,
          columnName: item.column_name,
          operator: item.operator,
          value: item.value,
        });
      });
      setFilters(tempFilters);
      setReady(true);
    }
  };

  const prepareColumns = () => {
    if (reportData.allColumns && reportData.allColumns.length > 0) {
      let columnObjects = reportData.allColumns;
      setColumns(columnObjects);
    } else {
      getReportColumns(setColumns);
    }
  };

  const prepareOperators = () => {
    let operators = getReportOperators();
    setReportOperators(operators);
  };

  function handleOperatorChange(event: any) {
    setSelectedOperator(event.target.value);
  }

  function handleColumnChange(event: any) {
    setSelectedColumn(event.target.value);
  }

  function deDuplicateFilters(filtersArray: any) {
    let delimitedStrings = [];
    for (var current of filtersArray) {
      let filterString = (
        current.columnName +
        "-" +
        current.operator +
        "-" +
        current.value.toString()
      ).toLowerCase();
      delimitedStrings.push(filterString);
    }

    const filterSet = new Set(delimitedStrings); //remove dups via convert to Set (uniques only)
    const filterArray = Array.from(filterSet);

    let newFilters = []; //new array to be populated with rebuilt unique filters
    for (var filterString of filterArray) {
      let stringsArr = filterString.split("-", 3);
      let filterObj = new Filter();
      filterObj.columnName = stringsArr[0];
      filterObj.operator = capitalize(stringsArr[1]);
      filterObj.value = stringsArr[2];
      newFilters.push(filterObj);
    }

    return newFilters;
  }

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleSave = () => {
    ``
    var tempFilters = filters;
    let filter = new Filter();
    if (selectedColumn && selectedOperator && selectedValue) {
      filter.columnName = selectedColumn;
      filter.operator = selectedOperator;
      if (selectedOperator && selectedOperator.toUpperCase().includes("PREVIOUS")) {
        filter.value = "";
      }
      else {
        filter.value = selectedValue.trim().toLowerCase();
      }
      tempFilters.push(filter);
      let newFilters = deDuplicateFilters(tempFilters);
      setFilters(newFilters);
    }

    setSelectedValue("");
    setSelectedColumn("");
    setSelectedOperator("");
  };

  const handleDeleteClick = (event: any, index: any) => {
    var tempFilters = clonedeep(filters);
    tempFilters.splice(index, 1);
    setFilters(tempFilters);
  };

  const headerStyle = {
    borderBottom: "1px solid grey",
    fontSize: "11pt",
    borderRight: "1px solid grey",
    backgroundColor: "transparent",
    color: "dark grey",
    margin: 1,
  };

  const bodyStyle = {
    fontSize: "10pt",
    borderRight: "1px solid grey",
    backgroundColor: "transparent",
    color: "dark grey",
    margin: 1,
  };

  function handleNext() {
    setReportData({
      allColumns: reportData.allColumns,
      existingReport: reportData.existingReport,
      reportName: reportData.reportName,
      isScheduled: reportData.isScheduled,
      isWeekly: reportData.isWeekly,
      category: reportData.category,
      scheduledTime: reportData.scheduledTime,
      scheduledStartDate: reportData.scheduledStartDate,
      weekdays: reportData.weekdays,
      frequency: reportData.frequency,
      activeColumns: reportData.activeColumns,
      filters: filters,
      recipients: reportData.recipients,
      username: "",
      reportID: reportData.reportID,
    });
    setNext(true);
  }

  function handleBack() {
    setReportData({
      allColumns: reportData.allColumns,
      existingReport: reportData.existingReport,
      reportName: reportData.reportName,
      isScheduled: reportData.isScheduled,
      isWeekly: reportData.isWeekly,
      category: reportData.category,
      scheduledTime: reportData.scheduledTime,
      scheduledStartDate: reportData.scheduledStartDate,
      weekdays: reportData.weekdays,
      frequency: reportData.frequency,
      activeColumns: reportData.activeColumns,
      filters: filters,
      recipients: reportData.recipients,
      username: "",
      reportID: reportData.reportID,
    });
    setBack(true);
  }

  return (
    <Layout>
      <Box>
        <Paper style={{ backgroundColor: "transparent" }}>
          <Grid container style={{ backgroundColor: "transparent", padding: 5 }}>
            <Grid item sm={6}>
              <Grid style={{ maxHeight: 700, overflow: "scroll" }} item sm={12}>
                <Typography style={{ marginLeft: 16 }}>FILTERS</Typography>
                <hr></hr>
                <Typography style={{ margin: 12, color: "#368BCC" }} variant="body2">
                  Filters
                </Typography>
                <TableContainer>
                  <Table id="" stickyHeader={true} size={"small"}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={headerStyle}>Column Name</TableCell>
                        <TableCell style={headerStyle}>Operator</TableCell>
                        <TableCell style={headerStyle}>Value</TableCell>
                        <TableCell
                          size="small"
                          style={{ backgroundColor: "transparent", borderBottom: "1px solid grey" }}
                        >
                          {""}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filters &&
                        filters.map((filter, index) => {
                          return (
                            <TableRow key={filter.columName + index.toString()}>
                              <TableCell key={"column" + index.toString()} style={bodyStyle}>
                                {filter.columnName}
                              </TableCell>
                              <TableCell key={"operator" + index.toString()} style={bodyStyle}>
                                {filter.operator}
                              </TableCell>
                              <TableCell key={"value" + index.toString()} style={bodyStyle}>
                                {filter.value}
                              </TableCell>
                              <TableCell
                                key={index}
                                size="small"
                                style={{
                                  backgroundColor: "transparent",
                                  width: "8%",
                                }}
                              >
                                <IconButton
                                  size="small"
                                  style={{ margin: 2, padding: 0, color: "#F5542C" }}
                                  onClick={(event) => handleDeleteClick(event, index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography style={{ margin: 16, color: "#368BCC" }} variant="body2">
                  Add New Filter
                </Typography>
                <Grid container style={{ margin: 16 }}>
                  <Grid item sm={4} style={{ margin: 4 }}>
                    <TextField
                      select
                      fullWidth
                      onChange={handleColumnChange}
                      variant="outlined"
                      label="Column Name"
                      InputLabelProps={{ shrink: true }}
                      value={selectedColumn}
                    >
                      {columns &&
                        columns.map((item, index) => {
                          if (columns.length <= 0) {
                            return <div />;
                          }
                          return (
                            <MenuItem key={index} value={item.name}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>
                  <Grid item sm={4} style={{ margin: 4 }}>
                    <TextField
                      select
                      fullWidth
                      onChange={handleOperatorChange}
                      variant="outlined"
                      label="Operator"
                      InputLabelProps={{ shrink: true }}
                      value={selectedOperator}
                    >
                      {reportOperators &&
                        reportOperators.map((item, index) => {
                          if (reportOperators.length <= 0) {
                            return <div />;
                          }
                          return (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>
                  {selectedOperator && selectedOperator.toUpperCase().includes("PREVIOUS") ? (
                    <div />
                  ) : (
                      <Grid item sm={3} style={{ margin: 4 }}>
                        {selectedColumn && selectedColumn.includes("Date", 0) ? (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              inputVariant="outlined"
                              variant="inline"
                              format="MM/dd/yyyy"
                              error={false}
                              InputLabelProps={{ shrink: true }}
                              id="date-picker-inline"
                              label="Value"
                              value={selectedValue}
                              style={{ width: "100%" }}
                              onChange={(event: any) => {
                                setSelectedValue(formatDateMMDDYYYY(event).toString());
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        ) : (
                            <TextField
                              fullWidth
                              label="Value"
                              value={selectedValue}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              onChange={(event: any) => {
                                setSelectedValue(event.target.value);
                              }}
                            />
                          )}
                      </Grid>
                    )}
                  <Grid item sm={12}>
                    <Button
                      style={{ margin: 16, float: "right" }}
                      onClick={handleSave}
                      variant="outlined"
                      color="primary"
                    >
                      ADD
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center">
                <Grid item sm={3}>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ margin: 10, float: "left", width: "80%" }}
                    onClick={(e) => {
                      handleBack();
                    }}

                  >
                    Back
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <hr color="#909090" />
                </Grid>
                <Grid item sm={3}>
                  <Button
                    disabled={!ready}
                    variant="outlined"
                    color="primary"
                    style={{ margin: 10, float: "right", width: "80%" }}
                    onClick={(e) => {
                      handleNext();
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} style={{ maxHeight: 750, overflow: "hidden" }}>
              {activeColumns && props.location.state.reportName && (
                <ReportColumnsPreview
                  columns={reportData.activeColumns}
                  reportName={reportData.reportName}
                  category={reportData.category}
                  isScheduled={reportData.isScheduled}
                  isWeekly={reportData.isWeekly}
                  scheduledTime={reportData.scheduledTime}
                  scheduledStartDate={reportData.scheduledStartDate}
                  weekdays={reportData.weekdays}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Layout>
  );
}
