
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    width: "100%",
    height: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    height: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  check: {
    color: "rgba(65, 150, 68, 1)",
  },
  clear: {
    color: "rgba(245, 84, 44, 1)",
  },
})
);
