export type SortDirection = "asc" | "desc";
export type Comparable = string | number;

export type SortWithFn<T> = (a: T, b: T) => number;
export type SortByFn<T> = (item: T) => Comparable;
export type Order = "asc" | "desc";

var isDate = function (date) {
  var parsedDate = Date.parse(date);

  return isNaN(date) && !isNaN(parsedDate);
};

var convertToDate = function (date) {
  return new Date(date).getTime();
};

var isNumber = function (number) {
  var parsedNumber = parseFloat(number);

  return !isNaN(number) && !isNaN(parsedNumber);
};

var convertToNumber = function (number) {
  return parseFloat(number);
};

export function sortWithBy<T>(by: SortByFn<T>): SortWithFn<T> {
  return (a: T, b: T) => {
    const byA = by(a);
    const byB = by(b);
    if (byA === byB) {
      return 0;
    }
    if (!byA && !!byB) {
      return 1;
    }
    if (!!byA && !byB) {
      return -1;
    }
    if (byA > byB) {
      return -1;
    }
    return 1;
  };
}

export function sortBy<T>(array: T[], by: SortByFn<T>, sortDirection: SortDirection) {
  const stabilizedThis = array.map((el, index) => ({ el, index }));
  stabilizedThis.sort((a, b) => {
    const byA = by(a.el);
    const byB = by(b.el);
    let order = byA > byB ? -1 : byA < byB ? 1 : 0;
    if (sortDirection === "asc") {
      order = -order;
    }
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((el) => el.el);
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  //Check if a date.
  if (isDate(b[orderBy]) && isDate(a[orderBy])) {
    if (convertToDate(b[orderBy]) < convertToDate(a[orderBy])) {
      return -1;
    }

    if (convertToDate(b[orderBy]) > convertToDate(a[orderBy])) {
      return 1;
    }

    return 0;
  }

  //Check if a number.
  if (isNumber(a[orderBy]) && isNumber(b[orderBy])) {
    if (convertToNumber(b[orderBy]) < convertToNumber(a[orderBy])) {
      return -1;
    }

    if (convertToNumber(b[orderBy]) > convertToNumber(a[orderBy])) {
      return 1;
    }

    return 0;
  }

  if (
    orderBy === "manifest_num" &&
    ((b["matches"] && b["matches"].length > 1) || (a["matches"] && a["matches"].length > 1))
  ) {
    if (!a["matches"] || (b["matches"] && b["matches"].length > 1 && a["matches"].length <= 1)) {
      return -1;
    }

    if (!b["matches"] || (a["matches"].length > 1 && b["matches"].length <= 1)) {
      return 1;
    }
  }

  if (!b[orderBy]) {
    return -1;
  }

  if (!a[orderBy]) {
    return 1;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

export function getComparator<Key extends keyof any>(order: Order, orderBy: Key) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  if (array.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) {
        return order;
      }

      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  return array;
}
