import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
 
export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    width: "100%",
    height: "100%",
  },
  paper: {
    position: "absolute",
    width: "80%",
    maxHeight: "85%",
    overflow: "scroll",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
})
);