
import DateFnsUtils from "@date-io/date-fns";
import {
  Box, Button, Dialog, DialogActions, Grid, TextField, Typography
} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as React from "react";
import { getIepList } from "../api";


interface FilterProps {
  open: boolean;
  onClose: Function;
  setFilteredRecords: Function;
  unfilteredRecords: any[];

}

export default function FilterDialog(props: FilterProps) {
  const [containerNum, setContainerNum] = React.useState<string | undefined>("");
  const [chassisNum, setChassisNum] = React.useState<string | undefined>("");
  const [invoiceNum, setInvoiceNum] = React.useState<string | undefined>("");
  const [ssl, setSsl] = React.useState<string | undefined>("");
  const [iepList, setIepList] = React.useState<any | undefined>();
  const [selectedIep, setSelectedIep] = React.useState<any | undefined>();

  React.useEffect(() => {
    getIepList( setIepList);
  }, []);

  function handleClose() {
    props.onClose(false);
  }

  function applyFilters() {
    let filtered: any[] = Array.from(props.unfilteredRecords);
    if (containerNum) {
      filtered = filtered.filter((item) => { return item.container1.toUpperCase().includes(containerNum.toUpperCase()) });
    }
    if (chassisNum) {
      filtered = filtered.filter((item) => { return item.chassis.toUpperCase().includes(chassisNum.toUpperCase()) });
    }
    if (invoiceNum) {
      filtered = filtered.filter((item) => { return item.invoice_num.toUpperCase().includes(invoiceNum.toUpperCase()) });
    }
    if (ssl) {
      filtered = filtered.filter((item) => { return item.invoice_ssl.toUpperCase().includes(ssl.toUpperCase()) });
    }
    if (selectedIep) {
      filtered = filtered.filter((item) => { return item.iep_id == selectedIep });
    }

    props.onClose(false);
    props.setFilteredRecords(filtered);
  }

  function handleIepDropdownChange(event) {

  }


  return (
    <Dialog open={props.open} onClose={handleClose} fullWidth>
      <Box margin={2} padding={1}>
        <Typography variant="h2" color="primary">
          Filters
        </Typography>
        <hr />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

          <Grid container justify="space-around"></Grid>
        </MuiPickersUtilsProvider>
        <Box margin={1} padding={1}>
          <TextField
            onChange={(e) => {
              setInvoiceNum(e.target.value);
            }}
            label="INVOICE #"
            variant="outlined"
            value={invoiceNum}
            fullWidth
          />
        </Box>
        <Box margin={1} padding={1}>
          <TextField
            onChange={(e) => {
              setChassisNum(e.target.value);
            }}
            label="CHASSIS #"
            variant="outlined"
            value={chassisNum}
            fullWidth
          />
        </Box>
        <Box margin={1} padding={1}>
          <TextField
            onChange={(e) => {
              setContainerNum(e.target.value);
            }}
            label="CONTAINER #"
            variant="outlined"
            value={containerNum}
            fullWidth
          />
        </Box>
        <Box margin={1} padding={1}>
          <TextField
            onChange={(e) => {
              setSsl(e.target.value);
            }}
            label="SSL"
            variant="outlined"
            value={ssl}
            fullWidth
          />
        </Box>
        <Box margin={1} padding={1}>
          <TextField
            id="standard-select-currency"
            select
            label="IEP"
            value={selectedIep}
            onChange={(e) => { setSelectedIep(e.target.value); }}
            helperText="Please select your currency"
          >
            {iepList && iepList.map((option) => (
              <MenuItem key={option.id_} value={option.id_}>
                {option.abbrv}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            cancel
          </Button>
          <Button onClick={applyFilters} variant="contained" color="primary" autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
