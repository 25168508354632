import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import CustomerManager from "../components/CustomTable/customerManagerTable/CustomerManager";
import Layout from "../components/layout/Layout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 900,
      maxHeight: 700,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

export default function (props: any) {
  const classes = useStyles();

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  function loadPageInfo() { }


  return (
    <Layout>
      <Paper style={{ margin: 5 }}>
        <Box style={{ padding: 30 }}>
          <Grid container style={{ overflow: "hidden", width: "100%" }}>
            <Grid item sm={12}>
              <Grid container>
                <Grid item sm={12}>
                  <Box>
                    <h3>MANAGE CUSTOMERS</h3>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <CustomerManager />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
}
