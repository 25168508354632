import createMuiTheme, { Theme } from "@material-ui/core/styles/createMuiTheme";

// Material Theme
export const theme = createMuiTheme({
  palette: {
    common: {
      white: "#ffffff",
    },
    primary: {
      main: "#00376E",
      dark: "#368BCC",
      light: "#6EAEDE",
    },
    secondary: {
      main: "#0465AA",
      light: "#6EAEDE",
      dark: "#368BCC",
    },
    error: {
      main: "#E6001F",
      light: "#ffcb3e",
    },
    text: {
      primary: "#707070",
      secondary: "#ffffff",
    },
    background: {
      default: "#f8f8f8",
    },
  },
  typography: {
    fontFamily: "Lato, Arial, sans-serif",

    h1: {
      fontSize: "1.25rem" /*20px */,
      textTransform: "uppercase",
      fontWeight: 700,
    },
    h2: {
      fontSize: "1.25rem" /*20px */,
    },
    h3: {
      fontSize: "1.125rem" /*18px */,
      fontWeight: 700,
    },
    h4: {
      fontSize: "1" /*18px */,
    },
    body1: {
      color: "#707070",
      fontSize: "1 rem" /*16px */,
    },
    caption: {
      fontSize: "0.875rem",
      fontWeight: 300,
      fontStyle: "italic",
    },
    overline: {
      fontSize: "0.875rem",
      color: "#368BCC",
      textDecoration: "underline",
      cursor: "pointer",
      padding: 0,
      "&:hover": {
        color: "#0465AA",
      },
    },
  },
  overrides: {
    MuiTableSortLabel: {
      root: {
        "&:hover": {
          color: "inherit",
        },
        "&:focus": {
          color: "inherit",
        },
        "&$active": {
          color: "inherit",
          "&& $icon": {
            color: "inherit",
          },
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: "inherit",
      },
    },
  },
});
