import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { getOpenDisputes } from "../api";
import OpenDisputes from "../components/CustomTable/openDisputeTable/OpenDisputes";
import ErrorMessage from "../components/ErrorMessage";
import Layout from "../components/layout/Layout";
import LoadingProgress from "../components/LoadingProgress";
import useAsyncLoader from "../lib/useAsyncLoader";

export default function (props: any) {
  const openDisputesLoader = useAsyncLoader<any[]>([], getOpenDisputes);

  React.useEffect(() => {
    openDisputesLoader.load();
  }, []);

  return (
    <Layout>
      <Paper style={{ margin: 5 }}>
        <Box style={{ padding: 30 }}>
          <Grid container style={{ overflow: "hidden", width: "100%" }}>
            <Grid item sm={12}>
              <Grid container>
                <Grid item sm={10}>
                  <Box>
                    <h3>OPEN DISPUTES({openDisputesLoader.data.length})</h3>
                  </Box>
                </Grid>
                <Grid item sm={1}>
                  <Grid item sm={2} style={{ margin: "auto" }}></Grid>
                </Grid>
                <Grid item sm={1}></Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <ErrorMessage error={openDisputesLoader.error} />
              {openDisputesLoader.waiting ? (
                <LoadingProgress />
              ) : (
                <OpenDisputes openDisputeData={openDisputesLoader.data} />
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
}
