import { useState } from "react";
import { authStore } from "../stores/authStore";

function validateEmail(email: string): string | null {
  email = email.trim();
  if (email === "") {
    return "Email Cannot Be Empty!";
  } else if (
    !email.match(/([a-zA-Z0-9-_\.])+@([a-zA-Z0-9])+\.([a-zA-Z]){1,}/)
  ) {
    return "Not a Valid Email Address!";
  }
  return null;
}

export default function ForgotPassword() {
  const [email, setEmail] = useState<string>("");
  const [validationError, setValidationError] = useState<string | null>(null);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const [emailSent, setEmailSent] = useState<boolean>(false);

  async function sendEmail() {
    setApiError(null);

    let validationError = validateEmail(email);
    setValidationError(validationError);
    if (validationError) return;

    setIsSending(true);
    setEmailSent(false);
    try {
      await authStore.forgotPassword(email);
      setEmailSent(true);
    } catch (err) {
      setApiError("Error sending email");
    } finally {
      setIsSending(false);
    }
  }

  if (emailSent) {
    return null;
    // <AuthLayout title="Sent!">
    //   <Typography variant="h6" align="center">
    //     An message with password reset instructions has been sent to {email}.
    //   </Typography>

    //   <Box paddingTop={3}>
    //     <Typography color="textSecondary" align="center">
    //       {/* <Link component={RouterLink} to="/signin"> */}
    //       Back to Signin
    //       {/* </Link> */}
    //     </Typography>
    //   </Box>
    // </AuthLayout>
  }

  return null;
  // <AuthLayout title="Forgot Password">
  //   <ErrorMessage error={apiError} />
  //   <Box textAlign="left" fontFamily="regular" margin={3} paddingX={10}>
  //     Enter the email associated with your account and we’ll send you
  //     instructions to reset your password.
  //   </Box>
  //   <Box paddingBottom={3} paddingX={10}>
  //     <TextField
  //       type="text"
  //       value={email}
  //       onChange={e => setEmail(e.target.value)}
  //       variant="outlined"
  //       label="Email"
  //       margin="dense"
  //       fullWidth
  //       error={!!validationError}
  //       helperText={validationError || ""}
  //       disabled={isSending}
  //     />
  //   </Box>

  //   <Box paddingBottom={5} paddingX={10}>
  //     <div id="btnSub" style={{ display: "flex", margin: "auto" }}>
  //       <Button
  //         variant="outlined"
  //         color="primary"
  //         size="medium"
  //         style={{ margin: "auto" }}
  //         onClick={sendEmail}
  //         disabled={isSending}
  //       >
  //         {"Back"}
  //       </Button>

  //       <Button
  //         variant="contained"
  //         color="primary"
  //         size="medium"
  //         style={{ margin: "auto" }}
  //         onClick={sendEmail}
  //         disabled={isSending}
  //       >
  //         {isSending ? "Sending..." : "Submit"}
  //       </Button>
  //     </div>
  //   </Box>

  //   {/* <Box paddingTop={3}>
  //     <Typography color="textSecondary" align="center">
  //       <Link component={RouterLink} to="/signin">
  //         Back to Signin
  //       </Link>
  //     </Typography>
  //   </Box> */}
  // </AuthLayout>
}
