import { useEffect, useState } from "react";

/**
 * This is for using React hooks to share state between components
 */
export class Store<State> {
  protected state: State;

  private setters: React.Dispatch<React.SetStateAction<State>>[] = [];

  constructor(initialState: State) {
    this.state = initialState;
    this.setState = this.setState.bind(this);
    this.use = this.use.bind(this);
  }

  setState(state: State) {
    this.state = state;
    this.setters.forEach(setter => setter(this.state));
  }

  /**
   * Call this inside your React render function to "hook" into the store
   */
  use(): State {
    const [state, set] = useState(this.state);

    useEffect(() => {
      if (!this.setters.includes(set)) {
        this.setters.push(set);
      }
      return () => {
        this.setters = this.setters.filter(setter => setter !== set);
      };
    }, []);

    return state;
  }
}
