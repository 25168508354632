
export interface DataIEPManager {
    id_: number;
    name: string;
    email: string;
    abbrv: string;
  }
  
  export const HeaderDataIEPManager: HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "NAME",
    },
    { id: "abbrv", numeric: false, disablePadding: false, label: "ABBREVIATION" },
    { id: "email", numeric: false, disablePadding: false, label: "EMAIL" },
  ];
  
  export interface HeadCell {
    disablePadding: boolean;
    id: keyof DataIEPManager;
    label: string;
    numeric: boolean;
  }
