import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import { deleteDisputeReason, getDisputeReasonswithDepenencies } from "../../../api";
import { getComparator, Order, stableSort } from "../../../lib/sort";
import AddCannedResponse from "../../AddCannedResponse";
import TableToExcel from "../../TableToExcel/TableToExcel";
import WaitPopup from "../../WaitPopup";
import { useStyles } from "./styles";
import { DataCustomerManager, HeadCell, HeaderDataCustomerManager } from "./types";

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DataCustomerManager) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: HeadCell[];
}

function SortableTableHeaders(props: EnhancedTableProps) {
  const { classes, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property: keyof DataCustomerManager) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
              padding={"checkbox"}
              style={{
                backgroundColor: "rgba(4, 101, 170, 1)",
                color: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                hideSortIcon={true}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
        <TableCell
          style={{
            width: 5,
            padding: 0,
            margin: 0,
            backgroundColor: "rgba(4, 101, 170, 1)",
            color: "white",
          }}
        >
            EDIT
        </TableCell>
        <TableCell
          style={{
            width: 5,
            padding: 0,
            margin: 0,
            backgroundColor: "rgba(4, 101, 170, 1)",
            color: "white",
          }}
        >
            DELETE
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function rand() {
  return Math.round(Math.random() * 15) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


export default function (props: any) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("reason");
  const [selectedItem, setSelected] = React.useState<number>();
  const [editID, setEditID] = React.useState();
  const [deleteID, setDeleteID] = React.useState<number|undefined>();
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [reasons, setReasons] = React.useState();
  const [modalStyle] = React.useState(getModalStyle);
  const [newState, setNewState] = React.useState(false);
  let openWait: boolean = true;

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DataCustomerManager
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
    let newSelected: number = -1;
    newSelected = name;
    setSelected(newSelected);
  };

  const loadPageInfo = () => {
    getDisputeReasonswithDepenencies( setReasons);
  };

  const handleEditClose = () => {
    loadPageInfo();
    setOpenEdit(false);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleEditClick = (event: any, id: any) => {
    setNewState(false);
    setEditID(id);
    setOpenEdit(true);
  };

  const handleNewClick = (event: any) => {
    setNewState(true);
    setOpenEdit(true);
  };

  const handleDeleteClick = (event: any, id: number) => {
      setOpenDelete(true);
      setDeleteID(id);
  };

  const handleDeleteYes = () =>{
    deleteDisputeReason( deleteID, (data)=>{
        loadPageInfo();
        setOpenDelete(false);
    });
  };

  if (reasons) {
    openWait = false;
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openEdit}
        onClose={handleEditClose}
      >
        <div style={modalStyle} className={classes.paper}>
            <AddCannedResponse id={editID} handleClose={handleEditClose} newState={newState}/>
        </div>
      </Modal>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openDelete}
      >
        <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to Delete this item?"}
        </DialogTitle>
        <DialogActions>
            <Button onClick={handleDeleteClose} color="primary">
            No, keep it.
            </Button>
            <Button onClick={handleDeleteYes} color="primary">
            Yes, delete.
            </Button>
        </DialogActions>
        </Dialog>
      </Modal>
      <Box style={{ height: "auto", width: "100%", padding: 0 }}>
        <TableToExcel
          id="tte_reasonManager"
          sheet="Worksheet"
          table="reasons"
          fileName="DisputeReasons"
          buttonText=""
          className=""
        />
        <IconButton
          onClick={(event) => handleNewClick(event)}
          style={{ float: "right", padding: 3 }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <TableContainer style={{ width: "100%" }}>
        <Table id="reasons" stickyHeader={true} size={"small"}>
          <SortableTableHeaders
            classes={classes}
            numSelected={1}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={0}
            headCells={HeaderDataCustomerManager}
          />
          <TableBody>
            {reasons &&
              stableSort(reasons, getComparator(order, orderBy)).map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    hover
                    onClick={(event) => handleClick(event, index)}
                    tabIndex={-1}
                    selected={selectedItem === index}
                  >
                    <TableCell>{row.reason}</TableCell>
                    <TableCell>
                      <IconButton onClick={(event) => handleEditClick(event, row.id_)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                        {row.notUsed ?
                        <IconButton onClick={(event) => handleDeleteClick(event, row.id_)}>
                          <DeleteIcon />
                        </IconButton>
                        :
                        <div/>
                        }
                      </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
