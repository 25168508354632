import { Box } from "@material-ui/core";
import * as React from "react";
import { getInvoiceRecords, getSetting, markTerminalResponseAccepted } from "../api";
import Layout from "../components/layout/Layout";
import Panel from "../components/layout/Panel";
import InvoicesToReview from "../components/terminalDashboard/InvoicesToReview";
import RecentlyApprovedInvoices from "../components/terminalDashboard/RecentlyApprovedInvoices";
import WaitPopup from "../components/WaitPopup";
import { formatDateMMDDYYYY } from "../formatters";
import { authStore } from "../stores/authStore";
import useAsyncLoader from "../useAsyncLoader";

interface Props {
  invoices: [] | undefined;
  loading: boolean;
}

export default function TerminalDashboard() {
  const auth = authStore.use();
  const me = auth.me;
  const invoicesToReview = useAsyncLoader<any | undefined>(undefined, () => {});
  const [isloading, setIsLoading] = React.useState<any | undefined>(true);
  const [termInvoices, setTermInvoices] = React.useState<any | undefined>();
  const [completedInvoices, setCompletedInvoices] = React.useState<any | undefined>();
  const [reviewInvoices, setReviewInvoices] = React.useState<any | undefined>();
  const [daysBeforeDueSetting, setdaysBeforeDueSetting] = React.useState<any | undefined>();
  const [recentTitle, setRecentTitle] = React.useState<any | undefined>(
    "recently approved invoices"
  );
  const [waitMsg, setWaitMsg] = React.useState<string | undefined>("...Loading Info");
  const [openWait, setOpenWait] = React.useState<boolean>(false);

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  React.useEffect(() => {
    invoicesToReview.load();
  }, []);

  React.useEffect(() => {
    if (termInvoices && daysBeforeDueSetting) {
      let invoiceList: any[] = [];
      let completeInvoiceList: any[] = [];
      termInvoices.forEach((invoice) => {
        if (countInvoiceRecordsNotReviewed(invoice.InvoiceRecords ? invoice.InvoiceRecords : [])) {
          invoice.InvoiceRecords.forEach((element) => {
            let profitLost = getProfitLoss(element.total_billed, element.matches);
            if (
              (element.terminal_status &&
                element.terminal_status != 7 &&
                element.terminal_status != 8) ||
              profitLost > 0
            ) {
              let completedInvoiceInfo: any = {};
              completedInvoiceInfo.invoice = element.invoice_num;

              completedInvoiceInfo.invoiceDate = element.invoice_dt
                ? formatDateMMDDYYYY(new Date(element.invoice_dt.replace(" GMT", "")))
                : null;
              completedInvoiceInfo.dateOut = element.dt_out
                ? formatDateMMDDYYYY(new Date(element.dt_out.replace(" GMT", "")))
                : null;
              completedInvoiceInfo.dateIn = element.dt_in
                ? formatDateMMDDYYYY(new Date(element.dt_in.replace(" GMT", "")))
                : null;
              completedInvoiceInfo.updated_dt = element.updated_dt
                ? new Date(element.updated_dt.replace(" GMT", ""))
                : null;
              completedInvoiceInfo.containerNum = element.container1;
              completedInvoiceInfo.chassisNum = element.chassis;
              completedInvoiceInfo.terminal = me.terminal_name;
              completedInvoiceInfo.manifestNum = element.manifest_num;
              completeInvoiceList.push(completedInvoiceInfo);
            }
          });
          let weekAgo = new Date();
          weekAgo.setDate(weekAgo.getDate() - 7);
          completeInvoiceList = completeInvoiceList.filter((item) => item.updated_dt > weekAgo);
          setCompletedInvoices(completeInvoiceList);
        }
        {
          let today = new Date();
          let dueDate =
            invoice.InvoiceRecords &&
            invoice.InvoiceRecords[0] &&
            invoice.InvoiceRecords[0].sent_to_terminal_dt
              ? new Date(invoice.InvoiceRecords[0].sent_to_terminal_dt.replace(" GMT", ""))
              : new Date();
          let daysRemaining = daysBeforeDueSetting.value ? parseInt(daysBeforeDueSetting.value) : 0;
          dueDate.setDate(dueDate.getDate() + daysRemaining);

          let diffTime = dueDate.getTime() - today.getTime();
          let diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));
          if (diffDays <= 0) {
            setWaitMsg("DUE DATE REACHED: AUTO ACCEPTING INVOICE RECORDS");
            setOpenWait(true);
            markTerminalResponseAccepted(invoice.id_, me.terminal_id, (data) => {
              setOpenWait(false);
            });
            return;
          }
          let invoiceInfo: any = {};
          invoiceInfo.id = invoice.id_;
          invoiceInfo.invoice = invoice.invoice_num;
          invoiceInfo.daysRemaining = diffDays;
          invoiceInfo.totalInvoices = countInvoiceRecordsNotReviewed(
            invoice.InvoiceRecords ? invoice.InvoiceRecords : []
          );
          invoiceInfo.reviewedInvoices = countInvoiceRecordsToReview(
            invoice.InvoiceRecords ? invoice.InvoiceRecords : []
          );
          invoiceList.push(invoiceInfo);
        }
      });
      setReviewInvoices(invoiceList);
    }
  }, [termInvoices]);

  React.useEffect(() => {
    if (completedInvoices) {
      setRecentTitle(recentTitle + " (" + completedInvoices.length + ")");
    }
  }, [completedInvoices]);

  function countInvoiceRecordsNotReviewed(invoiceRecordList: any[]) {
    let notReviewedCount = 0;
    invoiceRecordList.forEach((invoiceRec) => {
      if (invoiceRec.terminal_status) {
        notReviewedCount++;
      }
    });
    return notReviewedCount;
  }

  function countInvoiceRecordsToReview(invoiceRecordList: any[]) {
    let reviewedCount = 0;
    invoiceRecordList.forEach((invoiceRec) => {
      let profitLost = getProfitLoss(invoiceRec.total_billed, invoiceRec.matches);
      if (
        (invoiceRec.terminal_status &&
          invoiceRec.terminal_status != 7 &&
          invoiceRec.terminal_status != 8) ||
        profitLost > 0
      ) {
        reviewedCount++;
      }
    });
    return reviewedCount;
  }

  function getProfitLoss(totalBilled: any, manifestList: any[]) {
    let profitLost = 0;
    let totalRebill = 0;
    manifestList.forEach((item) => {
      if (item.ar_chuse && item.ar_chuse > 0) {
        totalRebill += parseFloat(item.ar_chuse);
      } else if (item.ar_trx && item.ar_trx > 0) {
        totalRebill += parseFloat(item.ar_trx);
      }
    });

    if (totalBilled && manifestList && manifestList.length > 0) {
      profitLost = totalRebill - totalBilled;
    } else {
      profitLost = 0;
    }
    return profitLost;
  }

  const loadPageInfo = function () {
    getSetting("DaysRemainingTerminalReview", setdaysBeforeDueSetting);
    getInvoiceRecords(
      null,
      (data) => {
        setIsLoading(false);
        setTermInvoices(data);
      },
      null,
      null,
      me.terminal_id
    );
  };

  return (
    <Box>
      <Layout>
        <WaitPopup open={openWait}>{waitMsg}</WaitPopup>
        <Box marginBottom={3}>
          <Panel title="invoices to review">
            <InvoicesToReview loading={isloading} invoices={reviewInvoices} />
          </Panel>
        </Box>
        <Box marginBottom={3}>
          <Panel title={recentTitle}>
            <RecentlyApprovedInvoices loading={isloading} invoices={completedInvoices} />
          </Panel>
        </Box>
      </Layout>
    </Box>
  );
}
