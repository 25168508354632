import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { useState } from "react";
import AuthLayout from "../components/AuthLayout";
import ErrorMessage from "../components/ErrorMessage";
import { authStore } from "../stores/authStore";

export default function SignIn(props: any) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameIsEmpty, setUsernameIsEmpty] = useState(false);
  const [passwordIsEmpty, setPasswordIsEmpty] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [signInError, setSignInError] = useState<string | null>(null);

  function usernameOnChange(event: any): void {
    setUsername(event.target.value);
    setUsernameIsEmpty(event.target.value === "");
  }

  function passwordOnChange(event: any): void {
    setPassword(event.target.value);
    setPasswordIsEmpty(event.target.value === "");
  }

  function logIn(): void {
    setUsernameIsEmpty(username === "");
    setPasswordIsEmpty(password === "");
    if (username !== "" && password !== "") {
      callSignIn();
    }
  }

  async function callSignIn() {
    setSignInError(null);
    setSubmitting(true);
    try {
      await authStore.signIn(username, password);
    } catch (err) {
      setSignInError(err.message);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <AuthLayout title="Log In">
      <ErrorMessage error={signInError} />

      <Box paddingBottom={2} paddingX={10}>
        <TextField
          type="text"
          value={username}
          onChange={usernameOnChange}
          variant="outlined"
          label="Username"
          margin="dense"
          fullWidth
          error={usernameIsEmpty}
          helperText={usernameIsEmpty && "Username Cannot Be Empty!"}
          disabled={submitting}
        />
      </Box>

      <Box paddingBottom={2} paddingX={10}>
        <TextField
          type="password"
          value={password}
          onChange={passwordOnChange}
          variant="outlined"
          label="Password"
          margin="dense"
          fullWidth
          error={passwordIsEmpty}
          helperText={passwordIsEmpty && "Password Cannot Be Empty!"}
          disabled={submitting}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              logIn();
            }
          }}
        />

        <Grid container>
          <Grid item sm={12}>
            <Box justifyContent="center" display="flex">
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={logIn}
                disabled={submitting}
              >
                Log In
              </Button>
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Box margin={2} justifyContent="center" display="flex">
              <Typography color="textSecondary" align="center">
                Sign in as terminal
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </AuthLayout>
  );
}
