import { Box } from "@material-ui/core";
import * as React from "react";
import { UWLTableColumn } from "../../types/UWLTable";
import { UWLTable } from "../CustomTable/UWLTable";
interface Props {
  invoices: [] | undefined;
  loading: boolean;
}

export default function RecentlyApprovedInvoices(props: Props) {
  const [invoices, setInvoices] = React.useState([]);

  React.useEffect(() => {
    if (props.invoices) {
      setInvoices(props.invoices);
    }
  }, [props.invoices]);
  const columns: UWLTableColumn<any>[] = [
    { id: "invoice", label: "Invoice #", type: "string" },
    { id: "invoiceDate", label: "Invoice Date", type: "date" },
    { id: "dateOut", label: "Date Out", type: "date" },
    { id: "dateIn", label: "Date In", type: "date" },
    { id: "containerNum", label: "Container #", type: "string" },
    { id: "chassisNum", label: "chassis#", type: "string" },
    { id: "manifestNum", label: "Manifest #", type: "string" },
  ];
  return (
    <Box p={2}>
      <UWLTable
        rowId="invoice"
        columns={columns}
        sizeMedium
        isLoading={props.loading}
        rows={invoices}
        emptyMessage="No items to show"
      />
    </Box>
  );
}
