import DateFnsUtils from "@date-io/date-fns";
import { Button } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
  KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider
} from "@material-ui/pickers";
import "date-fns";
import { Moment } from "moment";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { getFullReport } from "../api";
import Layout from "../components/layout/Layout";
import WaitPopup from "../components/WaitPopup";
import { authStore } from "../stores/authStore";
import { ReportData } from "../types/ReportData";


const moment = require('moment');

export default function (props: any) {
  const history = useHistory();
  const [openWait, setOpenWait] = React.useState<boolean>(false);
  const [textWhileWait, setTextWhileWait] = React.useState("...Loading Info");

  const [reportData, setReportData] = React.useState<ReportData | undefined>(props.location.state);
  const [reportID, setReportID] = React.useState<number | undefined>();
  const [isNext, setNext] = React.useState<boolean | undefined>(false);
  const [ready, setReady] = React.useState<boolean>(false);
  const [reportName, setReportName] = React.useState<string | undefined>("");
  const [isScheduled, setScheduled] = React.useState<boolean>(false);
  const [frequency, setFrequency] = React.useState<any | undefined>("");
  const [isWeekly, setWeekly] = React.useState<boolean | undefined>(false);
  const [category, setCategory] = React.useState<any | undefined>("");
  const [activeColumns, setActiveColumns] = React.useState<any | undefined>();
  const [allColumns, setAllColumns] = React.useState<any | undefined>();
  const [filters, setFilters] = React.useState<any | undefined>();
  const [recipients, setRecipients] = React.useState<any | undefined>();
  const [allReport, setAllReport] = React.useState<any | undefined>([]);
  const [reportType, setReportType] = React.useState<any | undefined>();
  const [scheduledTime, setScheduledTime] = React.useState<Moment | null>(moment());
  const [scheduledStartDate, setScheduledStartDate] = React.useState<Moment | null>(moment());
  const [weekdays, setWeekdays] = React.useState<any>([
    {
      day: "Sunday",
      abbrv: "Su",
      isSelected: false,
    },
    { day: "Monday", abbrv: "Mo", isSelected: false },
    { day: "Tuesday", abbrv: "Tu", isSelected: false },
    { day: "Wednesday", abbrv: "We", isSelected: false },
    { day: "Thursday", abbrv: "Th", isSelected: false },
    { day: "Friday", abbrv: "Fr", isSelected: false },
    { day: "Saturday", abbrv: "Sa", isSelected: false },
  ]);
  const [invalidReportName, setInvalidReportName] = React.useState<boolean>(false);

  const selectedDay = {
    margin: 10,
    width: "28px",
    height: "28px",
    backgroundColor: "#368BCC",
    fontSize: "11px",
  };
  const unSelectedDay = {
    margin: 10,
    width: "28px",
    height: "28px",
    backgroundColor: "white",
    border: "1px solid",
    color: "#368BCC",
    borderColor: "#368BCC",
    fontSize: "11px",
  };
  const [dayStyle, setDayStyle] = React.useState<any>(unSelectedDay);
  const blueHeading = {
    color: "#368BCC",
  };
  const auth = authStore.use();
  const me = auth.me;
  const frequencies = ["weekly", "monthly", "once"];
  const categories = ["invoice", "ccm", "trinium"];

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  React.useEffect(() => {
  }, [isScheduled]);

  React.useEffect(() => {
    if (reportName) {
      checkReady();
    }
  }, [reportName]);

  React.useEffect(() => {
    if (isNext) {
      if (reportData) {
        if (reportData.type_ == "ReportType.pre_defined") {
          reportData.scheduledTime = undefined;
          reportData.scheduledStartDate = undefined;
          history.push("/reportparams/", reportData);
        } else {
          history.push("/reportcolumns", reportData);
        }
      }
    }
  }, [isNext]);

  React.useEffect(() => {
    if (frequency == "weekly") {
      setWeekly(true);
    } else {
      setWeekly(false);
    }
  }, [frequency]);

  const handleTimeChange = (time: Moment | null) => {
    setScheduledTime(time);
    checkReady();
  };

  const handleScheduledStartChange = (date: Moment | null) => {
    setScheduledStartDate(date);
    checkReady();
  };

  const loadPageInfo = () => {
    if (props.location.state) {
      setReportData(props.location.state);
      if (props.location.state.reportID) {
        setOpenWait(true);
        setReportID(props.location.state.reportID);
        getFullReport( props.location.state.reportID, (data) => {
          setOpenWait(false);
          if (data) {
            if (data.schedule && data.schedule.length > 0) {
              let dateLimit = moment();
              dateLimit.year(dateLimit.year() + 100);
              if (data.schedule[0].start_dt && moment.utc(data.schedule[0].start_dt) < dateLimit) {
                data.schedule[0].start_dt = data.schedule[0].start_dt.replace(" GMT", "");
                setScheduled(true);
                setScheduledStartDate(moment.utc(data.schedule[0].start_dt));
                setScheduledTime(moment.utc(data.schedule[0].start_dt));
              }
              if (data.schedule[0].frequency == 1) {
                setFrequency("weekly");
              }
              if (data.schedule[0].frequency == 2) {
                setFrequency("monthly");
              }
              if (data.schedule[0].frequency == 3) {
                setFrequency("once");
              }
            } else {
            }
            setReportName(data.report_name);
            setCategory(data.category);
            setActiveColumns(data.assigned_columns);
            setFilters(data.filters);
            setRecipients(data.recipients);
            setAllReport(data);
            setReportType(data.type_);
            setReady(true);
          }
        });
      } else {
        if (reportData) {
          if (reportData.reportID) {
            setReportID(reportData.reportID);
          }
          setReportName(reportData.reportName);
          setScheduled(reportData.isScheduled);
          setFrequency(reportData.frequency);
          setCategory(reportData.category);
          setScheduledStartDate(reportData.scheduledStartDate);
          setScheduledTime(reportData.scheduledTime);
          setReportType(reportData.type_);
          setReady(true);
        }
      }
    }
  };

  const handleScheduled = (event: any) => {
    let bool_;
    if (event.target.value == "Yes") {
      bool_ = true;
    } else {
      bool_ = false;
    }
    setScheduled(bool_);
    checkReady();
  };

  const handleWeekdayClick = (event: any, index) => {
    let updatedWeekdays = weekdays;
    updatedWeekdays[index].isSelected = !weekdays[index].isSelected;
    setWeekdays(updatedWeekdays);
    if (updatedWeekdays[index].isSelected) {
      setDayStyle(selectedDay);
    } else {
      setDayStyle(unSelectedDay);
    }
    checkReady();
  };

  const handleFrequencyChange = (event: any) => {
    setFrequency(event.target.value);
    checkReady();
  };

  const handleCategoryChange = (event: any) => {
    setCategory(event.target.value);
    checkReady();
  };

  const handleNameChange = function (event: any) {
    setReportName(event.target.value);
    checkReady();
  };


  const closeReportNamePopup = () => {
    setInvalidReportName(false);
  };

  function validateReportName(name) {
    var specialCharacters = /^[A-Za-z0-9 ]+$/;
    var isValid = specialCharacters.test(name);
    isValid = true;
    setInvalidReportName(!isValid);
    return isValid
  }

  function handleNext() {
    setReportData({
      allColumns: allColumns,
      existingReport: allReport,
      reportName: reportName,
      isScheduled: isScheduled,
      isWeekly: isWeekly,
      category: category,
      scheduledTime: scheduledTime ? moment.utc(scheduledTime.toLocaleString()).format("L"):undefined,
      scheduledStartDate: scheduledStartDate ? moment.utc(scheduledStartDate.toLocaleString()).format("L"):undefined,
      weekdays: weekdays,
      frequency: frequency,
      activeColumns: activeColumns,
      filters: filters,
      recipients: recipients,
      username: "",
      type_: reportType,
      reportID: reportID,
    });
    setNext(true);
  }

  const checkReady = function () {
    if (validateReportName(reportName)) {
      setReady(true);
    } else {
      setReady(false);
    }
    if (isScheduled) {
      if (validateReportName(reportName) && frequency && scheduledStartDate && scheduledTime) {
        setReady(true);
      }
    }
  };

  return (
    <Layout>
      <Paper style={{ margin: 5 }}>
        <WaitPopup open={openWait}>{textWhileWait}</WaitPopup>
        <Box style={{ padding: 30 }}>
          <Grid
            container
            style={{
              width: "auto",
              margin: "0% 20%",
            }}
          >
            <Grid item sm={12} xs={12}>
              <Box style={{ marginLeft: 8 }} justifyContent="left" display="flex">
                <Typography>REPORT DETAILS</Typography>
              </Box>
              <hr color="#E4E4E4"></hr>
            </Grid>
            <Grid item sm={12} style={{ margin: 10 }}>
              <TextField
                style={{ marginTop: 10, width: "100%" }}
                value={reportName}
                required
                disabled = {reportType === "ReportType.pre_defined"? true : false}
                label="Report Name"
                variant="outlined"
                onChange={handleNameChange}
              />
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                autoHideDuration={3000}
                open={invalidReportName}
                onClose={closeReportNamePopup}
                message="Invalid Report Name. Remove any punctuation or special characters."
                key={"invalidReportName"}
              />
            </Grid>
            <Grid item sm={12}>
              {reportType && reportType == "ReportType.pre_defined" ? (
                <div></div>
              ) : (
                  <FormControl component="fieldset">
                    <FormLabel style={{ color: "#368BCC" }} component="legend">
                      Scheduled?
                  </FormLabel>
                    <RadioGroup
                      row
                      aria-label="scheduled"
                      name="scheduled"
                      value={isScheduled ? "Yes" : "No"}
                      onChange={handleScheduled}
                    >
                      <FormControlLabel
                        value={"Yes"}
                        control={<Radio color="secondary" />}
                        label="Yes"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value={"No"}
                        control={<Radio color="secondary" />}
                        label="No"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
            </Grid>
            {isScheduled && (
              <Grid item sm={5} style={{ margin: 10 }}>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  label="Frequency"
                  value={frequency}
                  SelectProps={{ displayEmpty: true }}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleFrequencyChange}
                >
                  {frequencies &&
                    frequencies.map((item, index) => {
                      if (frequencies.length <= 0) {
                        return <div />;
                      }
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
            )}
            {isScheduled && (
              <Grid item sm={5} style={{ margin: 10 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardTimePicker
                      id="scheduled-time"
                      label="Scheduled time"
                      inputVariant="outlined"
                      value={scheduledTime}
                      onChange={handleTimeChange}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            )}
            {isScheduled && (
              <Grid item sm={5} style={{ margin: 10 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDatePicker
                      id="schedule-start"
                      label="Schedule Start Date"
                      inputVariant="outlined"
                      error={false}
                      value={scheduledStartDate}
                      onChange={handleScheduledStartChange}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            )}
            {isWeekly && false && (
              <Grid item sm={8} style={{ padding: 6 }}>
                <Typography style={{ color: "#B1B1B1", fontSize: "12px", marginLeft: 12 }}>
                  On
                </Typography>
                <Grid container item sm={12}>
                  {weekdays &&
                    weekdays.map((day, index) => {
                      return (
                        <Grid
                          item
                          sm={1}
                          key={index}
                          style={{
                            flexWrap: "wrap",
                            padding: 3,
                            marginRight: 15,
                          }}
                        >
                          <Avatar
                            onClick={(event) => handleWeekdayClick(event, index)}
                            style={day.isSelected ? selectedDay : unSelectedDay}
                          >
                            {day.abbrv}
                          </Avatar>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            )}
            <Grid container direction="row" alignItems="center">
              <Grid item sm={3}>
                <Link to={"/reports/"}>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ margin: 10, float: "left", width: "80%" }}
                    onClick={(e) => {
                      history.push("/reports", reportData);
                    }}
                  >
                    Back
                  </Button>
                </Link>
              </Grid>
              <Grid item sm={6}>
                <hr color="#909090" />
              </Grid>
              <Grid item sm={3}>
                <Button
                  disabled={!ready}
                  variant="outlined"
                  color="primary"
                  style={{ margin: 10, float: "right", width: "80%" }}
                  onClick={(e) => {
                    handleNext();
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
}
