export default function LocalStorageManager<T = any>(key: string, defaultValue: T) {
  return {
    get(): T {
      try {
        const value = JSON.parse(localStorage.getItem(key) as any);
        if (value !== null && value !== undefined) {
          return value;
        }
      } catch (err) {
      }
      return defaultValue;
    },
    set(value: T) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  };
}
