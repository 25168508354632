import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import clsx from "clsx";
import React from "react";
import ViewMore from "./ViewMore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(3),
    },
    wrapper: {
      width: "100%",
      height: "100%",
    },
    inputWrapper: {
      position: "absolute",
      overflow: "hidden",
      display: "inline",
      width: "100%",
      height: "50%",
      zIndex: 1,
      opacity: 0,
      left: 0,
      top: 0,
    },
    paper: {
      position: "absolute",
      width: 500,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    btnSelected: {
      backgroundColor: "rgba(54, 139, 204, 1)",
    },
    btnUnSelected: {
      backgroundColor: "rgba(178, 178, 178, 1)",
    },
  })
);

function rand() {
  return Math.round(Math.random() * 15) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface Props {
  setSelectedFilter: Function;
  totalCCM: number;
  completeCCMs: number;
  incompleteCCMs: number;
  urgentCCMs: number;
  totalInvoices: number;
  completeInvoices: number;
  incompleteInvoices: number;
  urgentInvoices: number;
  handleSetIEPFilter: Function;
}

export default function RecordSummary(props: Props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState("incomplete");
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleBtnClick(index: string) {
    setSelected(index);
    props.setSelectedFilter(index);
  }

  return (
    <Box>
      <Grid container style={{ padding: 15 }}>
        <Grid item sm={6}>
          <Box style={{ margin: "auto" }}>
            <h4 style={{ margin: "auto" }}>
              RECORD SUMMARY ({props.totalCCM + props.totalInvoices})
            </h4>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box style={{ textAlign: "right" }}>
            <Link onClick={handleOpen}>
              View More IEPs
              <VisibilityIcon style={{ margin: "auto" }} />
            </Link>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={open}
              onClose={handleClose}
            >
              <div style={modalStyle} className={classes.paper}>
                <ViewMore handleSetIEPFilter={props.handleSetIEPFilter} isClosed={handleClose} />
              </div>
            </Modal>
          </Box>
        </Grid>
      </Grid>
      <Grid container style={{ padding: 5 }}>
        <Grid item sm={3} style={{ padding: 5 }}>
          <Button
            className={clsx({
              [classes.btnSelected]: selected === "all",
              [classes.btnUnSelected]: selected !== "all",
            })}
            onClick={(event) => handleBtnClick("all")}
            variant="contained"
            color="primary"
            style={{ width: "100%", height: "100%", borderRadius: 0 }}
          >
            <Box style={{ paddingTop: 20, paddingBottom: 20 }}>
              <Grid container>
                <Grid item sm={12}>
                  <h3 style={{ margin: 0 }}>ALL ({props.totalCCM + props.totalInvoices})</h3>
                </Grid>
                <Grid item sm={5}>
                  CCM {props.totalCCM}
                </Grid>
                <Grid item sm={2}>
                  <Box>|</Box>
                </Grid>
                <Grid item sm={5}>
                  INV {props.totalInvoices}
                </Grid>
              </Grid>
            </Box>
          </Button>
        </Grid>
        <Grid item sm={3} style={{ padding: 5 }}>
          <Button
            className={clsx({
              [classes.btnSelected]: selected === "complete",
              [classes.btnUnSelected]: selected !== "complete",
            })}
            onClick={(event) => handleBtnClick("complete")}
            variant="contained"
            color="primary"
            style={{ width: "100%", height: "100%", borderRadius: 0 }}
          >
            <Box style={{ paddingTop: 20, paddingBottom: 20 }}>
              <Grid container>
                <Grid item sm={12}>
                  <h3 style={{ margin: 0 }}>
                    COMPLETE ({props.completeCCMs + props.completeInvoices})
                  </h3>
                </Grid>
                <Grid item sm={5}>
                  CCM {props.completeCCMs}
                </Grid>
                <Grid item sm={2}>
                  <Box>|</Box>
                </Grid>
                <Grid item sm={5}>
                  INV {props.completeInvoices}
                </Grid>
              </Grid>
            </Box>
          </Button>
        </Grid>
        <Grid item sm={3} style={{ padding: 5 }}>
          <Button
            className={clsx({
              [classes.btnSelected]: selected === "incomplete",
              [classes.btnUnSelected]: selected !== "incomplete",
            })}
            onClick={(event) => handleBtnClick("incomplete")}
            variant="contained"
            color="primary"
            style={{ width: "100%", height: "100%", borderRadius: 0 }}
          >
            <Box style={{ paddingTop: 20, paddingBottom: 20 }}>
              <Grid container>
                <Grid item sm={12}>
                  <h3 style={{ margin: 0 }}>
                    INCOMPLETE ({props.incompleteCCMs + props.incompleteInvoices})
                  </h3>
                </Grid>
                <Grid item sm={5}>
                  CCM {props.incompleteCCMs}
                </Grid>
                <Grid item sm={2}>
                  <Box>|</Box>
                </Grid>
                <Grid item sm={5}>
                  INV {props.incompleteInvoices}
                </Grid>
              </Grid>
            </Box>
          </Button>
        </Grid>
        <Grid item sm={3} style={{ padding: 5 }}>
          <Button
            className={clsx({
              [classes.btnSelected]: selected === "urgent",
              [classes.btnUnSelected]: selected !== "urgent",
            })}
            onClick={(event) => handleBtnClick("urgent")}
            variant="contained"
            color="primary"
            style={{ width: "100%", height: "100%", borderRadius: 0 }}
          >
            <Box style={{ paddingTop: 20, paddingBottom: 20 }}>
              <Grid container>
                <Grid item sm={12}>
                  <h3 style={{ margin: 0 }}>URGENT ({props.urgentCCMs + props.urgentInvoices})</h3>
                </Grid>
                <Grid item sm={5}>
                  CCM {props.urgentCCMs}
                </Grid>
                <Grid item sm={2}>
                  <Box>|</Box>
                </Grid>
                <Grid item sm={5}>
                  INV {props.urgentInvoices}
                </Grid>
              </Grid>
            </Box>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
