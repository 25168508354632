export interface DataApSums {
    payToMonth: number;
    sumApprovedAmt: string;
    sumRebillAmt: string;
  
  }
  
  export const HeaderDataApSums: HeadCell[] = [
  
    { id: "payToMonth", numeric: false, disablePadding: false, label: "PAYED TO MONTH" },
    { id: "sumApprovedAmt", numeric: false, disablePadding: false, label: "SUM OF APPROVED" },
    { id: "sumRebillAmt", numeric: false, disablePadding: false, label: "SUM OF REBILL" },
  
  ];
  
  export interface HeadCell {
    disablePadding: boolean;
    id: keyof DataApSums;
    label: string;
    numeric: boolean;
  }