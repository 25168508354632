import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { getGroups, getRoles, getTerminals } from "../api";
import { setUserAPI, getUser } from "../api/userAPI";
import WaitPopup from "./WaitPopup";

function isTerminal(id: number | undefined, groups: any[]) {

  if (id != undefined && groups) {
    const t = groups.find((grp) => grp.id_ == id);
    if (t) {
      return t.group.toLowerCase() === "termteam";
    }
  }
  return false;
}

interface Props {
  id: number;
  handleClose: Function;
  newState: boolean;
}

export default function (props: Props) {
  const [user, setUser] = React.useState<any | undefined>();
  const [groups, setGroups] = React.useState<any | undefined>();
  const [roles, setRoles] = React.useState<any | undefined>();
  const [terminals, setTerminals] = React.useState<any | undefined>();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [pass, setPass] = React.useState(null);
  const [role, setRole] = React.useState(-1);
  const [group, setGroup] = React.useState(-1);
  const [terminal, setTerminal] = React.useState(-1);
  let openWait: boolean = true;

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  React.useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setUsername(user.username);
      setPass(user.password);
      setRole(user.role_id);
      setGroup(user.group_id);
      setTerminal(user.terminal_id);
    }
  }, [user]);

  function loadPageInfo() {
    if (props.newState) {
      setUser({ name: "", email: "", role_id: -1, group_id: -1, username: "" });
    } else {
      getUser( props.id, setUser);
    }
    getGroups( setGroups);
    getRoles( setRoles);
    getTerminals( setTerminals);
  }

  function handleGroupDDChange(event: any) {
    setGroup(event.target.value);
  }

  function handleTerminalDDChange(event: any) {
    setTerminal(event.target.value);
  }

  function handleRoleDDChange(event: any) {
    setRole(event.target.value);
  }

  function handleSave() {
    if (validated()) {
      if (props.newState) {
        user.name = name;
        user.username = username;
        user.password = pass ?? null;
        user.email = email;
        user.role_id = role;
        user.group_id = group;

        if (isTerminal(user.group_id, groups)) {
          user.terminal_id = terminal;
        }

        setUserAPI(user, props.handleClose);
      } else {
        user.name = name;
        user.username = username;
        user.password = pass ?? null;
        user.email = email;
        user.role_id = role;
        user.group_id = group;

        if (isTerminal(user.group_id, groups)) {
          user.terminal_id = terminal;
        }

        setUserAPI(user, props.handleClose);
      }
    } else {
      alert("Information is not complete or in the correct format.");
    }
  }

  function validated() {
    if (props.newState) {
      if (name && username && pass && validateEmail(email) && role && group) {
        if (isTerminal(group, groups) && terminal) {
          return true;
        }
        if (!isTerminal(group, groups)) {
          return true;
        }
      }
      return false;
    } else {
      if (name && username && validateEmail(email) && role && group) {
        if (isTerminal(group, groups) && terminal) {
          return true;
        }
        if (!isTerminal(group, groups)) {
          return true;
        }
        return false;
      }
      return false;
    }
  }

  function validateEmail(email: string) {
    if (email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      return true;
    }
    return false;
  }

  let emailError: boolean = false;
  if (validateEmail(email)) {
    emailError = false;
  } else {
    emailError = true;
  }

  if (user) {
    openWait = false;
  }

  return (
    <div>
      <WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
      <Grid container>
        <Grid item sm={12}>
          <Box>{props.newState ? <h3>Add User</h3> : <h3>Edit User</h3>}</Box>
        </Grid>
        <Grid item sm={12}>
          <Grid container>
            <Grid item sm={5} style={{ margin: 10 }}>
              <TextField
                label="NAME"
                value={name}
                onChange={(event: any) => {
                  setName(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={6} style={{ margin: 10 }}>
              <TextField
                error={emailError ? true : false}
                label="EMAIL"
                type="email"
                value={email}
                onChange={(event: any) => {
                  setEmail(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={3} style={{ margin: 10 }}>
              <TextField
                size="small"
                select
                variant="outlined"
                label="ROLE"
                value={role}
                onChange={handleRoleDDChange}
              >
                {roles &&
                  roles.map((item, index) => {
                    if (roles.length <= 0) {
                      return <div />;
                    }
                    return (
                      <MenuItem key={index} value={item.id_}>
                        {item.role}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
            <Grid item sm={3} style={{ margin: 10 }}>
              <TextField
                size="small"
                select
                variant="outlined"
                label="GROUP"
                value={group}
                onChange={handleGroupDDChange}
              >
                {groups &&
                  groups.map((item, index) => {
                    if (groups.length <= 0) {
                      return <div />;
                    }
                    return (
                      <MenuItem key={index} value={item.id_}>
                        {item.group}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
            <Grid item sm={3} style={{ margin: 10 }}>
              {isTerminal(group, groups) ? (
                <TextField
                  size="small"
                  select
                  variant="outlined"
                  label="TERMINAL"
                  value={terminal}
                  onChange={handleTerminalDDChange}
                >
                  {terminals &&
                    terminals.map((item, index) => {
                      if (terminals.length <= 0) {
                        return <div />;
                      }
                      return (
                        <MenuItem key={index} value={item.id_}>
                          {item.abbrv}
                        </MenuItem>
                      );
                    })}
                </TextField>
              ) : null}
            </Grid>
            <Grid item sm={5} style={{ margin: 10 }}>
              <TextField
                label="USERNAME"
                value={username}
                onChange={(event: any) => {
                  setUsername(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={6} style={{ margin: 10 }}>
              <TextField
                label={props.newState ? "PASSWORD" : "RESET PASSWORD"}
                onChange={(event: any) => {
                  setPass(event.target.value);
                }}
                variant="outlined"
                type="password"
              />
            </Grid>
            <Grid item sm={12}>
              <Box style={{ float: "right" }}>
                <Button
                  style={{ margin: 5 }}
                  onClick={() => {
                    props.handleClose();
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Close
                </Button>
                {/* TODO: disable if entry is invalid or incomplete */}
                <Button
                  style={{ margin: 5 }}
                  onClick={handleSave}
                  variant="outlined"
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
