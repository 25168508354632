import { Box, Grow, IconButton, Slide, Snackbar, Typography } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { Close as CloseIcon } from "@material-ui/icons";
import * as React from "react";
import { toastStore } from "../stores/toastStore";

function TransitionLeft(props: TransitionProps) {
  return <Slide {...props} direction="left" timeout={{ enter: 5000, exit: 0 }} />;
}

function GrowTransition(props: TransitionProps) {
  return <Grow {...props} />;
}

export default function Toaster() {
  const toast = toastStore.use();

  React.useEffect(() => {}, []);

  function handleClose() {
    toastStore.setState({
      type: toast.type,
      message: toast.message,
      autoHideDuration: toast.autoHideDuration,
      show: false,
    });
  }

  return (
    <Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={toast.show}
        autoHideDuration={toast.autoHideDuration}
        onClose={handleClose}
        TransitionComponent={GrowTransition}
        children={
          <Box>
            <Box
              padding={1}
              bgcolor={toast.type == "error" ? "#E6001F" : "#51A351"}
              borderRadius="5px"
              minWidth="200px"
              maxWidth="500px"
              display="flex"
              alignItems="center"
            >
              <div style={{ flex: 1 }}>
                {toast.type === "error" && (
                  <Box marginLeft={1} marginTop={1}>
                    <Typography variant="h3" color="textSecondary">
                      Error
                    </Typography>
                  </Box>
                )}
                <Box margin={1}>
                  <Typography variant="h4" color="textSecondary">
                    {toast.message}
                  </Typography>
                </Box>
              </div>
              <IconButton onClick={handleClose} size="small">
                <CloseIcon style={{ color: "#FFFFFF", paddingRight: 1 }} key={"close"} />
              </IconButton>
            </Box>
          </Box>
        }
      />
    </Box>
  );
}
