import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import TriniumSearch from "./CustomTable/triniumResultsTable/TriniumResults";
import WaitPopup from "./WaitPopup";

const useStyles = makeStyles((theme: Theme) => createStyles({}));
const moment = require("moment");
interface Props {
  manifestsData: any | undefined;
  isClosed: Function;
}

export default function (props: Props) {
  const [triniumData, setTriniumData] = React.useState<any | undefined>();
  const [open, setOpen] = React.useState<boolean>(false);
  const classes = useStyles();

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  const loadPageInfo = () => {
    if (props.manifestsData && props.manifestsData.length > 0) {
      setTriniumData(dateFormatting(props.manifestsData));
    }
    setOpen(false);
  };

  const dateFormatting = (data) => {
    let tempTriniumData = [];
    data.forEach((item) => {
      if (
        item.delivery_date &&
        Object.prototype.toString.call(item.delivery_date) !== "[object Date]"
      ) {
        item.delivery_date = moment.utc(item.delivery_date);
      }
      if (
        item.pickup_date &&
        Object.prototype.toString.call(item.pickup_date) !== "[object Date]"
      ) {
        item.pickup_date = moment.utc(item.pickup_date);
      }
      tempTriniumData.push(item);
    });
    tempTriniumData.sort((a, b) => {
      return b.pickup_date - a.pickup_date;
    });
    return tempTriniumData;
  };

  const handleClosed = () => () => {
    props.isClosed(true);
  };

  return (
    <Box style={{ margin: 10, minWidth: 600 }}>
      <WaitPopup open={open}>{"...Loading Info."}</WaitPopup>
      <Grid container>
        <Grid item>
          <Box>
            <h3>ASSIGNED MANIFESTS</h3>
          </Box>
        </Grid>
        <Grid item sm={12} id="gd_resultTable">
          <TriniumSearch triniumData={triniumData} />
        </Grid>
        <Grid item sm={12}>
          <Box style={{ float: "right", marginTop: 10 }}>
            <Button onClick={handleClosed()} variant="outlined" color="primary">
              Close
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
