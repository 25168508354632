import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";


interface Props {
  setSearchText: Function;
  setResetSearch: Function;
}

export default function (props: Props) {
  const [searchText, setSearchText] = React.useState("");
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => { }, []);

  function handleSearch() {
    props.setSearchText(searchText);
  }

  return (
    <div>
      <Grid container style={{ backgroundColor: "white" }}>
        <Grid item sm={12} style={{ margin: 10 }}>
          <Box>
            <h3>Search Records</h3>
          </Box>
        </Grid>
        <Grid item sm={12}>
          <Grid container>
            <Grid item sm={12} style={{ margin: 10 }}>
              <TextField
                label="Invoice record ID , Chassis , Container , Manifest"
                fullWidth
                value={searchText}
                onChange={(event: any) => {
                  setSearchText(event.target.value);
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Box style={{ float: "right" }}>
              <Button
                style={{ margin: 5 }}
                onClick={() => {
                  props.setResetSearch(true);
                }}
                variant="outlined"
                color="primary"
              >
                Reset
              </Button>
              <Button
                style={{ margin: 5 }}
                onClick={handleSearch}
                variant="outlined"
                color="primary"
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
