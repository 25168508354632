import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useHistory } from "react-router-dom";
import { emailAdHocReport, getAPIURL, runAdHocReport, saveReport } from "../api";
import ReportColumnsPreview from "../components/CustomTable/reportColumnsPreviewTable/ReportColumnsPreview";
import Layout from "../components/layout/Layout";
import { authStore } from "../stores/authStore";
import { ReportData } from "../types/ReportData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
    paper: {
      position: "absolute",
      width: "80%",
      maxHeight: "85%",
      overflow: "scroll",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

interface Props {
  style: any;
  selectedColumns: any;
}

function getModalStyle() {
  return {
    backgroundColor: "white",
    top: `${top}%`,
    margin: "auto",
  };
}

export default function (props: any) {
  const { classes, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const auth = authStore.use();
  const me = auth.me;
  const [reportData, setReportData] = React.useState<ReportData | undefined>(props.location.state);
  const history = useHistory();
  const [scheduleString, setScheduleString] = React.useState<any | undefined>("");
  const [savedReport, setSavedReport] = React.useState<any | undefined>();
  const [reportOutput, setReportOutput] = React.useState<any | undefined>();
  const [reportSending, setReportSending] = React.useState<any | undefined>();
  const [reportSent, setReportSent] = React.useState<any | undefined>();
  const [reportSaving, setReportSaving] = React.useState<any | undefined>();
  const [reportRunning, setReportRunning] = React.useState<any | undefined>();
  const [activeColumns, setActiveColumns] = React.useState<any | undefined>();
  const [filters, setFilters] = React.useState<any | undefined>();
  const [recipients, setRecipients] = React.useState<any | undefined>();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [isBack, setBack] = React.useState<boolean | undefined>(false);

  React.useEffect(() => {
    prepareScheduling();
    prepareColumns();
    prepareFilters();
    prepareRecipients();
  }, []);

  React.useEffect(() => {
    if (reportOutput) {
      let reportName = reportData.reportName;
      setOpen(false);
      let doclink = document.createElement("a");
      doclink.href =
        getAPIURL() + "export/report/ad_hoc/" + Math.floor(Math.random() * 100000);
      doclink.target = "blank";
      doclink.click();
    }
  }, [reportOutput]);

  React.useEffect(() => {
    if (reportSending) {
      emailAdHocReport( reportData, setReportSent);
      setTimeout(() => { setOpen(false); }, 2000);
    }
  }, [reportSending]);

  React.useEffect(() => {
    if (savedReport) {
      history.push("/reports");
    }
  }, [savedReport]);

  React.useEffect(() => {
    if (isBack) {
      if (reportData) {
        history.push("/reportusers", reportData);
      }
    }
  }, [isBack]);

  const prepareColumns = () => {
    if (reportData.activeColumns) {
      setActiveColumns(reportData.activeColumns);
    }
  };

  const prepareFilters = () => {
    if (reportData.filters) {
      setFilters(reportData.filters);
    }
  };

  const prepareRecipients = () => {
    if (reportData.recipients) {
      setRecipients(reportData.recipients);
    }
  };

  const prepareScheduling = () => {
    let temp = "Not scheduled";
    if (reportData.isScheduled == true) {
      if (reportData.isWeekly) {
        temp = "Weekly";
      } else {
        temp = "Monthly";
      }

      temp += " -";
      temp = temp.slice(0, -1);
    }

    setScheduleString(temp);
  };

  const closeSavingPopup = () => {
    setReportSaving(false);
  };

  const closeRunningPopup = () => {
    setReportRunning(false);
  };

  function save(): void {
    delete reportData.allColumns;
    if (!reportData.existingReport || !("id_" in reportData.existingReport)) {
      delete reportData.existingReport;
    }
    reportData.username = me.username;
    saveReport( reportData, setSavedReport);

  }

  function runReport(): void {
    setOpen(true);
    if (reportData.existingReport && "id_" in reportData.existingReport) {
    } else {
      delete reportData.existingReport;
    }
    reportData.username = me.username;
    runAdHocReport( reportData, setReportOutput);
  }

  function sendReport(): void {
    setOpen(true);
    if (reportData.existingReport && "id_" in reportData.existingReport) {
    } else {
      delete reportData.existingReport;
    }
    reportData.username = me.username;
    runAdHocReport( reportData, setReportSending);
  }

  const handleClose = () => {
    setOpen(false);
  };

  function handleBack() {
    setReportData({
      allColumns: reportData.allColumns,
      existingReport: reportData.existingReport,
      reportName: reportData.reportName,
      isScheduled: reportData.isScheduled,
      isWeekly: reportData.isWeekly,
      category: reportData.category,
      scheduledTime: reportData.scheduledTime,
      scheduledStartDate: reportData.scheduledStartDate,
      weekdays: reportData.weekdays,
      frequency: reportData.frequency,
      activeColumns: reportData.activeColumns,
      filters: reportData.filters,
      recipients: reportData.recipients,
      username: "",
      reportID: reportData.reportID,
    });
    setBack(true);
  }

  return (
    <Layout>
      <React.Fragment>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <div style={modalStyle}>
            Running report...
          </div>
        </Modal>
        <Box>
          <Paper style={{ backgroundColor: "transparent" }}>
            <Grid container style={{ backgroundColor: "transparent", padding: 5 }}>
              <Grid item sm={6}>
                <Grid style={{ maxHeight: 650, overflow: "scroll" }} item sm={12}>
                  <Typography style={{ marginLeft: 16 }}>REVIEW AND PUBLISH</Typography>
                  <hr></hr>
                  <Typography style={{ margin: 8, color: "#368BCC" }} variant="body2">
                    Details
                  </Typography>
                  <TableContainer>
                    <Table id="Details" stickyHeader={true} size={"small"}>
                      <TableHead>
                        <TableCell style={{ fontWeight: "bold", backgroundColor: "transparent" }}>
                          Name
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold", backgroundColor: "transparent" }}>
                          Scheduling
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold", backgroundColor: "transparent" }}>
                          Type
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        <TableRow style={{ padding: 2, margin: 2 }} key={reportData.reportName}>
                          <TableCell>{reportData.reportName}</TableCell>
                          <TableCell>{scheduleString}</TableCell>
                          <TableCell>{reportData.category}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid
                    container
                    style={{ backgroundColor: "transparent", marginTop: 12, marginBottom: 12 }}
                  >
                    <Grid item sm={3}>
                      <Typography style={{ margin: 8, color: "#368BCC" }} variant="body2">
                        Columns
                      </Typography>
                      <TableContainer>
                        <Table id="Columns" stickyHeader={true} size={"small"}>
                          <TableBody>
                            {activeColumns &&
                              activeColumns.map((column, index) => {
                                return (
                                  <TableRow style={{ padding: 1, margin: 1 }} key={index}>
                                    <TableCell>{column.name}</TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item sm={9}>
                      <Typography style={{ margin: 8, color: "#368BCC" }} variant="body2">
                        Filters
                      </Typography>
                      <TableContainer>
                        <Table id="Filters" stickyHeader={true} size={"small"}>
                          <TableHead>
                            <TableCell
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "transparent",
                                borderLeft: "1px solid grey",
                                borderBottom: "1px solid grey",
                              }}
                            >
                              Column Name
                            </TableCell>
                            <TableCell
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "transparent",
                                borderBottom: "1px solid grey",
                              }}
                            >
                              Operator
                            </TableCell>
                            <TableCell
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "transparent",
                                borderBottom: "1px solid grey",
                              }}
                            >
                              Value
                            </TableCell>
                          </TableHead>
                          <TableBody>
                            {filters &&
                              filters.map((filter, index) => {
                                return (
                                  <TableRow style={{ padding: 2, margin: 2 }} key={index}>
                                    <TableCell style={{ borderLeft: "1px solid grey" }}>
                                      {filter.columnName}{" "}
                                    </TableCell>
                                    <TableCell>{filter.operator} </TableCell>
                                    <TableCell>{filter.value} </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                  <Typography style={{ margin: 8, color: "#368BCC" }} variant="body2">
                    Recipients
                  </Typography>

                  <TableContainer>
                    <Table id="Recipients" stickyHeader={true} size={"small"}>
                      <TableBody>
                        {recipients &&
                          recipients.map((recipient, index) => {
                            return (
                              <TableRow style={{ padding: 1, margin: 1 }} key={index}>
                                <TableCell>{recipient}</TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid container direction="row" alignItems="center">
                  <Grid item sm={3}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ margin: 10, float: "left", width: "80%" }}
                      onClick={(e) => {
                        handleBack();
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item sm={6}>
                    <hr color="#909090" />
                  </Grid>
                  <Grid item sm={3}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ margin: 10, float: "right", width: "80%" }}
                      onClick={save}
                    >
                      Save
                    </Button>
                    <Snackbar
                      autoHideDuration={3000}
                      open={reportSaving}
                      onClose={closeSavingPopup}
                      message="Saving report..."
                      key={"savingReport"}
                    />
                  </Grid>
                </Grid>
                <br></br>
                <Grid container direction="row" justify="center" alignItems="center">
                  <Grid item alignItems="center" justify="center" sm={4}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ margin: 10, width: "90%" }}
                      onClick={runReport}
                    >
                      Download
                  </Button>
                    <Snackbar
                      autoHideDuration={300}
                      open={reportRunning}
                      onClose={closeRunningPopup}
                      message="Running report..."
                      key={"runningReport"}
                    />
                  </Grid>
                  <Grid item alignItems="center" justify="center" sm={4}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ margin: 10, width: "90%" }}
                      onClick={sendReport}
                    >
                      Send to Recipients
                  </Button>
                    <Snackbar
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      autoHideDuration={300}
                      open={reportRunning}
                      onClose={closeRunningPopup}
                      message="Sending report..."
                      key={"runningReport"}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sm={6} style={{ maxHeight: 750, overflow: "hidden" }}>
                {reportData.activeColumns && reportData.reportName && (
                  <ReportColumnsPreview
                    columns={reportData.activeColumns}
                    reportName={reportData.reportName}
                    category={reportData.category}
                    isScheduled={reportData.isScheduled}
                    isWeekly={reportData.isWeekly}
                    scheduledTime={reportData.scheduledTime}
                    scheduledStartDate={reportData.scheduledStartDate}
                    weekdays={reportData.weekdays}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </React.Fragment>
    </Layout>
  );
}
