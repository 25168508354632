import { IconButton } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import DownloadIcon from "@material-ui/icons/GetApp";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  getAPIURL, getCCMRecords,
  getIepList,
  getStatusListByCategory,
  markCCMAsCompleted
} from "../api";
import CCMPossMatches from "../components/CustomTable/ccmPossMatchesTable/CCMPossMatches";
import Layout from "../components/layout/Layout";
import WaitPopup from "../components/WaitPopup";
import { formatDateMMDDYYYY } from "../formatters";
import { authStore } from "../stores/authStore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 900,
      maxHeight: 700,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

function rand() {
  return Math.round(Math.random() * 10) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box paddingTop={0}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function (props: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [matched, setMatched] = React.useState<any | undefined>();
  const [ccmss, setCcmss] = React.useState<any | undefined>();
  const [noMatched, setNoMatched] = React.useState<any | undefined>();
  const [radioValue, setradioValue] = React.useState("ccm");
  const [iepList, setIepList] = React.useState<any | undefined>();
  const [statusList, setStatusList] = React.useState<any | undefined>();
  const [dateFrom, setDateFrom] = React.useState<Date>();
  const [dateTo, setDateTo] = React.useState<Date>();
  const [openWait, setOpenWait] = React.useState<boolean>(true);
  const [waitMsg, setWaitMsg] = React.useState<string | undefined>("...Loading Info");
  const [sendToCCM, setSendToCCM] = React.useState<any | undefined>();
  const [markCCMComplete, setMarkCCMComplete] = React.useState<any | undefined>();
  const ccmID = props.match.params.id;
  const auth = authStore.use();
  const me = auth.me;
  const history = useHistory();

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  React.useEffect(() => {
    if (ccmss) {
      let dates: Date[] = [];
      ccmss.forEach((element: any) => {
        dates.push(new Date(element.cms_move_dt.replace(" GMT", "")));
      });
      let dateSorted = dates.sort((a: any, b: any) => {
        return a - b;
      });
      setDateFrom(dateSorted[0]);
      setDateTo(dateSorted[dateSorted.length - 1]);
    }
  }, ccmss);

  React.useEffect(() => {
    if (value) {
      setOpenWait(false);
    }
  }, [value]);

  React.useEffect(() => {
    if (sendToCCM) {
      setOpenWait(false);
      history.push("/dashboard");
    }
  }, [sendToCCM]);

  React.useEffect(() => {
    if (markCCMComplete) {
      setOpenWait(false);
      history.push("/dashboard");
    }
  }, [markCCMComplete]);

  const loadPageInfo = () => {
    if (!iepList) {
      getIepList( setIepList);
    }
    if (!statusList) {
      getStatusListByCategory( "ccm", setStatusList);
    }
    getCCMRecords( ccmID, setMatched, "matched");
    getCCMRecords( ccmID, setNoMatched, "nomatched");
    getCCMRecords( ccmID, setCcmss);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setradioValue((event.target as HTMLInputElement).value);
  };

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownloadClick = () => {
    let doclink = document.createElement("a");
    doclink.href = getAPIURL() + "export/ccm/" + ccmID + "/" + Math.floor(Math.random() * 100000);
    doclink.target = "blank";
    doclink.click();
  };

  const handleFinishSendClick = () => {
    if (radioValue === "ccm") {
      setWaitMsg("...Marking CCM as Completed");
      setOpenWait(true);
      markCCMAsCompleted( ccmID, setMarkCCMComplete);
    }
  };


  React.useEffect(() => {
    if (matched && noMatched && ccmss && dateFrom && dateTo) {
      setOpenWait(false);
    }
  }, [matched, noMatched, ccmss, dateFrom, dateTo]);

  return (
    <Layout>
      <Paper style={{ margin: 5 }}>
        <WaitPopup open={openWait}>{waitMsg} </WaitPopup>
        <Box style={{ padding: 30 }}>
          <Grid container style={{ overflow: "hidden", width: "100%" }}>
            <Grid item sm={12}>
              <Box>
                <h3>
                  REVIEW - CCM MOVES {dateFrom ? formatDateMMDDYYYY(dateFrom) : ""} AND{" "}
                  {dateTo ? formatDateMMDDYYYY(dateTo) : ""}
                </h3>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="rgba(134, 133, 133, 1)"
              >
                <Tab label={"Matched (" + (matched ? matched.length : 0) + ")"} {...a11yProps(0)} />
                <Tab
                  label={"No Matches (" + (noMatched ? noMatched.length : 0) + ")"}
                  {...a11yProps(1)}
                />
                <Tab label={"Merged (" + (ccmss ? ccmss.length : 0) + ")"} {...a11yProps(2)} />
              </Tabs>
              <Box>
                <TabPanel value={value} index={0}>
                  <CCMPossMatches
                    ccmData={matched}
                    iepList={iepList ? iepList : []}
                    statusList={statusList ? statusList : []}
                    viewFirstColumn={false}
                    disableControls={true}
                    maxHeight={300}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <CCMPossMatches
                    ccmData={noMatched}
                    iepList={iepList ? iepList : []}
                    statusList={statusList ? statusList : []}
                    viewFirstColumn={false}
                    disableControls={true}
                    maxHeight={300}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <CCMPossMatches
                    ccmData={ccmss}
                    iepList={iepList ? iepList : []}
                    statusList={statusList ? statusList : []}
                    viewFirstColumn={false}
                    disableControls={true}
                    maxHeight={300}
                    displayAllItems={true}
                  />
                </TabPanel>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Grid container>
                <Grid item sm={3}>
                  <Grid container>
                    <Grid item sm={12}>
                      <h5>SAVE</h5>
                    </Grid>
                    <Grid item sm={12}>
                      <IconButton onClick={handleDownloadClick} size="small">
                        <DownloadIcon />
                        <h5>Download CCM Form</h5>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={3}>
                  <Grid container>
                    <Grid item sm={12}>
                      <h5>SEND</h5>
                    </Grid>
                    <Grid item sm={12}>
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={radioValue}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel value="ccm" control={<Radio />} label="CCM" />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6}></Grid>
                <Grid item sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: 10, float: "right" }}
                    onClick={handleFinishSendClick}
                  >
                    Finish & Complete
                  </Button>
                  <Link to={"/ccmworkflow/" + ccmID}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ margin: 10, float: "right" }}
                    >
                      Back
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
}
