import DateFnsUtils from "@date-io/date-fns";
import { Box, Button, Dialog, DialogActions, Grid, TextField, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as React from "react";
import { useEffect, useState } from "react";
import { getIepList } from "../api";

interface FilterProps {
  open: boolean;
  onClose: Function;
  setFilteredRecords: Function;
  unfilteredRecords: any[];
  setFilterOn?: Function;
}

export default function FilterDialog(props: FilterProps) {
  const [invoiceNum, setInvoiceNum] = useState("");
  const [iepSelected, setIepSelected] = useState<any | undefined>();
  const [iepList, setIepList] = useState<any | undefined>();

  useEffect(() => {
    getIepList(setIepList);
  }, []);

  function handleClose() {
    props.onClose(false);
  }

  function applyFilters() {
    let filtered: any[] = Array.from(props.unfilteredRecords);

    if (invoiceNum) {
      filtered = filtered.filter((item) => {
        return item.invoice_num.toUpperCase().includes(invoiceNum.toUpperCase());
      });
    }

    if (iepSelected) {
      filtered = filtered.filter((item) => {
        return item.iep_id == iepSelected;
      });
    }

    if ((invoiceNum || iepSelected) && props.setFilterOn) {
      props.setFilterOn(true);
    } else if (props.setFilterOn) {
      props.setFilterOn(false);
    }

    props.onClose(false);
    props.setFilteredRecords(filtered);
  }

  return (
    <Dialog open={props.open} onClose={handleClose} fullWidth>
      <Box margin={2} padding={1}>
        <Typography variant="h2" color="primary">
          Filters
        </Typography>
        <hr />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around"></Grid>
        </MuiPickersUtilsProvider>
        <Box margin={1} padding={1}>
          <TextField
            onChange={(e) => {
              setInvoiceNum(e.target.value);
            }}
            label="INVOICE #"
            variant="outlined"
            value={invoiceNum}
            fullWidth
            autoFocus={true}
          />
        </Box>
        <Box margin={1} padding={1}>
          <TextField
            id="standard-select-currency"
            select
            label="IEP"
            value={iepSelected}
            onChange={(e) => {
              setIepSelected(e.target.value);
            }}
            helperText="Please select your currency"
          >
            {iepList &&
              iepList.map((option) => (
                <MenuItem key={option.id_} value={option.id_}>
                  {option.abbrv}
                </MenuItem>
              ))}
          </TextField>
        </Box>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            cancel
          </Button>
          <Button onClick={applyFilters} variant="contained" color="primary">
            Apply
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
