import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React from "react";
import {
  deleteCCM,
  deleteInvoice,
  getCCMs,
  getInvoices,
  getSettings,
  getTerminalResponse,
} from "../api";
import CCMRecons from "../components/CCMRecons";
import InvoiceRecons from "../components/InvoiceRecons";
import Layout from "../components/layout/Layout";
import RecSum from "../components/RecordSummary";
import TermResp from "../components/TerminalResponses";
import WaitPopup from "../components/WaitPopup";
import { CCMDTO } from "../types/tables/Ccm";
import { SettingDTO } from "../types/tables/Setting";
import { InvoiceDTO } from "../types/Invoice";

type FilterButtonType = "all" | "complete" | "incomplete" | "urgent";

export default function DashboardPage() {
  const [completeInvoices, setcompleteInvoices] = React.useState<InvoiceDTO[]>();
  const [incompleteInvoices, setincompleteInvoices] = React.useState<InvoiceDTO[]>();
  const [urgentInvoices, seturgentInvoices] = React.useState<InvoiceDTO[]>();
  const [completeCCMs, setcompleteCCMs] = React.useState<CCMDTO[]>();
  const [incompleteCCMs, setincompleteCCMs] = React.useState<CCMDTO[]>();
  const [urgentCCMs, seturgentCCMs] = React.useState<any>();
  const [ccms, setccms] = React.useState<CCMDTO[]>();
  const [invoices, setInvoices] = React.useState<InvoiceDTO[]>();
  const [terminalResponses, setTerminalResponses] = React.useState<any>();
  const [filterBtn, setFilterBtn] = React.useState<FilterButtonType>("incomplete");
  const [IEPFilter, setIEPFilter] = React.useState<number[]>([]);

  const selectedInvoiceIDRef = React.useRef<number>(null);
  const selectedCCMIDRef = React.useRef<number>(null);

  const [settings, setSettings] = React.useState<SettingDTO[]>();
  const [openWait, setOpenWait] = React.useState<boolean>(false);
  const [openInvoiceDeleteConfirm, setOpenInvoiceDeleteConfirm] = React.useState<boolean>(false);
  const [openCCMDeleteConfirm, setOpenCCMDeleteConfirm] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (localStorage.getItem("dashboardfilter")) {
      const iepFilters = localStorage.getItem("dashboardfilter").split(",");
      const iepFilterList: number[] = [];
      iepFilters.forEach((item) => {
        iepFilterList.push(parseInt(item));
      });
      setIEPFilter(iepFilterList);
    }
    loadPageInfo();
  }, []);

  React.useEffect(() => {
    if (ccms && ccms.length) {
      ccms.forEach((element) => {
        element.name = element.name ? element.name.toUpperCase() : "";
        element.user = element.user ? element.user.toUpperCase() : "";
      });
      setcompleteCCMs(ccms.filter((ccm: any) => ccm.is_completed === true));
      setincompleteCCMs(ccms.filter((ccm: any) => ccm.is_completed === false));
    }
  }, [ccms]);

  React.useEffect(() => {
    if (invoices && invoices.length) {
      invoices.forEach((element) => {
        element.iep_name = element.iep_name ? element.iep_name.toUpperCase() : "";
        element.name = element.name ? element.name.toUpperCase() : "";
        element.user = element.user ? element.user.toUpperCase() : "";
        element.documented_dt = new Date(element.documented_dt);
      });
      setcompleteInvoices(invoices.filter((inv: any) => inv.is_completed === true));
      setincompleteInvoices(
        invoices.filter((inv: any) => inv.is_completed === false || inv.is_completed === null)
      );
    }
  }, [invoices]);

  React.useEffect(() => {
    const urgentInvoicesList: InvoiceDTO[] = [];
    if (incompleteInvoices && settings) {
      const daysBeforeDueDate = settings.find((setting) => {
        return setting.name == "DaysForInvoiceDueDate";
      }).value;
      const daysBeforeUrgent = settings.find((setting) => {
        return setting.name == "DaysBeforeInvoiceIsUrgent";
      }).value;
      const daysBeforeDueDateInt = daysBeforeDueDate ? parseInt(daysBeforeDueDate) : 0;
      const daysBeforeUrgentInt = daysBeforeUrgent ? parseInt(daysBeforeUrgent) : 0;
      const today = new Date();

      incompleteInvoices.forEach((ele) => {
        let invoiceDt = new Date(ele.documented_dt);
        invoiceDt.setDate(invoiceDt.getDate() + (daysBeforeDueDateInt - daysBeforeUrgentInt));
        if (today >= invoiceDt) {
          ele.isUrgent = true;
          urgentInvoicesList.push(ele);
        }
      });
      seturgentInvoices(urgentInvoicesList);
    }
  }, [incompleteInvoices, settings]);

  React.useEffect(() => {
    const urgentCCMList: CCMDTO[] = [];
    if (incompleteCCMs && settings) {
      const daysBeforeDueDate = settings.find((setting) => {
        return setting.name == "DaysForCcmDueDate";
      }).value;
      const daysBeforeUrgent = settings.find((setting) => {
        return setting.name == "DaysBeforeCcmIsUrgent";
      }).value;
      const daysBeforeDueDateInt = daysBeforeDueDate ? parseInt(daysBeforeDueDate) : 0;
      const daysBeforeUrgentInt = daysBeforeUrgent ? parseInt(daysBeforeUrgent) : 0;
      let today = new Date();

      incompleteCCMs.forEach((ele) => {
        let ccmDueDt = new Date(ele.documented_dt);
        ccmDueDt.setDate(ccmDueDt.getDate() + (daysBeforeDueDateInt - daysBeforeUrgentInt));
        if (today >= ccmDueDt) {
          ele.isUrgent = true;
          urgentCCMList.push(ele);
        }
      });
      seturgentCCMs(urgentCCMList);
    }
  }, [incompleteCCMs, settings]);

  React.useEffect(() => {
    localStorage.setItem("dashboardfilter", IEPFilter.toString());
  }, [IEPFilter]);

  let displayCCM,
    displayInv: InvoiceDTO[] = [];

  switch (filterBtn) {
    case "all":
      displayCCM = ccms;
      displayInv = invoices;
      break;
    case "complete":
      displayCCM = completeCCMs;
      displayInv = completeInvoices;
      break;
    case "incomplete":
      displayCCM = incompleteCCMs;
      displayInv = incompleteInvoices;
      break;
    case "urgent":
      displayCCM = urgentCCMs;
      displayInv = urgentInvoices;
      break;
  }

  if (IEPFilter && IEPFilter.length && displayInv) {
    displayInv = displayInv.filter((inv) => {
      return IEPFilter.includes(inv.iep_id);
    });
  }

  let displayedTerminalResponses = [];
  if (IEPFilter && IEPFilter.length && terminalResponses) {
    let tempTerminalResponse = [];
    tempTerminalResponse = terminalResponses.filter((termRes) => {
      return IEPFilter.includes(termRes.iep_id);
    });
    displayedTerminalResponses = tempTerminalResponse;
  } else {
    displayedTerminalResponses = terminalResponses;
  }

  React.useEffect(() => {
    if (ccms && invoices) {
      setOpenWait(false);
    }
  }, [ccms, invoices]);

  const loadPageInfo = () => {
    getCCMs(setccms);

    getInvoices(setInvoices);

    if (!terminalResponses) {
      getTerminalResponse(setTerminalResponses);
    }

    if (!settings) {
      getSettings(setSettings);
    }
  };

  const handleDeleteClickCached = React.useCallback((itemID: number, isInvoice: boolean) => {
    if (isInvoice) {
      selectedInvoiceIDRef.current = itemID;
      setOpenInvoiceDeleteConfirm(true);
    } else {
      selectedCCMIDRef.current = itemID;
      setOpenCCMDeleteConfirm(true);
    }
  }, []);

  const handleInvoiceDelete = () => {
    const invoiceID = selectedInvoiceIDRef.current;
    setOpenWait(true);
    setOpenInvoiceDeleteConfirm(false);
    deleteInvoice(invoiceID, (data) => {
      selectedInvoiceIDRef.current = undefined;
      selectedCCMIDRef.current = undefined;
      setOpenWait(false);
      loadPageInfo();
    });
  };

  const handleCCMDelete = () => {
    const ccmID = selectedCCMIDRef.current;
    setOpenWait(true);
    setOpenCCMDeleteConfirm(false);
    deleteCCM(ccmID, (data) => {
      selectedInvoiceIDRef.current = undefined;
      selectedCCMIDRef.current = undefined;
      setOpenWait(false);
      loadPageInfo();
    });
  };

  const handleClose = () => {
    setOpenInvoiceDeleteConfirm(false);
    setOpenCCMDeleteConfirm(false);
    selectedInvoiceIDRef.current = undefined;
    selectedCCMIDRef.current = undefined;
  };

  if (displayInv && settings) {
    let daysBeforeDueDate: number = parseInt(
      settings.find((setting) => {
        return setting.name == "DaysForInvoiceDueDate";
      }).value
    );

    displayInv.forEach((ele) => {
      if (!ele.is_completed) {
        let invoiceDt = new Date(ele.documented_dt);
        invoiceDt.setDate(invoiceDt.getDate() + daysBeforeDueDate);
        ele.dueDt = invoiceDt;
      }
    });
  }

  if (displayCCM && settings) {
    let daysBeforeDueDate: number = parseInt(
      settings.find((setting) => {
        return setting.name == "DaysForCcmDueDate";
      }).value
    );

    displayCCM.forEach((ele) => {
      let ccmDt = new Date(ele.documented_dt);
      ccmDt.setDate(ccmDt.getDate() + daysBeforeDueDate);
      ele.dueDt = ccmDt;
    });
  }

  let totalInvNum,
    completeInvNum,
    incompleteInvNum,
    urgentInvNum = 0;
  totalInvNum = displayInv ? displayInv.length : 0;
  completeInvNum = invoices ? invoices.filter((inv: any) => inv.is_completed === true).length : 0;
  incompleteInvNum = invoices
    ? invoices.filter((inv: any) => inv.is_completed === false || inv.is_completed === null).length
    : 0;

  return (
    <Layout>
      <Box style={{ margin: 5, width: "100%" }}>
        <WaitPopup open={openWait}>{"...Loading Info"}</WaitPopup>
        <Grid container>
          <Grid item sm={9}>
            <Grid container style={{ marginRight: 5 }}>
              <Grid item sm={12}>
                <Paper>
                  <RecSum
                    setSelectedFilter={setFilterBtn}
                    totalCCM={ccms ? ccms.length : 0}
                    completeCCMs={completeCCMs ? completeCCMs.length : 0}
                    incompleteCCMs={incompleteCCMs ? incompleteCCMs.length : 0}
                    urgentCCMs={urgentCCMs ? urgentCCMs.length : 0}
                    totalInvoices={invoices ? invoices.length : 0}
                    completeInvoices={completeInvNum}
                    incompleteInvoices={incompleteInvNum}
                    urgentInvoices={urgentInvoices ? urgentInvoices.length : 0}
                    handleSetIEPFilter={setIEPFilter}
                  />
                </Paper>
              </Grid>
              <Grid item sm={12}>
                <Paper style={{ maxHeight: 500 }}>
                  <InvoiceRecons
                    invoiceData={displayInv || []}
                    handleDeleteClick={handleDeleteClickCached}
                  />
                  <Dialog
                    open={openInvoiceDeleteConfirm}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Are you sure you want to Delete this item?"}
                    </DialogTitle>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        No, keep it.
                      </Button>
                      <Button onClick={handleInvoiceDelete} color="primary">
                        Yes, delete.
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Paper>
              </Grid>
              <Grid item sm={12}>
                <Paper style={{ maxHeight: 500 }}>
                  <CCMRecons
                    ccmData={displayCCM ? displayCCM : []}
                    handleDeleteClick={handleDeleteClickCached}
                  />
                  <Dialog
                    open={openCCMDeleteConfirm}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Are you sure you want to Delete this item?"}
                    </DialogTitle>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        No, keep it.
                      </Button>
                      <Button onClick={handleCCMDelete} color="primary">
                        Yes, delete.
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Paper>
              </Grid>
              <Grid item sm={12}></Grid>
            </Grid>
          </Grid>
          <Grid item sm={3}>
            <Grid container style={{ marginLeft: 5 }}>
              <Grid item sm={11}>
                <Paper>
                  <TermResp
                    terminalData={displayedTerminalResponses ? displayedTerminalResponses : []}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}
