import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React from "react";
import InvoiceReconsTable from "./CustomTable/invoiceReconciliationTable/InvoiceReconciliations";

interface Props {
  invoiceData: any[];
  handleDeleteClick: Function;
}
export default function (props: Props) {
  return (
    <Box style={{ margin: 10, height: "100%" }}>
      <Grid container style={{ padding: 15 }}>
        <Grid item sm={12} style={{ height: 20 }}>
          <Grid container>
            <Grid item sm={12}>
              <Box>
                <h4>INVOICE RECONCILIATIONS ({props.invoiceData.length})</h4>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Box>
            {props.invoiceData.length > 0 ? (
              <InvoiceReconsTable
                handleDeleteClick={props.handleDeleteClick}
                invoiceData={props.invoiceData}
              />
            ) : (
              <Box style={{ marginTop: 20 }}>
                <div>No Records available</div>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
