import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { getCCMAdditionalFields, getInvoiceAdditionalFields, getInvoiceRecord } from "../api";
import WaitPopup from "./WaitPopup";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

interface Props {
  invoiceRecordID?: number;
  ccmRecordID?: number;
}

export default function (props: Props) {
  const [open, setOpen] = React.useState<boolean>(true);
  const [additionalFields, setAdditionalFields] = React.useState<any | undefined>();
  const [invoiceRecord, setInvoiceRecord] = React.useState<any | undefined>();
  const [additionalFieldKeys, setAdditionalFieldKeys] = React.useState<any | undefined>();

  React.useEffect(() => {
    if (props.invoiceRecordID || props.ccmRecordID) {
      loadPageInfo();
    }
  }, []);

  React.useEffect(() => {
    setOpen(false);
    if (additionalFields) {
      let keys = Object.keys(additionalFields);
      if (keys) {
        setAdditionalFieldKeys(keys);
      }
    }
  }, [additionalFields]);

  function loadPageInfo() {
    if (props.invoiceRecordID) {
      getInvoiceAdditionalFields(props.invoiceRecordID, setAdditionalFields);
      getInvoiceRecord(props.invoiceRecordID, setInvoiceRecord);
    }
    if (props.ccmRecordID) {
      getCCMAdditionalFields(props.ccmRecordID, setAdditionalFields);
    }
  }

  const handleClosed = () => () => {};

  return (
    <Box style={{ margin: 10, minWidth: 200 }}>
      <WaitPopup open={open}>"...Loading Info."</WaitPopup>
      <Grid container>
        <Grid item sm={12} style={{ maxHeight: 400, maxWidth: 500 }}>
          <TableContainer style={{ maxHeight: 400, width: "auto", overflow: "auto" }}>
            <Table id="invRecAdditField" size={"small"}>
              <TableBody>
                {additionalFieldKeys &&
                  additionalFieldKeys.map((keys) => {
                    return (
                      <TableRow>
                        <TableCell style={{ padding: 1 }}>
                          <b>{keys ? keys.toUpperCase() : ""}</b>
                        </TableCell>
                        <TableCell style={{ padding: 1 }}>
                          {additionalFields[keys] ? additionalFields[keys].toUpperCase() : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {invoiceRecord && (
                  <TableRow>
                    <TableCell style={{ padding: 1 }}>
                      <b>INVOICE SSL</b>
                    </TableCell>
                    <TableCell style={{ padding: 1 }}>
                      {invoiceRecord && invoiceRecord.invoice_ssl
                        ? invoiceRecord.invoice_ssl.toUpperCase()
                        : ""}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
