import React from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import APPage from "./pages/APpage";
import APSumary from "./pages/APsummary";
import CCMReconciliation from "./pages/CCMReconciliation";
import CCMReviewWorkFlow from "./pages/CCMReconciliationReview";
import CreditUpload from "./pages/CreditUpload";
import CustomerManagement from "./pages/CustomerManagement";
import Dashboard from "./pages/Dashboard";
import EditEmailTemplates from "./pages/EditEmailTemplates";
import ForgotPassword from "./pages/ForgotPasswordPage";
import IEPManagement from "./pages/IEPManagement";
import InvoiceReviewWorkFlow from "./pages/InvoiceReconciliationReview";
import InvoiceReconciliation from "./pages/InvoiceReconciliations";
import CustomerRulesManagement from "./pages/ManageCustomerRules";
import DisputeReasonManagement from "./pages/ManageDisputeReasons";
import UploadFileMappingsManagement from "./pages/ManageUploadFileMappings";
import NotFoundPage from "./pages/NotFoundPage";
import OpenDisputesWorkFlow from "./pages/OpenDisputes";
import PoolManagement from "./pages/PoolManagement";
import QuickSearch from "./pages/QuickSearch";
import RateManagement from "./pages/RateManagement";
import ReportColumns from "./pages/ReportColumns";
import ReportDetails from "./pages/ReportDetails";
import ReportFilters from "./pages/ReportFilters";
import ReportParameters from "./pages/ReportParametersPage";
import ReportReview from "./pages/ReportReview";
import ReportsPage from "./pages/ReportsPage";
import ReportUsers from "./pages/ReportUsers";
import Settings from "./pages/Settings";
import SignInPage from "./pages/SignInPage";
import SslManagement from "./pages/SslManagement";
import TerminalDashboard from "./pages/TerminalDashboard";
import InvoiceApproval from "./pages/TerminalInvoiceApproval";
import TerminalManagement from "./pages/TerminalManagement";
import Upload from "./pages/UploadFiles";
import UserMnagement from "./pages/UserManagement";
import WriteOffs from "./pages/WriteOffs";
import { authStore } from "./stores/authStore";

export default function AppRouter() {
  const auth = authStore.use();

  React.useEffect(() => {
    authStore.initialLoad();
  }, []);

  if (auth.isWaitingForAuth) {
    return <div>Loading...</div>;
  }

  return (
    <Switch>
      {/* NOTE: Order matters, go from specific to general */}
      <PublicRoute exact path="/signin" component={SignInPage} />
      <PublicRoute exact path="/forgotPassword" component={ForgotPassword} />
      {/* // public route for now */}
      <TermTeamAuthedRoute exact path="/terminal" component={TerminalDashboard} />
      <TermTeamAuthedRoute exact path="/approveInvoice/:id" component={InvoiceApproval} />
      {/* // --- end of public routes that will change */}
      <CTeamAuthedRoute exact path="/upload" component={Upload} />
      <CTeamAuthedRoute exact path="/uploadmanager" component={Upload} />
      <CTeamAuthedRoute exact path="/ccmreviewworkflow/:id" component={CCMReviewWorkFlow} />
      <CTeamAuthedRoute exact path="/ccmworkflow/:id" component={CCMReconciliation} />
      <CTeamAuthedRoute exact path="/invoiceworkflow/:id" component={InvoiceReconciliation} />
      <CTeamAuthedRoute exact path="/invoicereviewworkflow/:id" component={InvoiceReviewWorkFlow} />
      <CTeamAuthedRoute exact path="/opendisputesworkflow" component={OpenDisputesWorkFlow} />
      {/* <CTeamAuthedRoute exact path="/sslrulesmanager" component={SslRulesManagement} /> */}
      <CTeamAuthedRoute exact path="/customerrulesmanager" component={CustomerRulesManagement} />
      <CTeamAuthedRoute exact path="/settings" component={Settings} />
      <CTeamAuthedRoute exact path="/editemailtemplates" component={EditEmailTemplates} />
      <CTeamAuthedRoute exact path="/aps" component={APPage} />
      <CTeamAuthedRoute exact path="/quicksearch" component={QuickSearch} />
      <CTeamAuthedRoute exact path="/reportcolumns" component={ReportColumns} />
      <CTeamAuthedRoute exact path="/reportfilters" component={ReportFilters} />
      <CTeamAuthedRoute exact path="/reportusers" component={ReportUsers} />
      <CTeamAuthedRoute exact path="/reportdetails" component={ReportDetails} />
      <CTeamAuthedRoute exact path="/reportreview" component={ReportReview} />
      <CTeamAuthedRoute exact path="/reports" component={ReportsPage} />
      <CTeamAuthedRoute exact path="/apworkflow/:id" component={APSumary} />
      <CTeamAuthedRoute exact path="/reportparams" component={ReportParameters} />
      <CTeamAuthedRoute exact path="/creditupload" component={CreditUpload} />
      <CTeamAuthedRoute exact path="/autowriteoffs" component={WriteOffs} />
      <CTeamAuthedRoute
        exact
        path="/uploadfilemappingsmanager"
        component={UploadFileMappingsManagement}
      />
      <CTeamAuthedRoute exact path="/usermanager" component={UserMnagement} />
      <CTeamAuthedRoute exact path="/ratemanager" component={RateManagement} />
      <CTeamAuthedRoute exact path="/customermanager" component={CustomerManagement} />
      <CTeamAuthedRoute exact path="/iepmanager" component={IEPManagement} />
      <CTeamAuthedRoute exact path="/terminalmanager" component={TerminalManagement} />
      <CTeamAuthedRoute exact path="/sslmanager" component={SslManagement} />
      <CTeamAuthedRoute exact path="/poolmanager" component={PoolManagement} />
      <CTeamAuthedRoute exact path="/reasonmanager" component={DisputeReasonManagement} />
      <CTeamAuthedRoute exact path="/dashboard" component={Dashboard} />
      <CTeamAuthedRoute exact path="/" component={Dashboard} />
      <CTeamAuthedRoute component={NotFoundPage} />
    </Switch>
  );
}

function PublicRoute(props: RouteProps) {
  const { component, ...other } = props;
  const { me } = authStore.use();
  return <Route {...other} component={me ? RedirToApp : component} />;
}

function CTeamAuthedRoute(props: RouteProps) {
  const { component, ...other } = props;
  const { me } = authStore.use();
  return <Route {...other} component={me &&  me.group === 'RecTeam' ? component : RedirToSignin} />;
}

function TermTeamAuthedRoute(props: RouteProps) {
  const { component, ...other } = props;
  const { me } = authStore.use();
  return <Route {...other} component={me ? component : RedirToSignin} />;
}

function RedirToSignin(props: any) {
  let to = "/signin";
  if (props.location.pathname !== "/") {
    to += "?redir=" + encodeURIComponent(props.location.pathname + props.location.search);
  }
  return <Redirect to={to} />;
}

function RedirToApp(props: any) {
  let to = "/";
  const { me } = authStore.use();

  if (me && me.group === "TermTeam" ) {
    to = "/terminal";
  }

  if (props.location.search) {
    let params = new URLSearchParams(props.location.search);
    let redir = params.get("redir");
    if (redir) {
      to = redir;
    }
  }
  return <Redirect to={to} />;
}
