import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";
import { useHistory } from "react-router-dom";
import { deleteReport, getAPIURL, getReports } from "../api";
import Layout from "../components/layout/Layout";
import WaitPopup from "../components/WaitPopup";
import { formatDateMMDDYYYYAndTime } from "../formatters";
import { frequency } from "../types/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(16),
      },
    },
  })
);

interface Props {
  style: any;
}

export default function (props: any) {
  const classes = useStyles();
  const history = useHistory();

  const [reports, setReports] = React.useState<any | undefined>();
  const [deletedReport, setDeletedReport] = React.useState<any | undefined>();
  const [modifiedReports, setmodifiedReports] = React.useState<any | undefined>();

  const futuredate = new Date("01-01-2999");
  const currentDate = new Date();

  let openWait: boolean = true;

  React.useEffect(() => {
    if (reports && reports.length > 0) {
      openWait = false;
      let modifiedReports = null;
      reports.forEach((element) => {
        let report = element;
        let emptySchedule = {
          report_id: report.id_,
          scheduled_by: report.created_by,
          created_dt: report.created_dt,
          modified_by: report.modified_by,
          modified_dt: report.modified_dt,
          is_active: true,
          frequency: 1,
        };
        let startDt = new Date("01-01-2999");
        let nextDt = new Date("01-01-2999");
        let lastDt = new Date("01-01-2999");
        if (report.schedule && report.schedule.length > 0) {
          report.schedule[0].start_dt = report.schedule[0].start_dt
            ? report.schedule[0].start_dt.replace(" GMT", "")
            : null;
          report.schedule[0].next_run = report.schedule[0].next_run
            ? report.schedule[0].next_run.replace(" GMT", "")
            : report.schedule[0].start_dt;

          let startDt = new Date(report.schedule[0].start_dt);
          let nextDt = new Date(report.schedule[0].next_run);
          let lastDt = new Date(nextDt);
          let currentDay = currentDate.getDate();
          let reportDay = startDt.getDate();
          let currentMonth = currentDate.getMonth();
          report.schedule[0].start_dt = startDt;
          report.schedule[0].next_run = nextDt;

          if (report.schedule[0].frequency == frequency.once) {
            if (startDt > currentDate) {
              lastDt = new Date("01-01-2999");
              report.schedule[0].next_run = new Date(startDt);
            } else {
              report.schedule[0].last_run = new Date("01-01-2999");
              report.schedule[0].next_run = new Date("01-01-2999");
            }
          }

          if (report.schedule[0].frequency == frequency.monthly) {
            if (currentDay >= reportDay) {
              lastDt.setMonth(currentDate.getMonth());
              nextDt.setMonth(currentDate.getMonth() + 1);
            } else {
              lastDt.setMonth(currentDate.getMonth() - 1);
              nextDt.setMonth(currentDate.getMonth());
            }
            if (startDt > currentDate) {
              nextDt.setMonth(startDt.getMonth());
            }
            report.schedule[0].next_run = new Date(nextDt);
          }

          if (report.schedule[0].frequency == frequency.weekly) {
            if (currentDay >= reportDay) {
              lastDt.setMonth(currentMonth);

              if (startDt >= currentDate) {
                nextDt.setDate(startDt.getDate());
              } else {
                nextDt.setDate(lastDt.getDate() + 7);
                nextDt.setMonth(currentMonth);
              }
            } else {
              lastDt.setMonth(currentMonth - 1);
              if (startDt >= currentDate) {
                nextDt.setDate(startDt.getDate());
              } else {
                nextDt.setDate(lastDt.getDate() + 7);
              }
            }
            report.schedule[0].next_run = new Date(nextDt);
          }

          if (lastDt >= startDt) {
            report.schedule[0].last_run = new Date(lastDt);
          } else {
            report.schedule[0].last_run = new Date("01-01-2999");
          }
        } else {
          report.schedule.push(emptySchedule);
          report.schedule[0].start_dt = startDt;
          report.schedule[0].next_run = nextDt;
          report.schedule[0].last_run = lastDt;
        }

        if (!modifiedReports) {
          modifiedReports = [];
        }
        modifiedReports.push(report);
      });
      setmodifiedReports(modifiedReports);
    } else {
      setmodifiedReports(undefined);
    }
  }, [reports]);

  React.useEffect(() => {
    loadPageInfo();
  }, []);

  const loadPageInfo = () => {
    getReports( setReports);
  };

  if (reports) {
    openWait = false;
  }

  const handleEditClick = (reportID) => {
    history.push("/reportdetails", { reportID: reportID });
  };

  const handleDownloadClick = (reportID, reportName) => {
    let doclink = document.createElement("a");
    doclink.href =
      getAPIURL() + "export/report/" + reportID + "/" + Math.floor(Math.random() * 100000);
    doclink.target = "blank";
    doclink.click();
  };

  const handleDeleteClick = (report_id: number) => {
    deleteReport( report_id, (data) => {
      loadPageInfo();
      setDeletedReport(data);
    });
  };

  return (
    <Layout>
      <Box style={{ margin: 5, width: "100%" }}>
        <WaitPopup open={openWait}>{"...Loading Info"}</WaitPopup>
        <Grid container>
          <Grid item sm={12} style={{ maxHeight: 750, overflow: "scroll" }}>
            <Grid
              container
              style={{
                display: "flex",
                flexWrap: "wrap",
                padding: 5,
                margin: 1,
              }}
            >
              <Grid item sm={12}>
                <Button
                  style={{
                    float: "right",
                    padding: 10,
                    paddingLeft: 28,
                    paddingRight: 28,
                    marginBottom: 10,
                  }}
                  variant="contained"
                  color="secondary"
                  size="medium"
                  onClick={(e) => {
                    history.push("/reportdetails", null);
                  }}
                >
                  New Report
                </Button>
              </Grid>
              {modifiedReports &&
                modifiedReports.map((report, index) => {
                  return (
                    <Grid
                      item
                      sm={6}
                      key={index}
                      style={{
                        flexWrap: "wrap",
                        padding: 5,
                        margin: 0,
                      }}
                    >
                      <Card elevation={0} variant="outlined">
                        <CardContent>
                          <Grid container>
                            <Grid item container sm={4}>
                              <Grid item sm={12}>
                                <Typography style={{ margin: 1, fontWeight: "bold" }}>
                                  {report.report_name}
                                </Typography>
                              </Grid>
                              <Avatar
                                onClick={() => {
                                  handleEditClick(report.id_);
                                }}
                                style={{
                                  margin: 1,
                                  marginRight: 18,
                                  width: "34px",
                                  height: "34px",
                                  backgroundColor: "#FFC338",
                                }}
                              >
                                <EditIcon />
                              </Avatar>
                              <Avatar
                                onClick={() => {
                                  handleDownloadClick(report.id_, report.report_name);
                                }}
                                style={{
                                  margin: 1,
                                  marginRight: 18,
                                  width: "34px",
                                  height: "34px",
                                  backgroundColor: "#368BCC",
                                }}
                              >
                                <GetAppIcon />
                              </Avatar>
                              {report.type_ && report.type_ != "ReportType.pre_defined" ? (
                                <Avatar
                                  onClick={() => {
                                    handleDeleteClick(report.id_);
                                  }}
                                  style={{
                                    margin: 1,
                                    width: "34px",
                                    height: "34px",
                                    backgroundColor: "#F5542C",
                                  }}
                                >
                                  <DeleteIcon style={{ margin: 1 }} />
                                </Avatar>
                              ) : null}
                            </Grid>
                            <Grid item sm={4}>
                              <Typography style={{ fontWeight: "bold", margin: 1 }}>
                                Last Report
                              </Typography>
                              {report.schedule &&
                                report.schedule[0].last_run &&
                                report.schedule[0].last_run < futuredate
                                ? formatDateMMDDYYYYAndTime(report.schedule[0].last_run)
                                : ""}
                              <Typography style={{ fontWeight: "bold", margin: 1, marginTop: 6 }}>
                                Frequency
                              </Typography>
                              {report.schedule ? report.schedule[0].frequency_name : ""}
                            </Grid>
                            <Grid item sm={4}>
                              <Typography style={{ fontWeight: "bold", margin: 1 }}>
                                Next Report
                              </Typography>
                              {report.schedule &&
                                report.schedule[0].next_run &&
                                report.schedule[0].next_run < futuredate
                                ? formatDateMMDDYYYYAndTime(report.schedule[0].next_run)
                                : ""}
                              <Typography style={{ fontWeight: "bold", margin: 1, marginTop: 6 }}>
                                Schedule Start
                              </Typography>
                              {report.schedule &&
                                report.schedule[0].start_dt &&
                                report.schedule[0].start_dt < futuredate
                                ? formatDateMMDDYYYYAndTime(report.schedule[0].start_dt)
                                : ""}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}
