export interface DataTriniumResults {
    full_manifest: number;
    chassis: string;
    container: string;
    customer_name: string;
    ssl: string;
    site: string;
    booking_bol: string;
    pickup: string;
    pickup_date: string;
    delivery: string;
    delivery_date: string;
    current_bol: string;
    ar_chuse: string;
    days_out: number;
    cost: number;
  }
  
export const HeaderDataInvoiceRecons: HeadCell[] = [
    {
      id: "full_manifest",
      numeric: false,
      disablePadding: false,
      label: "MANIFEST",
    },
    { id: "chassis", numeric: true, disablePadding: false, label: "CHASSIS" },
    { id: "container", numeric: false, disablePadding: false, label: "CONTAINER" },
    {
      id: "customer_name",
      numeric: false,
      disablePadding: false,
      label: "CUSTOMER NAME",
    },
    {
      id: "ssl",
      numeric: false,
      disablePadding: false,
      label: "SSL",
    },
    {
      id: "site",
      numeric: false,
      disablePadding: false,
      label: "SITE",
    },
    {
      id: "booking_bol",
      numeric: false,
      disablePadding: false,
      label: "BOOKING BOL",
    },
    {
      id: "pickup",
      numeric: false,
      disablePadding: false,
      label: "PICKUP",
    },
    {
      id: "pickup_date",
      numeric: false,
      disablePadding: false,
      label: "PICKUP DATE",
    },
    {
      id: "delivery",
      numeric: false,
      disablePadding: false,
      label: "DELIVERY",
    },
    {
      id: "delivery_date",
      numeric: false,
      disablePadding: false,
      label: "DELIVERY DATE",
    },
    {
      id: "ar_chuse",
      numeric: false,
      disablePadding: false,
      label: "REBILL",
    },
    {
      id: "cost",
      numeric: false,
      disablePadding: false,
      label: "COST",
    },
    {
      id: "days_out",
      numeric: true,
      disablePadding: false,
      label: "CUSTOM DAYS OUT",
    },
    
  ];
  
  export interface HeadCell {
    disablePadding: boolean;
    id: keyof DataTriniumResults;
    label: string;
    numeric: boolean;
  }
  
