import React from "react";

const backgroundColor = "yellow";
interface Props {
  str: string;
  subStr: string;
}

interface HiglightStr {
  highlight: boolean;
  str: string;
}

export default function SubHighlight(props: Props) {
  const substrArray: HiglightStr[] = [];

  function getIndicesOf(searchStr: string, str: string, caseSensitive: boolean) {
    const searchStrLen = searchStr.length;
    if (searchStrLen == 0 || str == null) {
      return [];
    }
    let startIndex = 0,
      index,
      indices = [];
    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    return indices;
  }

  const indexArray = getIndicesOf(props.subStr, props.str, false);

  for (let i = 0; i < indexArray.length; i++) {
    if (i == 0 && indexArray[i] !== 0) {
      substrArray.push({
        highlight: false,
        str: props.str?.substring(0, indexArray[i]),
      });
    }

    substrArray.push({
      highlight: true,
      str: props.str?.substring(indexArray[i], indexArray[i] + props.subStr.length),
    });

    if (i + 1 < indexArray.length) {
      substrArray.push({
        highlight: false,
        str: props.str?.substring(indexArray[i] + props.subStr.length, indexArray[i + 1]),
      });
    } else {
      substrArray.push({
        highlight: false,
        str: props.str?.substring(indexArray[i] + props.subStr.length),
      });
    }
  }

  return (
    <div>
      {props.str?.toUpperCase().includes(props.subStr.toUpperCase()) ? (
        <span>
          {substrArray.map((subStr, index) => {
            if (subStr.highlight) {
              return (
                <span key={index} style={{ backgroundColor: backgroundColor }}>
                  {subStr.str}
                </span>
              );
            } else {
              return <span key={index}>{subStr.str}</span>;
            }
          })}
        </span>
      ) : (
        <span>{props.str}</span>
      )}
    </div>
  );
}
